import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import auth from "./auth/login/reducer"
import login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Data from "./auth/data/reducer"

//Calendar
import calendar from "./calendar/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  auth,
  login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  Data
})

export default rootReducer
