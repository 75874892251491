import React, { useState } from "react"
import { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { contractDelete, contractDetailResponse, doSign, patchContract, contractWriteResponse } from 'crud/auth.crud';

import { Link, useLocation, useHistory } from "react-router-dom"

import empty from "../../assets/images/empty.png"
import FileUploads from "../../components/RopikCustom/FileUploads";

import { useSelector } from "react-redux";
const ContractManagementDetailScreen = () => {
  const selector = useSelector(state => state)

  const location = useLocation()
  const history = useHistory()

  const [id, setId] = useState(location.state.contract);
  const [myCompany, setMyCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group.id);
  const [otherCompany, setOtherCompany] = useState('');
  const [signFile, setSignFile] = useState(selector.auth.user.member_file.filter(item => item.category === 'signFile')?.[0] || null);
  const [otherSignFile, setOtherSignFile] = useState(null);
  const [data, setData] = useState({});
  const [haveAuth, setHaveAuth] = useState(false);
  const [isReceipt, setIsReceipt] = useState(false);
  const [onUpload, setOnUpload] = useState(false);
  const [deleteOn, setDeleteOn] = useState(false);
  const [contractCompanyGroupId, setContractCompanyGroupId] = useState('')
  const [companyList, setCompanyList] = useState([])
  const [signList, setSignList] = useState([])
  const [edit, setEdit] = useState(false)
  const [previewImages, setPreviewImages] = useState([])
  const [imgArr, setImgArr] = useState([])

  const getData = async () => {
    const params = `?contract=${id}`
    const response = await contractDetailResponse(params);
    console.log(response)
    // let otherCompany = ''
    // if(myCompany === response.data?.company ){
    //     otherCompany = response.data?.target_company
    // }else{
    //     otherCompany = response.data?.company
    // }

    if (response.data.result === 'success') {
      let tmp = []
      let companyList = []
      for (let i = 0; i < response.data?.data.length; i++) {
        const data = response.data?.data[i];
        let item = {
          id: data.id,
          companyId: data.company,
          companyName: data.contract_company_group_data?.name,
          isSign: data.sign,
          companyType: data.contract_company_group_data.type,
          signFile: data.contract_company_group_data?.company_group?.[0]?.member_group?.member_file?.filter(itm => itm.category === 'signFile')?.[0] || ''
        }
        if (data.company === myCompany) {
          setContractCompanyGroupId(data.id)
        }
        tmp.push(item)
      }
      setCompanyList(companyList)
      setSignList(tmp)
      setData(response.data?.data?.[0]?.contract_group_data)
      // 삭제부분에 넣어야함
      if (myCompany === response.data?.data?.[0]?.contract.company && response.data?.data?.[0]?.contract_group_data.state === 'N') {
        setDeleteOn(true)
        // this.props.navigation.setOptions(
        //   {    
        //     headerTitle: '계약서 상세', 
        //     headerTitleContainerStyle: {left: 40},            
        //     headerShown: true,
        //     headerRight: () => (
        //       <View style={{ flexDirection: 'row', paddingRight: 10 }}>            
        //         <TouchableOpacity style={{width: 60, height: 35,  padding:5, borderWidth: 1, borderColor: Color.point, borderRadius: 10, marginRight: 10}} onPress={()=>{ this.openDelete()}}>
        //           <Text style={{fontSize: 16, textAlign: 'center', color: Color.point, fontWeight: 'bold'}}>삭제</Text>
        //         </TouchableOpacity>
        //       </View>
        //     ),
        //     }
        // )       
      }
    }
  }

  const doDelete = async () => {
    if (!confirm("삭제하시겠습니까?")) {
      return;
    } else {
      setOnUpload(true);
      await contractDelete(id);
      setOnUpload(false);
      alert("정상적으로 삭제되었습니다.");
      history.push("/contract-management-screen");
    }
  }

  const deleteImage = (index) => {
    let newImgArr = [...imgArr]
    newImgArr.splice(index, 1)
    setImgArr(newImgArr)
    handleFileChange(newImgArr)
  }

  const openContract = async () => {
    if (confirm("계약서에 서명하시겠습니까?")) {
      setOnUpload(true)
      const param = {
        id: contractCompanyGroupId,
        contract: id,
        sign: 'Y',
        company: data.company,
      }
      const response = await doSign(param)
      if (response.status === 200) {
        getData()
      }
    }
  }

  const truncateString = (str) => {
    if (str.length > 25) {
      return str.substring(0, 25) + "...";
    } else {
      return str;
    }
  }

  const handleFileChange = (event) => {
    const files = event.target.files;
    let prevTmp = [];
    for (let i = 0; i < files.length; i++) {
      let item = {
        name : files[i].name,
        path :  URL.createObjectURL(files[i]),        
      }
      prevTmp.push(item)
    }
    let tmp = [...imgArr, event.target.files];
    tmp = tmp.filter((v, i) => {return i - 5 < 0})    
    setImgArr(tmp)    
    prevTmp = prevTmp.filter((v, i) => {return i - 5 < 0})
    setPreviewImages(prevTmp);
  };

  // const handleFileChange = (event) => {
  //   let files = event;
  //   files = [...files].filter(item => item.type !== 'application/pdf')
  //   const newPreviewImages = [];
  //   const readers = [];
  //   if(files.length === 0){
  //     setPreviewImages([])
  //   }
  //   for (let i = 0; i < files.length; i++) {
  //     readers[i] = new FileReader();

  //     readers[i].onload = (event) => {
  //       newPreviewImages[i] = event.target.result;
  //       setPreviewImages([...newPreviewImages]);
  //       console.log([...newPreviewImages])
  //     };

  //     readers[i].readAsDataURL(files[i]);
  //   }
  // };

  const editComplete = async () => {
    const fd = new FormData();

    for (let i = 0; i < imgArr.length; i++) {
      let newFile = new File([imgArr[i]], `${encodeURI(imgArr[i].name)}`, {type: imgArr[i].type})
      fd.append('file_' + i, newFile);
    }
    const contractData = {
      id : id
    }
    fd.append('contract_data', JSON.stringify(contractData))

    for (let key of fd.keys()) {
      console.log(key, ">>>", fd.get(key));
    }
    const response = await patchContract(fd)
    console.log("결과 >>", response)
    if(response.status === 200){
      getData()
      setEdit(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const otherSignList = signList.filter(itm => itm.companyType === 'emitter' || itm.companyType === 'collection') || []; // 서명란의 회사가 배출자거나 수거운반
  const thisSignList = signList.filter(itm => itm.companyType !== 'emitter' && itm.companyType !== 'collection') || []; // 서명란의 회사가 처리장  

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>계약서 상세 |  IoT Monitor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="계약서 상세" />

          <Row>
            <Col xs="12" style={{maxWidth: '28rem'}}>
              {/* Render Email SideBar */}
              {/* <EmailSideBar /> */}
              <div className="email-rightbar mb-3">
                <Card>
                  <CardBody style={{ padding: 24 }}>
                    {/* <div onClick={()=>{window.history.back()}}                      
                      className="btn dropdown-toggle waves-effect waves-light to-list-btn"
                    >목록으로
                    </div> */}
                    <div style={{ marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>ID</p>
                      <p style={{ fontSize: 16, lineHeight: '35px' }} >{data.id}</p>
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>계약명</p>
                      <p style={{ fontSize: 16, lineHeight: '35px' }} >{data.subject}</p>
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>계약일자</p>
                      <p style={{ fontSize: 16, lineHeight: '35px' }} >{data.created_at?.substr(0, 10)}</p>
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>계약기간</p>
                      <p style={{ fontSize: 16, lineHeight: '35px' }} >{data.start_date?.substr(0, 10) + " ~ " + data.end_date?.substr(0, 10)}</p>
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>L당 요금</p>
                      <p style={{ fontSize: 16, lineHeight: '35px' }}>{data.price_per_l} 원</p>
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>Kg당 요금</p>
                      <p style={{ fontSize: 16, lineHeight: '35px' }}>{data.price_per_kg} 원</p>                      
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>기본요금</p>
                      <p style={{ fontSize: 16, lineHeight: '35px' }}>{data.min_price} 원</p>                      
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>계약내용</p>
                      <p style={{ fontSize: 16, lineHeight: '35px', width: 480 }} >{data.content}</p>
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>계약여부</p>
                      <p style={{ fontSize: 16, lineHeight: '35px', width: 480, color: data.state == 'Y' ? 'blue' : 'red' }} >{data.state == 'Y' ? "계약완료" : "계약 진행중"}</p>
                    </div>
                    <div style={{  marginBottom: 24}}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>전자서명</p>
                      <div style={{ marginBottom: 50}} >
                        <div style={{ display: "flex", flexWrap : 'wrap'}}>
                        {otherSignList && otherSignList.map((item, index) => {
                          if (item.isSign === 'N') {
                            return (
                              <div key={'other_sign_'+index} style={{marginBottom: '16px'}}>
                                <p className="waver-sign-box-header" >{item.companyName}</p>
                                {myCompany === item.companyId ?
                                  <div style={{ border: '1px solid #bdbdbd', minWidth: 80, height: 80, alignItems: 'center', marginRight: 1 }} onClick={() => openContract(item)}>
                                    <p style={{ margin: "24px auto", width: 70, fontSize: 16, textAlign: 'center', borderStyle: 'solid', borderWidth: 1, borderColor: "red", borderRadius: 50, cursor: "pointer" }}>서명하기</p>
                                  </div>
                                  :
                                  <div style={{ border: '1px solid #bdbdbd', minWidth: 80, height: 80, marginRight: 1 }}></div>
                                }
                                <p className="waver-sign-box-footer">{item.companyType === 'emitter' ? '배출자' : '수거업체'}</p>
                              </div>
                            )
                          } else {
                            return (
                              <div key={'other_sign_'+index}>
                                <p className="waver-sign-box-header">{item.companyName}</p>
                                <div style={{ border: '1px solid #bdbdbd', width: 80, height: 80,paddingVertical: 5, marginRight: 1 }}>
                                  <img src={item.signFile?.path?.replace("http://127.0.0.1:5010", "http://Iot_monitor.ropiklabs.com")} onError={(e) => e.target.src = empty} style={{ width: 80, height: 80, resizeMode: 'contain' }} />
                                </div>
                                <p className="waver-sign-box-footer">{item.companyType === 'emitter' ? '배출자' : '수거업체'}</p>
                              </div>
                            )
                          }
                        })
                        }
                        </div>
                        <div style={{ display: "flex", flexWrap : 'wrap'}}>
                        {thisSignList && thisSignList.map((item, index) => {
                          if (item.isSign === 'N') {
                            return (
                              <div key={'this_sign_'+index}>
                                <p className="waver-sign-box-header">{item.companyName}</p>
                                {myCompany === item.companyId ?
                                  <div style={{ border: '1px solid #bdbdbd', minWidth: 80, height: 80, alignItems: 'center', marginRight: 1}} onClick={() => openContract(item)}>
                                    <p style={{ margin: "24px auto", width: 70, fontSize: 16, textAlign: 'center', borderStyle: 'solid', borderWidth: 1, borderColor: "red", borderRadius: 50, cursor: "pointer" }}>서명하기</p>
                                  </div>
                                  :
                                  <div style={{ border: '1px solid #bdbdbd', minWidth: 80, height: 80, marginRight: 1}}></div>
                                }
                                <p className="waver-sign-box-footer">{thisSignList.length === 1 ? '처리장' : `처리장${index + 1}`}</p>
                              </div>
                            )
                          } else {
                            return (
                              <div key={'this_sign_'+index}>
                                <p className="waver-sign-box-header">{item.companyName}</p>
                                <div style={{ border: '1px solid #bdbdbd', width: 80, height: 80, paddingVertical: 5, marginRight: 1 }}>
                                  <img src={item.signFile?.path?.replace("http://127.0.0.1:5010", "http://Iot_monitor.ropiklabs.com")} onError={(e) => e.target.src = empty} style={{ width: 80, height: 80, resizeMode: 'contain' }} />
                                </div>
                                <p className="waver-sign-box-footer">{thisSignList.length === 1 ? '처리장' : `처리장${index + 1}`}</p>
                              </div>
                            )
                          }
                        })
                        }
                        </div>
                      </div>
                    </div>
                                        
                    {!edit &&
                      <FileUploads title={'계약서'} arr={data?.contract_file || []} handleChange={()=>{}} handleDelete={()=>{}} disabled={true}/>
                      // <div style={{ marginBottom: 24 }}>
                      //   <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>계약서</p>
                      //   <div style={{ width: 600 }}>
                      //     {data?.['contract_file']?.length > 0 && data?.['contract_file'].map((item, index) => {
                      //       return (
                      //         <img src={data?.contract_file?.[index]?.path.replace("http://127.0.0.1:5010", "http://Iot_monitor.ropiklabs.com")} style={{ fontSize: 16, lineHeight: '35px', width: 600, padding: 3, border: "1px solid #ced4da", marginBottom: 24 }} />
                      //       )
                      //     })
                      //     }
                      //   </div>
                      // </div>
                    }
                    {edit &&
                      <FileUploads title={'계약서 사본'} arr={previewImages} handleChange={handleFileChange} handleDelete={deleteImage} />
                      // <div style={{ marginBottom: 24 }}>
                      //   <div style={{ display: "flex" }}>
                      //     <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>계약서</p>
                      //     <label className="btn btn-primary waves-effect waves-light" htmlFor="getFile" style={{ height: 36, marginLeft: 6 }}>파일 선택</label>
                      //     <input style={{ display: "none" }} type="file" id="getFile" accept="image/*" onChange={(e) => {
                      //       handleFileChange(e.target.files)
                      //       setImgArr(e.target.files)
                      //     }} />
                      //     {imgArr?.length > 0 && [...imgArr].map((item, index) => {
                      //       return (
                      //         <div key={index} style={{ border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginLeft: 6, height: 36 }}>
                      //           <p className="noMargin" style={{ paddingLeft: 6 }}>{truncateString(item.name)}</p>
                      //           <p className="noMargin" style={{ paddingRight: 6, color: "#000", cursor: "pointer", marginLeft: 12 }} onClick={() => {
                      //             deleteImage(index)
                      //             // setPreviewImages(previewImages.filter(v => v !== index))
                      //           }}>X</p>
                      //         </div>
                      //       )
                      //     })
                      //     }
                      //   </div>
                        
                      //   <div style={{ width: 1000 }}>
                      //     {previewImages.length > 0 ? previewImages.map((image, index) => (
                      //       <>
                      //         <div key={index} style={{ width: "fit-content", width: 600, position: "relative", marginBottom: 24 }}>
                      //           <img style={{ width: 600, fontSize: 16, lineHeight: '35px', padding: 3, border: "1.5px solid #48C3B1" }} src={image} />
                      //           <p style={{ position: "absolute", top: 12, right: 24, cursor: "pointer" }} onClick={() => {
                      //             deleteImage(index)
                      //             // setPreviewImages(previewImages.filter(v => v !== image))
                      //           }}>X</p>
                      //         </div>
                      //       </>
                      //     )) : <></>
                      //     }
                      //   </div>
                      // </div>
                    }
                  <div style={{textAlign: 'right'}}>
                    <Button
                      type="button"
                      color="silver"
                      style={{ padding: '0.4rem 1.75rem', borderRadius: '0.2rem', marginRight: 16 }}
                      onClick={() => { history.goBack() }}
                    >
                      목록으로
                    </Button>
                    {!edit && data?.['contract_file']?.length === 0 &&
                      <Button
                        type="button"
                        color="secondary"
                        style={{ padding: '0.4rem 1.75rem', borderRadius: '0.2rem' }}
                        onClick={() => {
                          setEdit(true)
                        }}
                      >
                        수정하기
                      </Button>
                    }
                    {edit &&
                      <Button
                        type="button"
                        color="secondary"
                        style={{ padding: '0.4rem 1.75rem', borderRadius: '0.2rem' }}
                        onClick={() => {
                          editComplete()
                        }}
                      >
                        수정완료
                      </Button>
                    }
                    {deleteOn &&
                      <Button
                        type="button"
                        color="danger"
                        style={{ padding: '0.4rem 1.75rem', borderRadius: '0.2rem' }}
                        onClick={() => {
                          doDelete()
                        }}
                      >
                        삭제하기
                      </Button>
                    }

                  </div>                    
                  </CardBody>

                </Card>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContractManagementDetailScreen
