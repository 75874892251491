import { getSettlement, postTaxPdf } from "crud/auth.crud";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"
import { useSelector } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import moment from "moment";
import Util from "pages/Dashboard/Common/Util";
import loading from "../../assets/images/loading.gif";

const SettlementDetails = () => {
  const selector = useSelector(state => state)
  const [dataList, setDataList] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [user, setUser] = useState(selector.auth.user)
  const [detailData, setDetailData] = useState()
  const [page, setPage] = useState(1)
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1);
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4]);
  const [contentCount, setContentCount] = useState();
  const [pdfUrl, setPdfUrl] = useState();

  const nextGetContract = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      console.log("페이지 일치 여부", page, i + 1)
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {
    setOnUpload(true)
    const params = {
      page: page,
      company: user.member_group?.[0]?.company_group?.id,
    }

    let url = '/summary_wearing/data_list_for_treatment/?page=' + params.page + '&company=' + params.company;

    const response = await getSettlement(url);
    console.log("response >>> ", response)
    if (!contentCount || contentCount !== Math.floor(response.data.count / 15) + 1) {
      setContentCount(Math.floor(response.data.count / 15) + 1)
      let newArr = []
      for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
        console.log("페이지 일치 여부", page, i + 1)
        if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
          newArr.push(i + 1)
        }
      }
      setPageArr(newArr)
    }
    if (response.status === 200) {
      const content = response.data?.results || [];
      if (content.length > 0) {
        console.log([...content])
        setDataList([...content])
      } else {
        setDataList(false)
      }
    }
    setOnUpload(false)
  }

  const clickList = async (item) => {
    setDetailData(item);
    setModalShow(true)
  }

  useEffect(() => {
    nextGetContract()
  }, [pagesGroup])

  useEffect(() => {
    getDataList()
  }, [])

  return (
    // <React.Fragment>
    //   <div className="page-content">
    //     <MetaTags>
    //       <title>정산상세내역 /  IoT Monitor</title>
    //     </MetaTags>
    //     <Container fluid>
    //       {/* Render Breadcrumbs */}
    //       <Breadcrumbs maintitle="홈" title="청구서" breadcrumbItem="정산상세내역" />

    <Row>
      <Col xs="12">
        {/* Render Email SideBar */}
        {/* <EmailSideBar /> */}
        <div className="email-rightbar mb-3">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">정산 내역</h4>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ fontWeight: 600 }}>ID</th>
                      <th scope="col" style={{ fontWeight: 600 }}>업체명</th>
                      <th scope="col" style={{ fontWeight: 600 }}>거래일자</th>
                      <th scope="col" style={{ fontWeight: 600 }}>인수량</th>
                      <th scope="col" style={{ fontWeight: 600 }}>고지 금액</th>
                      <th scope="col" style={{ fontWeight: 600 }}>완납여부</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      dataList && dataList.map((item, index) => {
                        return (
                          <tr key={item.id + '_' + index}>
                            <th style={{ cursor: "pointer" }} onClick={() => {
                              clickList(item)
                            }} scope="row">{item.id}</th>
                            <td style={{ cursor: "pointer" }} onClick={() => {
                              clickList(item)
                            }}>{item.collection_company.name}</td>
                            <td style={{ cursor: "pointer" }} onClick={() => {
                              clickList(item)
                            }}>{moment(item.summary_date).format('YYYY-MM-DD')}</td>
                            <td style={{ cursor: "pointer" }} onClick={() => {
                              clickList(item)
                            }} scope="row">{Util.setCommaPrice(item.qty_kg)} Kg</td>
                            <td style={{ cursor: "pointer" }} onClick={() => {
                              clickList(item)
                            }} scope="row">{Util.setCommaPrice(item.total_price)} 원</td>
                            <td style={{ cursor: "pointer" }} onClick={() => {
                              clickList(item)
                            }} scope="row">{item.state === 'Y' ? '완납' : item.state === 'N' ? '미납' : '부분납부'}</td>
                          </tr>
                        )
                      })
                    }
                    {!dataList &&
                      <tr style={{ position: "relative", height: 46 }}>
                        <th scope="row"><p className="noMargin" style={{ position: "absolute", left: 0, right: 0, top: '0.75rem', margin: '0 auto', textAlign: "center" }}>데이터가 없습니다.</p></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }

                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
          <Row>
            <Col xs="12" style={{ textAlign: "center" }}>
              <div className="btn-group mt-4">
                <Button
                  style={{ opacity: page !== 1 ? 1 : 0, cursor: page !== 1 ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                  type="button"
                  color="primary"
                  size="sm"
                  className="waves-effect"
                  onClick={() => {
                    if (page !== 1) {
                      setPage(page - 1)
                      if (Math.min(...pageArr) === page) {
                        setPagesGroup(pagesGroup - 5)
                      }
                    }
                  }}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                {pageArr && pageArr.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      style={{ cursor: 'pointer', padding: "0.5rem 1rem", border: 'none', color: page === item ? 'white' : 'black', backgroundColor: page === item ? '#48C3B1' : 'white' }}
                      type="button"
                      size="sm"
                      className="waves-effect"
                      onClick={() => {
                        setPage(item)
                      }}
                    >
                      {item}
                    </Button>
                  )
                })}
                <Button
                  style={{ opacity: page !== contentCount ? 1 : 0, cursor: page !== contentCount ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                  type="button"
                  color="primary"
                  size="sm"
                  className="waves-effect"
                  onClick={() => {
                    if (page !== contentCount) {
                      setPage(page + 1)
                      if (Math.max(...pageArr) === page) {
                        setPagesGroup(pagesGroup + 5)
                      }
                    }
                  }}
                >
                  <i className="fa fa-chevron-right" />
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
      {modalShow && <div style={{
        position: "fixed",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div style={{ width: 480, height: 480, backgroundColor: "#fff", zIndex: 1000, padding: 36 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontSize: 20, color: 'black', fontWeight: 'bold' }}>정산 내역 상세</p>
            <p style={{ cursor: "pointer", fontSize: 20 }} onClick={() => {
              setModalShow(false)
            }}>X</p>
          </div>
          <div style={{ padding: 12 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>업체명</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{detailData?.collection_company.name}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>인수량</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.qty_kg)} Kg</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>VAT</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.vat)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>고지 금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.total_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>납부 금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.payed_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>미납 금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.total_price - detailData?.payed_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>완납 여부</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{detailData?.state === 'Y' ? '완납' : detailData?.state === 'N' ? '미납' : '부분납부'}</p>
            </div>
          </div>
        </div>
      </div>}
    </Row>
    //     </Container>
    //   </div>
    // </React.Fragment>
  )
}

export default SettlementDetails
