import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import weaver from "../../assets/images/weaver.png";

const AllbaroManagementScreen = props => {

  const [fileArr1, setFileArr1] = useState([])
  const [fileArr2, setFileArr2] = useState([])
  const [fileArr3, setFileArr3] = useState([])

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.registerUser(values)
  }

  const deleteFile1 = (item) => {
    setFileArr1(fileArr1.filter(data => data !== item))
  }

  const deleteFile2 = (item) => {
    setFileArr2(fileArr2.filter(data => data !== item))
  }

  const deleteFile3 = (item) => {
    setFileArr3(fileArr3.filter(data => data !== item))
  }

  useEffect(() => {
    props.apiError("")
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
        <title>올바로 등록관리 |  IoT Monitor</title>
      </MetaTags>
      <div className="account-pages">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="올바로 등록 관리" />

          <Row className="justify-content-center">
            <Col md={8} lg={6} style={{width: '20%', minWidth: 435}}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">올바로 등록 관리</h5>
                    <p className="text-white-50"></p>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.user && props.user ? 
                      // (
                      //   <Alert color="success">
                      //     Register User Successfully
                      //   </Alert>
                      // )
                      null
                       : null}

                      {props.registrationError &&
                        props.registrationError ? (
                          <Alert color="danger">
                            {props.registrationError}
                          </Alert>
                        ) : null}

                      <div className="mb-3">
                        <p className="noMargin" style={{fontSize: 18, color: 'black', marginTop: 12}}>올바로 등록 시 필요한 서류</p>
                      </div>
                      <div className="mb-3">
                        <p style={{marginBottom: 6, color: "black", fontSize: 15}}>- 사업자등록증</p>
                        <label className="btn btn-primary waves-effect waves-light" htmlFor="regist" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                        <input style={{display: "none"}} type="file" id="regist" accept=".pdf" multiple onChange={(e) => {
                          setFileArr1([...e.target.files])
                          console.log(e.target.files)
                        }} />
                        {fileArr1.length > 0 && fileArr1.map((item, index) => {
                          return (
                            <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                              <p className="noMargin" style={{paddingLeft: 6}}>{item.name}</p>
                              <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                                deleteFile1(item)
                              }}>X</p>
                            </div>
                          )
                        })
                        }
                      </div>
                      <div className="mb-3">
                        <p style={{marginBottom: 6, color: "black", fontSize: 15}}>- 음식물류폐기물 위·수탁 운반처리계약서</p>
                        <label className="btn btn-primary waves-effect waves-light" htmlFor="contract" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                        <input style={{display: "none"}} type="file" id="contract" accept=".pdf" multiple onChange={(e) => {
                          setFileArr2([...e.target.files])
                          console.log(e.target.files)
                        }} />
                        {fileArr2.length > 0 && fileArr2.map((item, index) => {
                          return (
                            <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                              <p className="noMargin" style={{paddingLeft: 6}}>{item.name}</p>
                              <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                                deleteFile2(item)
                              }}>X</p>
                            </div>
                          )
                        })
                        }
                      </div>
                      <div className="mb-3">
                        <p style={{marginBottom: 6, color: "black", fontSize: 15}}>- 공동인증서</p>
                        <label className="btn btn-primary waves-effect waves-light" htmlFor="certification" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                        <input style={{display: "none"}} type="file" id="certification" accept=".pdf" multiple onChange={(e) => {
                          setFileArr3([...e.target.files])
                          console.log(e.target.files)
                        }} />
                        {fileArr3.length > 0 && fileArr3.map((item, index) => {
                          return (
                            <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                              <p className="noMargin" style={{paddingLeft: 6}}>{item.name}</p>
                              <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                                deleteFile3(item)
                              }}>X</p>
                            </div>
                          )
                        })
                        }
                      </div>

                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            등록하기
                          </button>
                        </div>
                      </div>
                      
                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      </div>
    </React.Fragment>
  )
}

AllbaroManagementScreen.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(AllbaroManagementScreen)
