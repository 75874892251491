import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { useSelector } from "react-redux";
import store from "../../store"
import { setRefresh } from "store/auth/data/actions";
import { CLEAR_AUTH } from "../../store/auth/profile/actionTypes";
import { LOGOUT_USER } from "store/auth/login/actionTypes";
import { CLEAR_DATA } from "store/auth/data/actionTypes";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { withdrawlUser, deleteAuth } from "../../crud/auth.crud"

import Util from "pages/Dashboard/Common/Util";
import persistor from "index"

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

const WithdrawlUser = () => {
  const selector = useSelector(state => state)

  const [memberId, setMemberId] = useState('');
  const [userId, setUserId] = useState(selector.auth?.user?.id || '')
  const [memberName, setMemberName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [companyAddress1, setCompanyAddress1] = useState('');
  const [companyAddress2, setCompanyAddress2] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [memberEmail, setMemberEmail] = useState('');
  const [memberTelephone, setMemberTelephone] = useState('');
  const [memberFax, setMemberFax] = useState('');
  const [memberCellphone, setMemberCellphone] = useState('');
  const [myProfileData, setMyProfileData] = useState(selector.Profile?.auth?.[0] || selector.auth?.user || {});
  const [companyData, setCompanyData] = useState({});
  const [onUpload, setOnUpload] = useState(false);
  
  useEffect(() => {            
    const companyData = myProfileData?.member_group?.[0]?.company_group;
    setCompanyData(companyData || {}),
    setMemberId(myProfileData['user_id'] || '')
    setMemberName(myProfileData['username'] ||'')
    setCompanyName(companyData['name'] || '')
    setMemberEmail(myProfileData['email'] || '')
    setCompanyNumber(companyData['company_number'] || '')
    setZipCode(companyData['zipcode'] || '')
    setCompanyAddress1(companyData['address1'] || '')
    setCompanyAddress2(companyData['address2'] || '')
    setMemberTelephone(companyData['phone'] || '')
    setMemberFax(companyData['fax'] || '')
    setMemberCellphone(companyData['mobile_phone'] || '')
  }
, [])

  const handleSubmit = async () => {
    if (confirm("정말 회원 탈퇴 하시겠습니까?")) {
      setOnUpload(true)
      const params = {
        id : userId
      }
      const response = await withdrawlUser(params)  
      console.log(response)
      if(response.data?.result === 'success'){
        store.dispatch(setRefresh('main'));
        store.dispatch({type: CLEAR_DATA})
        store.dispatch({type: CLEAR_AUTH});
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('REFRESH_TOKEN');
        localStorage.removeItem('authUser');
        await persistor.purge()
        deleteAuth()
        setOnUpload(false)
        alert('정상적으로 탈퇴되었습니다.')
        return store.dispatch({type: LOGOUT_USER})
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
        <title>회원탈퇴 |  IoT Monitor</title>
      </MetaTags>
      <div className="account-pages">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="회원탈퇴" />

          <Row className="justify-content-center">
            <Col md={8} lg={6} style={{width: '20%', minWidth: 435}}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">회원탈퇴</h5>
                    <p className="text-white-50"></p>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>아이디</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{memberId}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>업체명</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{companyName}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>대표자명</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{memberName}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>사업자등록번호</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{Util.checkBusinessNumber(companyNumber)}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <div style={{display: 'flex'}}>
                          <div>
                            <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>주소</p>
                            <p className="noMargin" style={{fontSize: 16, color: 'black', paddingTop: 6}}>{zipCode}</p>
                          </div>
                        </div>
                        <p className="noMargin" style={{fontSize: 16, color: 'black', paddingTop: 6}}>{companyAddress1 + ' ' + companyAddress2}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>이메일</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{memberEmail}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>전화번호</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{Util.handlePhoneNumberChange(memberTelephone)}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>팩스</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{Util.handlePhoneNumberChange(memberFax)}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>휴대폰 번호</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{Util.handleMobileNumberChange(memberCellphone)}</p>
                      </div>

                      <div className="mt-4 row">
                        <div className="col-12 text-center">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={ async () => {
                              handleSubmit()
                            }}
                          >
                              회원 탈퇴하기
                          </button>
                        </div>
                      </div>
                      

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                        </div>
                      </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

export default WithdrawlUser