import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link, useHistory } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import { useSelector } from 'react-redux';

import axios from "axios";
import { saveAs } from "file-saver";

import { uncontractCall } from "../../crud/auth.crud"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import loading from "../../assets/images/loading.gif";

const Uncontracted = () => {

  const history = useHistory()

  // 드롭다운
  const [settingMenu, setSettingMenu] = useState(false)

  // useState가 list일 땐 목록창, detail일 땐 디테일창, modify일 땐 수정창
  const [form, setForm] = useState('list')

  // contractDataList 객체 추출용
  const [detailData, setDetailData] = useState({})

  const selector = useSelector(state => state);
  console.log("설렉터", selector)

  const [page, setPage] = useState(1)
  const [dataList, setDataList] = useState([])
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState()

  const nextGetContract = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for(let i = pagesGroup - 1; i < pagesGroup + 4; i++){
      console.log("페이지 일치 여부", page, i + 1)
      if(i + 1 <= contentCount){
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getContract = async () => {
    setOnUpload(true)
    console.log("page ???", page)
    const params = {
      company: selector.auth?.user?.member_group?.[0]?.company_group.id,
      state: 'Y',
      page: page
    }
    console.log('params:: ', params)
    const rs = await uncontractCall(params)
    if(!contentCount || contentCount !== Math.floor(rs.data.count / 15) + 1){
      setContentCount(Math.floor(rs.data.count / 15) + 1)
      let newArr = []
      for(let i = pagesGroup - 1; i < pagesGroup + 4; i++){
        console.log("페이지 일치 여부", page, i + 1)
        if(i + 1 <= Math.floor(rs.data.count / 15) + 1){
          newArr.push(i + 1)
        }
      }
      setPageArr(newArr)
    }
    const content = rs.data?.results?.data || []
    if (content.length > 0) {
      console.log("datalist>>>", [...content])
      setDataList([...content])
    } else {
      setDataList(false)
    }
    setOnUpload(false)
  }

  useEffect(()=>{
    nextGetContract()
  }, [pagesGroup])

  useEffect(()=>{
    getContract()
  }, [page])

  const fileDownload = async (item) => {
    for (let i = 0; i < item.length; i++) {
      let url = item[i].path.replace("127.0.0.1:5010", "Iot_monitor.ropiklabs.com")
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const imgUrl = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = imgUrl;
          if(item[i].path.indexOf('.pdf') === -1){
            link.setAttribute('download', '계약서.png');
          } else {
            link.setAttribute('download', '계약서.pdf');
          }
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error('에러내용 >>> ', error)
        })
    }
  };

  const clickList = (item) => {
    setDetailData({...item});
    console.log("item ::", item)
    const url = {
      pathname : `/contract-management-screen-detail`,
      state : {...item}
    }
    history.push(url);
  }

  return (
          <Row>
            <Col xs="12">
              <div className="email-rightbar mb-3">
                <Card>
                  <CardBody style={{padding: 0, borderRadius: 4, overflow: 'hidden'}}>                 
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                          <thead className="chart-thead">
                          <tr>
                            <th scope="col" >(#) <span>ID</span></th>
                            <th scope="col" >계약명</th>
                            <th scope="col" >계약일자</th>
                            <th scope="col" >계약기간</th>
                            {/* <th scope="col" >다운로드</th> */}
                          </tr>
                        </thead>
                        <tbody className="chart-tbody clickable">

                          {
                            dataList && dataList.map((item, index) => {
                              return (
                                <tr key={item.id + '_' + index}>
                                  <td onClick={() => {clickList(item)}} scope="row">{item.contract}</td>
                                  <td onClick={() => {clickList(item)}}>{item.contract_group_data.subject}</td>
                                  <td onClick={() => {clickList(item)}}>{item.contract_group_data.created_at.substr(0, 10)}</td>
                                  <td onClick={() => {clickList(item)}}>{item.contract_group_data.start_date.substr(0, 10)} ~ {item.contract_group_data.end_date.substr(0, 10)}</td>
                                </tr>
                              )
                            })
                          }
                          {!dataList &&
                            <tr >
                              <td scope="row" colSpan="5">데이터가 없습니다.</td>                              
                            </tr>
                          }

                        </tbody>
                      </table>
                    </div>
                  </CardBody>

                </Card>
                {form === 'list' &&
                  <Row>
                  <Col xs="12" style={{ textAlign: "center" }}>
                    <div className="btn-group mt-4 waver-pagination-btn-group">
                      <Button                          
                        type="button"                          
                        size="sm"
                        className={page !== 1 ? 'waves-effect left-btn active' : 'waves-effect left-btn' }
                        onClick={() => {
                          if (page !== 1) {
                            setPage(page - 1)
                            if (Math.min(...pageArr) === page) {
                              setPagesGroup(pagesGroup - 5)
                            }
                          }
                        }}
                      >
                        <i className="fa fa-chevron-left" />
                      </Button>
                      {pageArr && pageArr.map((item, index) => {
                        return (
                          <Button
                            key={index}                              
                            type="button"
                            size="sm"
                            className={page === item ? 'waves-effect center-btn active' : 'waves-effect center-btn' }                              
                            onClick={() => {
                              setPage(item)
                            }}
                          >
                            {item}
                          </Button>
                        )
                      })}
                      <Button                        
                        type="button"                          
                        size="sm"
                        className={page !== contentCount ? 'waves-effect right-btn active' : 'waves-effect right-btn' }    
                        onClick={() => {
                          if (page !== contentCount) {
                            setPage(page + 1)
                            if (Math.max(...pageArr) === page) {
                              setPagesGroup(pagesGroup + 5)
                            }
                          }
                        }}
                      >
                        <i className="fa fa-chevron-right" />
                      </Button>
                    </div>
                  </Col>
                </Row>
                }
              </div>
            </Col>
            {onUpload && <div style={{
              position: "absolute",
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              background: 'rgba(255, 255, 255, 0.6)',
              zIndex: 999,
              display: "flex",
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <img src={loading} alt="로딩중입니다." width={'5%'}></img>
            </div>}
          </Row>
  )
}

export default Uncontracted
