import React, { useEffect, useState } from "react"
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import store from "store";


class Emitter extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            sector: '',
            businessType: '',
            companyListFile: [],
            companyTotalListFile: [],
            modalShow: false,
            dailyEmission: '',
            collection: '',
            desiredCollectionDays: [],
            area: '',
            waterPerDay: '',
        }
        const code = store.getState().Data?.code[0] || []
        this.sectors = code.filter(item => item.code === 'sectors')
        this.businessTypes = code.filter(item => item.code === 'business_types')
        this.dailyEmissions = code.filter(item => item.code === 'dailyEmissions')
        this.collections = code.filter(item => item.code === 'collections')
        this.desiredCollectionDays = code.filter(item => item.code === 'weeks')
    }

    deleteFile = (str) => {
        this.setState({ [str]: [] })
    }

    isValiddate = () => {
        if (this.state.sector === '') {
            alert("업종을 선택해주세요.")
            return ""
        }
        if (this.state.businessType === '') {
            alert("사업장형태를 선택해주세요.")
            return ""
        }
        if (this.state.area.length > 0 && isNaN(this.state.area * 1)){
            return alert('사업장 면적에는 숫자가 들어가야 합니다.')
        }
        if (this.state.dailyEmission.length === 0) {
            alert("일 배출량을 입력해주세요.")
            return ""
        } else if (isNaN(this.state.dailyEmission * 1)){
            return alert('일 배출량에는 숫자가 들어가야 합니다.')
        }
        if (this.state.waterPerDay.length > 0 && isNaN(this.state.waterPerDay * 1)){
            return alert('일 식수인원에는 숫자가 들어가야 합니다.')
        }
        if (this.state.collection === '') {
            alert("예상수거량을 선택해주세요.")
            return ""
        }
        if(this.state.desiredCollectionDays.length === 0){
            alert("희망수거일을 선택해주세요.")
            return ""
        }
        if (this.state.businessType === 'multi' && this.state.companyListFile.length === 0) {
            alert("다수사업장 리스트를 업로드 해주세요.")
            return ""
        }
        if (this.state.businessType === 'total' && this.state.companyTotalListFile.length === 0) {
            alert("사업장배출자 리스트를 업로드 해주세요.")
            return ""
        }
        return "success";
    }

    registFiles = (str, e) => {
        this.setState({ [str]: [...e.target.files] })
    }

    fileDownload = async (str) => {
        let url = `https://waveradmin.ropiklabs.com/media/sample/${str}.xlsx`
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const excelUrl = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = excelUrl;
                link.setAttribute('download', `${str}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.error(error)
            })
    };

    handleDesiredCollectionDays = (item, num) => {                
        if(this.state.desiredCollectionDays.filter(itm => itm.key === item.key).length > 0){            
            this.setState({desiredCollectionDays : [...this.state.desiredCollectionDays.filter(value => value.key !== item.key)]})
        }else if(this.state.desiredCollectionDays.length < num){
            this.setState({desiredCollectionDays : [...this.state.desiredCollectionDays, item]})
        }        
    }

    render() {
        return (
            <>
                <div className="mb-3">
                    <legend>업종선택</legend>
                    {
                        this.sectors && this.sectors.map((item, index) => {
                            return (
                                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name='sectors' value={item.key} onChange={() => { this.setState({ sector: item.key }) }} />
                                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="mb-4">
                    <legend>사업장 면적</legend>
                    <div style={{display: "flex"}}>
                        <input className="is-touched is-dirty av-valid form-control" style={{marginTop: 3, width: '50%'}} onChange={(e)=>{
                            this.setState({area: e.target.value})
                        }} />
                        <p className="noMargin" style={{lineHeight: '36px'}}>&nbsp;㎡</p>
                    </div>
                </div>

                <div className="mb-4">
                    <legend>일 식수인원</legend>
                    <div style={{display: "flex"}}>
                        <input className="is-touched is-dirty av-valid form-control" style={{marginTop: 3, width: '50%'}} onChange={(e)=>{
                            this.setState({waterPerDay: e.target.value})
                        }} />
                        <p className="noMargin" style={{lineHeight: '36px'}}>&nbsp;명</p>
                    </div>
                </div>

                <div className="mb-4">
                    <legend>일 배출량</legend>
                    <div style={{display: "flex"}}>
                        <input className="is-touched is-dirty av-valid form-control" style={{marginTop: 3, width: '50%'}} onChange={(e)=>{
                            this.setState({dailyEmission: e.target.value})
                        }} />
                        <p className="noMargin" style={{lineHeight: '36px'}}>&nbsp;Kg</p>
                    </div>
                </div>

                <div className="mb-3">
                    <legend>예상수거량(용기통 L수 확인 후 체크)</legend>
                    {
                        this.collections && this.collections.map((item, index) => {
                            return (
                                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name='collections' value={item.key} onChange={() => { this.setState({ collection: item.key }) }} />
                                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="mb-3">
                    <legend>희망수거일(최대 3회까지 선택 가능)</legend>
                    {
                        this.desiredCollectionDays && this.desiredCollectionDays.map((item, index) => {
                            return (
                                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                                    <input className="is-untouched is-pristine av-valid form-check-input" checked={this.state.desiredCollectionDays.filter(data => data.key == item.key).length > 0} type='checkbox' id={item.key} name='desiredCollectionDays' value={item.key} onChange={() => {
                                        // this.setState({desiredCollectionDays: item.key})
                                        this.handleDesiredCollectionDays(item, 3)
                                    }} />
                                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="mb-3">
                    <legend>사업장형태</legend>
                    {
                        this.businessTypes && this.businessTypes.map((item, index) => {
                            return (
                                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name='businessTypes' value={item.key} onChange={() => { this.setState({ businessType: item.key }) }} />
                                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                                </div>
                            )
                        })
                    }
                </div>

                {this.state.businessType === 'multi' &&
                    <div>
                        <div className="mb-3">
                            <p className="noMargin" style={{ fontSize: 18, color: 'black', marginTop: 12 }}>다수사업장 리스트 업로드</p>
                        </div>
                        <div className="mb-3">
                            <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>- 사업장명/대표자명/사업장등록번호/우편번호/주소/연락처/일배출량/희망수거일 필수</p>
                            <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{ height: 36, marginLeft: 6 }}>파일 선택</label>
                            <label className="btn btn-primary waves-effect waves-light" style={{ height: 36, marginLeft: 6 }} onClick={() => { this.fileDownload('다수사업장등록엑셀') }}>샘플 받기</label>
                            <input style={{ display: "none" }} type="file" id="ex_file" accept=".xls,.xlsx" onChange={(e) => {
                                this.registFiles('companyListFile', e)
                            }} />
                            {this.state.companyListFile?.length > 0 && this.state.companyListFile.map((item, index) => {
                                return (
                                    <div key={index} style={{ border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6 }}>
                                        <p className="noMargin" style={{ paddingLeft: 6 }}>{item.name}</p>
                                        <p className="noMargin" style={{ paddingRight: 6, color: "#000", cursor: "pointer" }} onClick={() => {
                                            this.deleteFile('companyListFile')
                                        }}>X</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                }
                {this.state.businessType === 'total' &&
                    <div>
                        <div className="mb-3">
                            <p className="noMargin" style={{ fontSize: 18, color: 'black', marginTop: 12 }}>사업장배출자 리스트 업로드</p>
                        </div>
                        <div className="mb-3">
                            <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>- 사업장명/대표자명/사업장등록번호/우편번호/주소/연락처/일배출량/희망수거일 필수</p>
                            <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{ height: 36, marginLeft: 6 }}>파일 선택</label>
                            <label className="btn btn-primary waves-effect waves-light" style={{ height: 36, marginLeft: 6 }} onClick={() => { this.fileDownload('사업장배출자등록엑셀') }}>샘플 받기</label>
                            <input style={{ display: "none" }} type="file" id="ex_file" accept=".xls,.xlsx" onChange={(e) => {
                                this.registFiles('companyTotalListFile', e)
                            }} />
                            {this.state.companyTotalListFile?.length > 0 && this.state.companyTotalListFile.map((item, index) => {
                                return (
                                    <div key={index} style={{ border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6 }}>
                                        <p className="noMargin" style={{ paddingLeft: 6 }}>{item.name}</p>
                                        <p className="noMargin" style={{ paddingRight: 6, color: "#000", cursor: "pointer" }} onClick={() => {
                                            this.deleteFile('companyTotalListFile')
                                        }}>X</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                }
                <div className="mb-3 row">
                    <div className="col-12 text-end">
                        <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={() => {
                                if (this.isValiddate() === 'success') {
                                    this.props.handlePress({
                                        ...this.state
                                    })
                                }
                            }}
                        >
                            회원가입 완료하기
                        </button>
                    </div>
                </div>
            </>
        );
    }
};

export default Emitter;


