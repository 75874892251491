import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { getSellProductDetail, patchTradeRequest1, patchTradeRequest2, patchTradeRequest3, patchtradeReply, postTradeRequest, sellComplete1, sellComplete2, sellComplete3, sellComplete4, sellDelete1, sellDelete2, sellDelete3, sendReplay } from 'crud/auth.crud';

import { useLocation } from "react-router-dom"
import moment from "moment";
import _ from "lodash";

import { useSelector } from "react-redux";
const SellDetailScreen = () => {
  const selector = useSelector(state => state)

  const location = useLocation()

  const [form, setForm] = useState('detail')
  const [type, setType] = useState(selector.auth.user?.type || "")
  const [id, setId] = useState(location.state.id);
  const [myCompany, setMyCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group);
  const [myProfile, setMyProfile] = useState(selector.auth?.user)
  const [data, setData] = useState({});
  const [onUpload, setOnUpload] = useState(false);
  const [tradeRequest, setTradeRequest] = useState({})
  const [related, setRelated] = useState(false)
  const [isMe, setIsMe] = useState(false)
  const [chatList, setChatList] = useState([])
  const [chat, setChat] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [requestQty, setRequestQty] = useState('')
  const [opponent, setOpponent] = useState('')


  const getData = async () => {       
    const response = await getSellProductDetail(id);
    if(response.status === 200){
      console.log(response.data)
      setData(response.data)
      const rqData = response.data?.request_list?.filter(itm => itm.status === 'Y')?.[0] || {};
      const related = response.data?.request_list?.filter(itm => itm.status === 'Y' && itm.member === myProfile.id).length > 0 || response.data.member === myProfile?.id
      const isMe = type === 'treatment' && response.data.member === myProfile?.id
      setTradeRequest(rqData)
      console.log(rqData)
      setRelated(related)
      setIsMe(isMe)
      setChatList(rqData.chat_list)
      setOpponent(type === 'treatment' ? response.data?.request_list?.filter(itm => itm.status === 'Y')?.[0]?.company_data?.name : response.data?.company_data?.name)
    }   
    setIsLoading(false)
  }

  const openTrade = () => { 
    if (confirm(`${data.subject} ${requestQty}Kg을 ${(requestQty || 0) * (data.price_per_kg || 0)}원에 구매요청을 하시겠습니까?`)){
      doTradeRequest()
    }     
  }

  const doTradeRequest = async() =>{
    setOnUpload(true)
    setModalShow(false)
    const params = {
        trade : id,
        trade_request_id : tradeRequest?.id,
        company : myCompany.id,
        member : myProfile.id,
        qty : requestQty,
        price_per_kg : data?.price_per_kg,
        type : 'buy',
        state : 'N',
        status : 'Y',            
    }
    const response = await postTradeRequest(params)
    console.log(response)
    if(response.status === 200){
      alert(response.data.msg)
      getData()
    }
    setOnUpload(false)
    setRequestQty('')  
  }

  const openTradeComplete = async () =>{
    if (confirm(`${data.subject} ${tradeRequest.qty}Kg을 ${(tradeRequest.qty || 0) * (data.price_per_kg || 0)}원에 판매완료 하시겠습니까?`)){
      const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      const response = await sellComplete1(tradeRequest.id, date)
      if(response.status === 200){                    
        const tradeRs = await sellComplete2(id, date)
        if(tradeRs.status === 200){
          const params = {
            trade_id : id,
            company_id : myCompany.id,
            member_id : myProfile.id,
            qty : data?.qty,
            price_per_kg : data?.price_per_kg,
            complete_date : date,
            type : 'sell',
            state : 'Y',
            status : 'Y',
          }
          const rs = await sellComplete3(params)
          const params2 = {
            trade_id : id,
            company_id : tradeRequest.company,
            member_id : tradeRequest.member,
            qty : tradeRequest.qty,
            price_per_kg : data?.price_per_kg,
            complete_date : date,
            type : 'buy',
            state : 'Y',
            status : 'Y',
          }
          const rs2 = await sellComplete4(params2)
          if(rs2.status === 201){
            alert('정상적으로 판매완료 되었습니다.')
            getData()
          }
        }                    
      }
    }
    return true;        
  }

  const deleteTrade = async () => {
    console.log("아이디",id)
    if (confirm("판매를 취소하시겠습니까?")){
      {                
        const response = await sellDelete1(tradeRequest.id)
        console.log(response)
        if(response.status === 200){
          const params = {
            trade_id : id,
            trade_request_id : tradeRequest.id,
            company_id : myCompany.id,
            member_id : myProfile.id,
            qty : tradeRequest.qty,
            price_per_kg : data?.price_per_kg,
            type : 'buy',
            state : 'N',
            status : 'N',
          }
          console.log(params)
          const tradeRs = await sellDelete2(id)
          console.log(tradeRs)
          if(tradeRs.status === 200){
            const rs = await sellDelete3(params)
            console.log(rs)
            if(rs.status === 201){
              alert('정상적으로 판매취소 되었습니다.')
              getData()
            }
          }                    
        }
      }
    }
    return true;        
  }

  const handleSend = async () => {
    const params = {
      content : chat,
      status : 'Y',
      company : myCompany.id,
      member : myProfile.id,
      trade_request : tradeRequest?.id
    }

    const response = await sendReplay(params);
    setChat('')
    setChatList([...chatList, response.data])
  }

  const deleteTradeRequest = async () => {
    console.log(tradeRequest.id)
    if (confirm('구매요청을 취소하시겠습니까?')){
      const response = await patchTradeRequest1(tradeRequest.id)
      if(response.status === 200){
        const params = {
          trade_id : id,
          company_id : myCompany.id,
          member_id : myProfile.id,
          qty : tradeRequest.qty,
          price_per_kg : data?.price_per_kg,
          type : 'buy',
          state : 'N',
          status : 'N',
        }
        const tradeRs = await patchTradeRequest2(id)
        if(tradeRs.status === 200){
          const rs = await patchTradeRequest3(params)
          if(rs.status === 201){
            alert('정상적으로 구매 취소 되었습니다.')
            getData()
          }
        }
      }
    }
    return true
  }

  const deleteChat = async (id) => {
    if (confirm('해당 댓글을 삭제하시겠습니까?')){
      console.log(id)
      const rs = await patchtradeReply(id)
      console.log(rs)
      if(rs.status === 200){
        let tmp = [...chatList]
        console.log(tmp)
        tmp.filter(itm => itm.id === id)[0].status = 'N'
        setChatList(tmp)
      }
    }
    return true;        
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>제품상세 |  IoT Monitor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="홈" title="거래소" breadcrumbItem="제품 상세" />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              {/* <EmailSideBar /> */}
              <div className="email-rightbar mb-3">
                <Card>
                <CardBody style={{padding: 48}}>
                  <div onClick={()=>{window.history.back()}}
                    style={{padding: '0.4rem 1rem', borderRadius: '0.2rem', marginBottom: 48, fontSize: 16}}
                    className="btn btn-primary dropdown-toggle waves-effect waves-light bg-success"
                  >뒤로가기
                  </div>
                  {form === 'detail' && 
                  <>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>상품명</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}} >{data.subject}</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>판매상태</p>
                      <p style={{fontSize: 16, lineHeight: '35px', width: 480, color: data.state == 'Y' ? 'blue' : data.state == 'U' ? 'red' : '#48C3B1'}} >{data.state == 'Y' ? "판매완료" : data.state == 'U' ? "판매중" : "판매대기"}</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>Kg당 요금</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}}>{data.price_per_kg}</p>
                      <p style={{lineHeight: '35px'}}>&nbsp;원</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>수량</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}}>{data.qty}</p>
                      <p style={{lineHeight: '35px'}}>&nbsp;Kg</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>총 요금</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}}>{(data.qty || 0) * (data.price_per_kg || 0)}</p>
                      <p style={{lineHeight: '35px'}}>&nbsp;원</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>전화번호</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}} >{data.company_data?.phone}</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>주소</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}} >{data.address}</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>상세설명</p>
                      <p style={{fontSize: 16, lineHeight: '35px', width: 480}} >{data.content}</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 600}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>제품 사진</p>
                      {
                      data?.['trade_file']?.length > 0 &&
                      <img src={data?.trade_file?.[0]?.path.replace("http://127.0.0.1:5010", "http://Iot_monitor.ropiklabs.com")} style={{fontSize: 16, lineHeight: '35px', padding: 3, border: "1px solid #ced4da"}} />
                      }
                    </div>
                  </>
                  }
                  {tradeRequest && !_.isEmpty(tradeRequest) &&
                  <>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>구매상태</p>
                      <p style={{fontSize: 16, lineHeight: '35px', width: 480, color: data.state == 'Y' ? 'blue' : '#48C3B1'}} >{data.state == 'Y' ? "구매완료" : "구매중"}</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>Kg당 요금</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}}>{tradeRequest.price_per_kg}</p>
                      <p style={{lineHeight: '35px'}}>&nbsp;원</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>구매 수량</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}}>{tradeRequest.qty}</p>
                      <p style={{lineHeight: '35px'}}>&nbsp;Kg</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>총 요금</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}}>{(tradeRequest.qty || 0) * (tradeRequest.price_per_kg || 0)}</p>
                      <p style={{lineHeight: '35px'}}>&nbsp;원</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>전화번호</p>
                      <p style={{fontSize: 16, lineHeight: '35px'}} >{myCompany.phone}</p>
                    </div>
                    {related && data.state == 'U' &&
                    <div style={{display: "flex", marginBottom: 50}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>채팅창</p>
                      <div style={{width: '60%'}}>
                        <div style={{border: '1px solid #ced4da', width: '100%', height: 360, overflowY: "scroll", padding: 24}} >
                          {chatList?.length > 0 && chatList.map((item, index)=>{return (
                            <div key={index} style={{width: '100%', display: "flex", fontSize: 16}}>
                              {myCompany.id === item.company ?
                                <>
                                  <div style={{width: '40%'}}></div>
                                  <div style={{width: '60%', display: "flex", flexDirection: "row-reverse"}}>
                                    <div>
                                      <p className="noMargin" style={{width: 120, textAlign: 'right'}}>&nbsp;:&nbsp;{myCompany.name}</p>
                                      <p style={{fontSize: 11, textAlign: 'right'}}>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                                    </div>
                                    <p style={{fontSize: 16, textAlign: 'right', paddingLeft: 12, cursor: "pointer", display: item.status === 'Y' ? "block" : "none"}} onClick={()=>{
                                      deleteChat(item.id)
                                    }}>X</p>
                                    <p style={{width: 'calc(100% - 120px)', textAlign: 'right', color: item.status === 'N' && '#8C734B'}}>{item.status === 'Y' ? item.content : '삭제된 댓글입니다.'}</p>
                                  </div>
                                </>
                                :
                                <>
                                  <div style={{width: '60%', display: 'flex'}}>
                                    <div>
                                      <p className="noMargin" style={{width: 120}}>{opponent}&nbsp;:&nbsp;</p>
                                      <p style={{fontSize: 11}}>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                                    </div>
                                    <p style={{width: 'calc(100% - 120px)', color: item.status === 'N' && '#8C734B'}}>{item.status === 'Y' ? item.content : '삭제된 댓글입니다.'}</p>
                                  </div>
                                  <div style={{width: '40%'}}></div>
                                </>
                              }
                            </div>
                          )})}
                        </div>
                        <div style={{width: '100%', display: 'flex', height: 42}}>
                          <input className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} value={chat} onKeyPress={(e) => {if (e.key == 'Enter') {
                              handleSend()
                            }
                          }} onChange={(e)=>{
                            setChat(e.target.value)
                          }} />
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{margin: '3px 0 0 6px', width: 84}} onClick={() => {
                            handleSend()
                          }} value="전송하기" />
                        </div>
                      </div>
                    </div>
                    }
                  </>
                  }
                    {data.state === 'N' && type === 'demand' &&
                    <Button
                      type="button"
                      color="primary"
                      style={{padding: '0.4rem 1.75rem', borderRadius: '0.2rem', marginLeft: 12}}
                      onClick={() => {
                        setModalShow(true)
                      }}
                    >
                    구매 요청하기
                    </Button>
                    }
                    {data.state === 'U' && isMe &&
                    <Button
                      type="button"
                      color="warning"
                      style={{padding: '0.4rem 1.75rem', borderRadius: '0.2rem', marginLeft: 12}}
                      onClick={() => {
                        openTradeComplete()
                      }}
                    >
                    판매 완료하기
                    </Button>
                    }
                    {data.state !== 'Y' && type === 'treatment' && isMe &&
                    <Button
                      type="button"
                      color="danger"
                      style={{padding: '0.4rem 1.75rem', borderRadius: '0.2rem', marginLeft: 12}}
                      onClick={() => {
                          console.log(tradeRequest.id)
                        deleteTrade()
                      }}
                    >
                    판매 취소하기
                    </Button>
                    }
                    {data.state === 'U' && type === 'demand' && related &&
                    <Button
                      type="button"
                      color="danger"
                      style={{padding: '0.4rem 1.75rem', borderRadius: '0.2rem', marginLeft: 12}}
                      onClick={() => {
                        deleteTradeRequest()
                      }}
                    >
                    구매 취소하기
                    </Button>
                    }
                  </CardBody>
                  
                </Card>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
      {modalShow && <div style={{
        position: "fixed",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div style={{width: 480, height: 480, backgroundColor: "#fff", zIndex: 1000, padding: 36}}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <p style={{fontSize: 20, color: 'black', fontWeight: 'bold'}}>{data.subject}</p>
            <p style={{cursor: "pointer", fontSize: 20}} onClick={()=>{
              setModalShow(false)
              setRequestQty('')
            }}>X</p>
          </div>
          <div style={{padding: 12}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <p style={{fontSize: 16}}>Kg당 요금</p>
              <p style={{fontSize: 16, color: 'black', fontWeight: 'bold'}}>{data.price_per_kg} 원</p>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <p style={{fontSize: 16}}>최대 수량</p>
              <p style={{fontSize: 16, color: 'black', fontWeight: 'bold'}}>{data.qty} Kg</p>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <p style={{fontSize: 16}}>구매 수량</p>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <input style={{width: 72, height: 30, padding: 6, marginLeft: '0.5rem', marginRight: 6}} placeholder="수량" className="form-control" onChange={(e)=>{
                  setRequestQty(e.target.value)
                }}></input>
                <p style={{fontSize: 16, color: 'black', fontWeight: 'bold'}}>Kg</p>
              </div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <p style={{fontSize: 16}}>구매 가격</p>
              <p style={{fontSize: 16, color: 'black', fontWeight: 'bold'}}>{(requestQty || 0) * (data.price_per_kg || 0)} 원</p>
            </div>
          </div>
          <Button
            type="button"
            color="warning"
            style={{padding: '0.4rem 1.75rem', borderRadius: '0.2rem', float: "right"}}
            onClick={() => {
              openTrade()
            }}
          >
          구매 요청
          </Button>
        </div>
      </div>}
    </React.Fragment>
  )
}

export default SellDetailScreen
