// export const LOGIN_USER = "LOGIN_USER"
// export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

// export const LOGOUT_USER = "LOGOUT_USER"
// export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
// export const API_ERROR = "LOGIN_API_ERROR"

export const SET_ALARM_COUNT = "SET_ALARM_COUNT"
export const SET_INTRO = "SET_INTRO"
export const SET_REFRESH = "SET_REFRESH"
export const SET_CODE = "SET_CODE"
export const SET_CONTRACT_STATE = 'SET_CONTRACT_STATE'
export const SET_EXCHANGE_STATE = 'SET_EXCHANGE_STATE'
export const CLEAR_DATA = 'CLEAR_DATA'