import React, { useEffect, useState } from "react"
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import store from "store";


class Collection extends React.PureComponent {

    constructor(props) {
        super(props);        
        this.state = {            
            area : '',
            waterPerDay : '',
            dailyEmission : '',
            collection : '',
            desiredCollectionDays : [],            
            companyFileArray: [],
            reportFileArray : [],
            certificateFile : [],
        }
        const code = store.getState().Data?.code[0] || []
        this.areaList = code.filter(item => item.code === 'areaList')
        this.waterPerDays = code.filter(item => item.code === 'waterPerDays')
        this.dailyEmissions = code.filter(item => item.code === 'dailyEmissions')
        this.collections = code.filter(item => item.code === 'collections')
        this.desiredCollectionDays = code.filter(item => item.code === 'weeks')

        this.touchFileUploadButtonData = [
            {'id' : 'companyFileArray', 'name' : '- 사업장 등록증', 'type' : 'multi'},
            {'id' : 'reportFileArray', 'name' : '- 음식물류폐기물 발생억제 및 처리계획 신고서', 'type' : 'multi'},            
            {'id' : 'certificateFile', 'name' : '- 공동 인증서', 'type' : 'single'},            
        ]
    }

    validateAddCompany = () => {
    }


    handleError = (err) => {
        if (DocumentPicker.isCancel(err)) {
            console.warn('cancelled')
            // User cancelled the picker, exit any dialogs or menus and move on
        } else if (isInProgress(err)) {
            console.warn('multiple pickers were opened, only the last will be considered')
        } else {
            throw err
        }
    }

    openFilePicker= async (str) => {
        try {
          const pickerResult = await DocumentPicker.pickSingle({
            presentationStyle: 'fullScreen',
            //copyTo: 'cachesDirectory',      
            type: [types.xls, types.csv, types.xlsx]
          })
          console.log(pickerResult)
          this.setState({[str] : {...pickerResult}})          
        } catch (e) {
          this.handleError(e)
        }
    }

    deleteFile = (str) =>{
        this.setState({ [str] : undefined})
    }    

    isValiddate = () => {        
        if(this.state.collection === ''){
            alert("예상수거량을 선택해주세요.")
            return ""
        }
        if(this.state.desiredCollectionDays.length === 0){
            alert("희망수거일을 선택해주세요.")
            return ""
        }
        if(this.state.companyFileArray.length === 0){
            alert("사업장 등록증을 업로드해주세요.")
            return ""
        }
        if(this.state.reportFileArray.length === 0){
            alert("음식물폐기물 발생억제 및 처리계획 신고서를 업로드해주세요.")
            return ""
        }
        if(this.state.certificateFile.length === 0){
            alert("공동인증서를 업로드해주세요.")
            return ""
        }

        return "success";
    }

    registDocument = (e, item) => {
        let filteredFiles
        for(let i=0;i<e.target.files.length;i++){
          if(this.state[item.id]?.length > 0 && this.state[item.id]?.map(item => item.name).indexOf(e.target.files[i].name) != -1){
            filteredFiles = (filteredFiles || [...e.target.files]).filter(data => data !== [...e.target.files][i]);
          }
        }
        this.setState({[item.id] : item.type === 'multi' ? [...this.state[item.id], ...(filteredFiles || e.target.files)] : [...e.target.files]})
    }

    deleteDocument = (item, data) => {
        this.setState({[item.id] : this.state[item.id].filter(element => element.name !== data.name)})
    }

    handleDesiredCollectionDays = (item, num) => {                
        if(this.state.desiredCollectionDays.filter(itm => itm.key === item.key).length > 0){            
            this.setState({desiredCollectionDays : [...this.state.desiredCollectionDays.filter(value => value.key !== item.key)]})
        }else if(this.state.desiredCollectionDays.length < num){
            this.setState({desiredCollectionDays : [...this.state.desiredCollectionDays, item]})
        }        
    }

    async componentDidMount() {
        try {
            const rs = await getCode();
            const code = rs.data
            this.setState({ sectors: code.filter(item => item.code === 'sectors') })
            this.setState({ businessTypes: code.filter(item => item.code === 'business_types') })
            console.log(this.state['businessTypes'])
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
        <>
            <div className="mb-3 allbaroRegist">
                <p style={{marginBottom: 0, fontSize: 18}}>올바로 등록 대상 확인</p>
                <p style={{marginBottom: 0, fontSize: 14, color: '#ff4949'}}>(*체크 시 올바로 등록 대상 사업장입니다.)</p>
                <div className="mb-3">
                    <div>
                        <legend>· 사업장 면적</legend>
                        {   
                        this.areaList && this.areaList.map((item, index) => { return (
                            <div className="form-check form-check-inline" key={item.key+'_'+index}>
                                <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name='areaList' value={item.key} checked={this.state.area == item.key} onClick={()=>{
                                    this.state.area == item.key ? this.setState({area : ''}) : this.setState({area : item.key})
                                    }} />
                                <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                            </div>
                        )})
                        }
                    </div>
                    <div>
                        <legend>· 일 식수인원</legend>
                        {   
                        this.waterPerDays && this.waterPerDays.map((item, index) => { return (
                            <div className="form-check form-check-inline" key={item.key+'_'+index}>
                                <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name='waterPerDays' value={item.key} checked={this.state.waterPerDay == item.key} onClick={()=>{
                                    this.state.waterPerDay == item.key ? this.setState({ waterPerDay : ''}) : this.setState({waterPerDay : item.key})
                                    }} />
                                <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                            </div>
                        )})
                        }
                    </div>
                    <div>
                        <legend>· 일 배출량</legend>
                        {   
                        this.dailyEmissions && this.dailyEmissions.map((item, index) => { return (
                            <div className="form-check form-check-inline" key={item.key+'_'+index}>
                                <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name='dailyEmissions' value={item.key} checked={this.state.dailyEmission == item.key} onClick={()=>{
                                    this.state.dailyEmission == item.key ? this.setState({ dailyEmission : ''}) : this.setState({dailyEmission : item.key})
                                    }} />
                                <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                            </div>
                        )})
                        }
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <legend>예상수거량(용기통 L수 확인 후 체크)</legend>
            {   
            this.collections && this.collections.map((item, index) => { return (
                <div className="form-check form-check-inline" key={item.key+'_'+index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name='collections' value={item.key} onChange={()=>{this.setState({collection : item.key})}} />
                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <legend>희망수거일(최대 3회까지 선택 가능)</legend>
            {   
            this.desiredCollectionDays && this.desiredCollectionDays.map((item, index) => { return (
                <div className="form-check form-check-inline" key={item.key+'_'+index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" checked={this.state.desiredCollectionDays.filter(data => data.key == item.key).length > 0} type='checkbox' id={item.key} name='desiredCollectionDays' value={item.key} onChange={()=>{
                        // this.setState({desiredCollectionDays: item.key})
                        this.handleDesiredCollectionDays(item, 3)
                        }} />
                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <p className="noMargin" style={{fontSize: 18, color: 'black', marginTop: 12}}>필수 업로드 서류</p>
            </div>
            {
                this.touchFileUploadButtonData && this.touchFileUploadButtonData.map((item, index)=>{
                    return (
                    <div className="mb-3" key={item.key+'_'+index}>
                        <p style={{marginBottom: 6, color: "black", fontSize: 15}}>{item.name}</p>
                        <label className="btn btn-primary waves-effect waves-light" htmlFor={item.id} style={{height: 36, marginLeft: 6}}>파일 선택</label>
                        <input style={{display: "none"}} accept=".pdf" type="file" multiple={ item.type === 'multi' ? "multiple" : ""} id={item.id} onChange={(e)=>{
                            this.registDocument(e, item)
                        }}/>
                        {this.state[item.id]?.length > 0 && this.state[item.id]?.map((data, index) => {
                        return (
                            <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                            <p className="noMargin" style={{paddingLeft: 6}}>{data.name}</p>
                            <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                                this.deleteDocument(item, data)
                            }}>X</p>
                            </div>
                        )
                        })
                        }
                    </div>
                    )
                })
            }

            <div className="mb-3 row">
                <div className="col-12 text-end">
                    <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                    onClick={()=>{
                        if(this.isValiddate() === 'success'){
                        this.props.handlePress({
                        ...this.state
                    }, this.touchFileUploadButtonData)}}}
                    >
                        회원가입 완료하기                        
                    </button>
                </div>
            </div>
        </>
        );
    }
};

export default Collection;