import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, CardBody } from "reactstrap"
import $ from 'jquery';
import loading from "../../../assets/images/loading.gif";
import moment from "moment";

const DefaultForm = (props) => {
  const [currentPageArr, setCurrentPageArr] = useState([])
  const [pagesGroup, setPagesGroup] = useState(1) // 페이지 목록의 첫번째 숫자
  const [page, setPage] = useState(1); // 현재 페이지
  const [isSearch, setIsSearch] = useState(['0', false]);
  const [searchText, setSearchText] = useState('')

  const { form, headers, dataList, callback, boardKey, pageArr, contentCount, title, height, domKey, pageCallBack, isLoading, filterList } = props;

  const [type, setType] = useState(headers !== undefined ? headers[0].key : headers)

  const nextGetData = () => {
    let newArr = []
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    setCurrentPageArr(newArr)
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect(() => {
    if (form === 'post') {
      pageCallBack(page, domKey)
    }
  }, [page])

  const searchList = () => {
    filterList(type, searchText, domKey)
  }

  const playLoading = () => {
    return <div style={{
      position: "absolute",
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'rgba(255, 255, 255, 0.6)',
      zIndex: 999,
      display: "flex",
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <img src={loading} alt="로딩중입니다." width={'5%'}></img>
    </div>
  }

  const changeType = () => {
    setType($(`#select${domKey}`).val() !== null ? $(`#select${domKey}`).val() : $(`#option${domKey}`).val())
  }

  return (
    <>
      {form === 'post' &&
        <Card>
          <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
            <div className="table-responsive" style={{ height: height, minHeight: '250px', overflowX: 'auto' }}>
              <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                <thead className="chart-thead position-sticky" style={{ top: 0 }}>
                  {(isSearch[1] && isSearch[0] === domKey) || domKey === '99' ?
                    <></>
                    :
                    <>
                      <i className="mdi mdi-magnify position-absolute text-white font-size-22 pointer" style={{ right: 12, lineHeight: '48px', zIndex: 10 }}
                        onClick={() => {
                          setIsSearch([domKey, true])
                        }}
                      />
                    </>
                  }
                  <tr>
                    <th scope="col" className="position-relative table-title font-size-16 bg-dark-ash" colSpan={headers.length}>
                      {
                        isSearch[1] && isSearch[0] === domKey ?
                          <>
                          <div className="d-flex">
                            <select id={`select${domKey}`} className='dropdown-toggle waves-effect waves-light mb-2 waver-select-box' onChange={(e) => {
                              setType(e.target.value)
                            }}>
                              <option id={`option${domKey}`} value={headers[0].key} selected disabled hidden>{headers[0].value}</option>
                              {headers.length > 0 && headers.map((item, index) => {
                                return (
                                  <option key={item + index} value={item.key}>{item.value}</option>
                                )
                              })}
                            </select>
                            <input className="is-touched is-dirty av-valid form-control padding-right-56" placeholder="검색어 입력"
                              onClick={() => {
                                changeType()
                              }}
                              onChange={(e) => {
                                setSearchText(e.target.value)
                              }}
                              onKeyDown={(e) => {
                                if(e.key === 'Enter'){
                                  searchList()
                                }
                              }}/>
                            </div>
                            <i className="mdi mdi-magnify position-absolute text-black font-size-22 pointer noMargin x-button" style={{top: 14, right: 48}} onClick={(()=>{
                              searchList()
                            })} />
                            <p className="position-absolute text-black font-size-12 pointer noMargin x-button" style={{ top: 20, right: 18 }} onClick={() => {
                              setSearchText('')
                              setIsSearch(['0', false])
                              filterList(type, '', domKey)
                            }}>X</p>
                          </>
                          :
                          title
                      }
                    </th>
                  </tr>
                  <tr>
                    {
                      headers.length > 0 && headers.map((v) => {
                        return (
                          <th scope="col" className="bg-dark-ash">{v.value}</th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody className="chart-tbody clickable">

                  {
                    dataList && dataList.map((item, index) => {
                      return (
                        <tr key={boardKey + index} onClick={() => { callback(item.id || '', item.company || '', item.factory_id || '', item.unsign_iot_device_mac || '', domKey) }}>
                          {
                            headers && headers.map((v) => {
                              let text = item[v.key] || ''
                              if (typeof text === 'object' ) {
                                text = text.name || ''
                              }
                              if (text === '' && item.iot_device_model){
                                text = item.iot_device_model[v.key]
                              }
                              if (v.key === 'created_at') {
                                text = moment(text).format('YYYY-MM-DD')
                              }
                              if (v.key === 'updated_at') {
                                text = moment(text).add(9, 'hours').format('YYYY-MM-DD HH:mm:ss')
                              }
                              return (
                                <td>{text}</td>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                  }
                  {dataList && dataList.length === 0 &&
                    <tr >
                      <td scope="row" colSpan={headers.length}>데이터가 없습니다.</td>
                    </tr>
                  }
                  {dataList === undefined &&
                    <tr >
                      <td scope="row" colSpan={headers.length}>데이터가 없습니다.</td>
                    </tr>
                  }


                </tbody>
              </table>
            </div>
            {/* <Row>
              <Col md="12" style={{ textAlign: "center", marginBottom: 20 }}>
                <div className="btn-group mt-4 waver-pagination-btn-group">
                  <Button
                    type="button"
                    size="sm"
                    className={page !== 1 ? 'waves-effect left-btn active' : 'waves-effect left-btn'}
                    onClick={() => {
                      if (page !== 1) {
                        setPage(page - 1)
                        if (Math.min(...currentPageArr) === page) {
                          setPagesGroup(pagesGroup - 5)
                        }
                      }

                    }}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  {currentPageArr.length === 0 ?
                    pageArr && pageArr.map((item, index) => {
                      return (
                        <Button
                          key={index}
                          type="button"
                          size="sm"
                          className={page === item ? 'waves-effect center-btn active' : 'waves-effect center-btn'}
                          onClick={() => {
                            setPage(item)
                          }}
                        >
                          {item}
                        </Button>
                      )
                    })
                    :
                    currentPageArr.map((item, index) => {
                      return (
                        <Button
                          key={index}
                          type="button"
                          size="sm"
                          className={page === item ? 'waves-effect center-btn active' : 'waves-effect center-btn'}
                          onClick={() => {
                            setPage(item)
                          }}
                        >
                          {item}
                        </Button>
                      )
                    })
                  }
                  <Button
                    type="button"
                    size="sm"
                    className={page !== contentCount ? 'waves-effect right-btn active' : 'waves-effect right-btn'}
                    onClick={() => {
                      if (page !== contentCount) {
                        setPage(page + 1)
                        if (Math.max(...currentPageArr) === page) {
                          setPagesGroup(pagesGroup + 5)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-chevron-right" />
                  </Button>
                </div>
              </Col>
            </Row> */}

          </CardBody>
          {isLoading && playLoading()}
        </Card>
      }
    </>
  )

}

export default DefaultForm;
