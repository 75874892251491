import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"

import { checkId, getTerms } from "../../crud/auth.crud"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

import Util from "pages/Dashboard/Common/Util";

// Redux
import { connect } from "react-redux"

// import images
import weaver from "../../assets/images/weaver.png";

// 주소 찾기
import DaumPostcode from 'react-daum-postcode';

const Terms = props => {

  const [prevState, setPrevState] = useState({})
  const history = useHistory()
  const location = useLocation()
  const [serviceAgree, setServiceAgree] = useState(false)
  const [privacyAgree, setPrivacyAgree] = useState(false)
  const [termsOfService, setTermsOfService] = useState(false)
  const [electronicFinancial, setElectronicFinancial] = useState(false)
  const [marketingAgree, setMarketingAgree] = useState(false)
  const [category, setCartegory] = useState(undefined)
  const [service, setService] = useState()

  const termsArr = [
    { key: 'service', title: '[필수] 서비스 이용약관', category: 'service', currentState: serviceAgree, changeState: setServiceAgree },
    { key: 'privacy', title: '[필수] 개인정보 수집 및 이용동의', category: 'privacy', currentState: privacyAgree, changeState: setPrivacyAgree },
    { key: 'terms', title: '[필수] 위치기반 서비스 이용약관', category: 'terms', currentState: termsOfService, changeState: setTermsOfService },
    { key: 'electronic', title: '[필수] 전자금융거래 이용약관', category: 'electronic', currentState: electronicFinancial, changeState: setElectronicFinancial },
    { key: 'marketing', title: '[선택] 마케팅 메시지 수신 동의', category: 'marketing', currentState: marketingAgree, changeState: setMarketingAgree },
  ]

  const allCheck = (bool) => {
    setServiceAgree(bool)
    setPrivacyAgree(bool)
    setTermsOfService(bool)
    setElectronicFinancial(bool)
    setMarketingAgree(bool)
  }

  const isValiddate = () => {
    if (!serviceAgree) {
      return alert("서비스 이용약관을 동의해주세요.")
    }
    if (!privacyAgree) {
      return alert("개인정보 수집 및 이용약관을 동의해주세요.")
    }
    if (!termsOfService) {
      return alert("위치기반 서비스 이용약관을 동의해주세요")
    }
    if (!electronicFinancial) {
      return alert("전자금융거래 이용약관을 동의해주세요")
    }
    return 'success'
  }

  const getService = async (category) => {
    const response = await getTerms();
    if (response.status === 200) {
      const service = response.data.results.filter(item => item.category === category)[0]
      setService(service)
      console.log(service)
    }
  }

  // useEffect(() => {
  //   if (category) {
  //     getService()
  //   }
  // }, [category])

  return (
    <React.Fragment>
      <MetaTags>
        <title>회원가입 |  IoT Monitor</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4} style={{minWidth: 450}}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">약관 동의</h5>
                    <p className="text-white-50"></p>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>
                {!category &&
                  <CardBody className="p-4">
                    <div className="p-3">

                      <div className="mb-3" style={{ marginTop: 24 }}>
                        <div className="form-check">
                          <input id="allCheck" className="is-untouched is-pristine av-valid form-check-input" type='checkbox' checked={serviceAgree && privacyAgree && termsOfService && electronicFinancial && marketingAgree} value={'selectAll'} onClick={(e) => {
                            e.target.checked === false ? allCheck(false) : allCheck(true)
                          }} />
                          <label className="form-check-label" htmlFor={'allCheck'} >약관 전체동의</label>
                        </div>
                        <div style={{ height: 1, width: '100%', backgroundColor: 'black', marginBottom: 6 }}></div>
                        {
                          termsArr && termsArr.map((item, index) => {
                            return (
                              <div className="form-check form-check-inline" style={{ marginTop: 12, display: "flex", justifyContent: "space-between" }} key={item.key + '_' + index}>
                                <div>
                                  <input className="is-untouched is-pristine av-valid form-check-input" type='checkbox' id={item.key} name="sectors" value={item.currentState} checked={item.currentState} onChange={(e) => { item.changeState(e.target.checked) }} />
                                  <label className="form-check-label" htmlFor={item.key} >{item.title}</label>
                                </div>
                                <p onClick={() => {
                                  setCartegory(item.category)
                                  getService(item.category)
                                }} className="noMargin" style={{ cursor: "pointer" }}>[자세히 보기]</p>
                              </div>
                            )
                          })
                        }
                      </div>

                      <div className="mb-3 row" style={{ marginTop: 30 }}>
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={() => {
                              if (isValiddate() === 'success') {
                                history.push('select-type')
                              }
                            }}
                          >
                            다음으로
                          </button>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                }
                {category &&
                  <CardBody className="p-4">
                    <div className="p-3">

                      <div className="mb-3" style={{ marginTop: 24 }}>
                        <p className="noMargin" style={{ fontSize: 18, textAlign: "center", marginBottom: 6 }}>{service?.subject}</p>
                        <div style={{ height: 1, width: '100%', backgroundColor: 'black', marginBottom: 6 }}></div>
                        <div style={{height: 360, overflowY: 'scroll'}}>
                          <p className="noMargin" style={{ fontSize: 14, textAlign: "center", marginBottom: 6 }}>{service?.content}</p>
                        </div>
                      </div>

                      <div className="mb-3 row" style={{ marginTop: 30 }}>
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={() => {
                              setCartegory(undefined)
                              setService(undefined)
                            }}
                          >
                            뒤로가기
                          </button>
                        </div>
                      </div>

                    </div>
                  </CardBody>
                }
              </Card>
              <div className="mt-5 text-center">
                <p>
                  이미 계정이 있습니까 ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    로그인
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

Terms.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Terms)
