import React, { useState, useEffect } from "react"
import { Row, Col, Container, Button } from "reactstrap"
import MetaTags from 'react-meta-tags';
import { useSelector } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import moment from "moment";
import { getCompanyOrder, getPaymentList, postPaymentLog, postPaymentReport, postTax } from "crud/auth.crud";

import Util from "pages/Dashboard/Common/Util";
import { useHistory } from "react-router-dom";

import loading from "../../assets/images/loading.gif";

const CollectionBill = props => {
  const selector = useSelector(state => state)
  const newDate = new Date()
  const nextDate = new Date()
  const preDate = new Date()
  const preMonth = new Date()
  const history = useHistory()
  const { IMP } = window

  const [isLoading, setIsLoading] = useState(true);
  const [prevSumData, setPrevSumData] = useState(0);
  const [sumData, setSumData] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [payMethod, setPayMethod] = useState('card');
  const [data, setData] = useState([]);
  const [priceObj, setPriceObj] = useState({});
  const [price, setPrice] = useState(0);
  const [pricePerL, setPricePerL] = useState(170);
  const [company, setCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group);
  const [companyId, setCompanyId] = useState(selector.auth?.user?.member_group?.[0]?.company_group.id);
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [checkedDateType, setCheckedDateType] = useState('');
  const [yearModalShow, setYearModalShow] = useState(false);
  const [monthModalShow, setMonthModalShow] = useState(false);
  const [prevMonth, setPrevMonth] = useState(moment(preMonth).format('DD') > 10 ? moment(preMonth).format('MM') : moment(preMonth.setMonth(preMonth.getMonth() - 1)).format('MM'));
  const [prevDate, setPrevDate] = useState(moment(preDate).format('DD') > 10 ? moment(preDate.setMonth(preDate.getMonth() + 1)).format('MM') + '/10' : moment(preDate).format('MM') + '/10');
  const [startDate, setStartDate] = useState(moment(newDate).format('YYYY-MM') + '-01 00:00:00');
  const [endDate, setEndDate] = useState(moment(nextDate.setMonth(nextDate.getMonth() + 1)).format('YYYY-MM') + '-01 00:00:00');
  const [companyTo, setCompanyTo] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);
  const [order, setOrder] = useState({});
  const [rsp, setRsp] = useState();
  const [selectedData, setSelectedData] = useState({});

  const getDataList = async () => {
    let url = '/payment_report/get_payment_wearing_report/?company='
    try {
      const params = {
        company: companyId,
        date: startDate,
      }

      const response = await getPaymentList(url, params);
      console.log(response.data.result)
      if (response.data?.result === 'success') {
        console.log("data response >>> ", response?.data?.data)
        setData(response?.data?.data || ['fakeData'])
        setPrevDate(response?.data?.data?.payment_date ? moment(response?.data?.data?.payment_date).format('YYYY-MM-DD') : prevDate)
      }
      setIsLoading(false)
    } catch (error) {
      console.error(error);
    }
  }

  const requestPay = () => {
    IMP.init("imp41866208")
    IMP.request_pay({
      pg: 'nice',
      pay_method: payMethod || 'card',
      currency: undefined,
      notice_url: undefined,
      display: undefined,
      merchant_uid: `mid_${new Date().getTime()}`,
      name: company.name + ' ' + prevDate,
      //amount : data.price || 0,
      amount: 1000,
      app_scheme: 'Iot_monitor',
      tax_free: undefined,
      buyer_name: company.name || '회사명',
      buyer_tel: company.mobile_phone || '회사모바일번호',
      buyer_email: company.email || '회사이메일',
      buyer_addr: undefined,
      buyer_postcode: undefined,
      custom_data: undefined,
      vbank_due: undefined,
      digital: false,
      language: undefined,
      biz_num: undefined,
      customer_uid: undefined,
      naverPopupMode: undefined,
      naverUseCfm: undefined,
      naverProducts: undefined,
      m_redirect_url: undefined,
      niceMobileV2: true,
      escrow: false,
    }, function (response) { // callback
      setRsp(response)
      console.log("response >>>", response)
      if (response.success) {
        savePaymentLog('Y', response)
        savePayment(response)
        setModalSuccess(true)
      } else {
        savePaymentLog('N', response)
        alert("결제에 실패하였습니다. \n에러내용: " + response.error_msg)
      }
    });
  }

  const savePaymentLog = async (state, response) => {
    console.log("response" >>> response)
    const params = {
      company_from: order?.companyFrom,
      company_to: order?.companyTo,
      payment_report: order?.paymentReportId,
      payment_type: 'card',
      imp_uid: response?.imp_uid,
      merchant_uid: response.merchant_uid,
      price: order?.totalPrice,
      status: state
    }
    console.log(params)
    const rs = await postPaymentLog(params);
    console.log(rs.data)
  }

  const savePayment = async (response) => {
    setIsLoading(true)
    const params = {
      company_from: order?.companyFrom,
      company_to: order?.companyTo,
      payment_report: order?.paymentReportId,
      payment_type: 'card',
      imp_uid: response?.imp_uid,
      merchant_uid: response.merchant_uid,
      price: order?.totalPrice,
      status: 'Y'
    }
    console.log(params)
    const rs = await postPaymentReport(params);
    console.log(rs.data)
    saveTaxBill()
    getDataList()
    setIsLoading(false)
  }

  const saveTaxBill = async () => {

    const toDate = moment(new Date()).format('YYYYMMDD');
    const mgtKey = toDate + '-' + order?.paymentReportId;

    const fromRs = await getCompanyOrder(order?.companyFrom);
    const fromData = fromRs.data || {}

    const toRs = await getCompanyOrder(order?.companyTo);
    const toData = toRs.data || {}

    const params = {
      payment_report_id: order?.paymentReportId,
      company_from_number: fromData.company_number,
      company_from_name: fromData.name,
      company_from_user_name: fromData.username,
      company_from_address: fromData.address1 + fromData.address2,
      company_from_email: fromData.email,
      company_from_phone: fromData.phone,
      company_from_mobile: fromData.mobile_phone,
      company_from_type: fromData.type,
      company_from_sector: fromData.type,
      company_to_number: toData.company_number,
      company_to_name: toData.name,
      company_to_user_name: toData.username,
      company_to_address: toData.address1 + toData.address2,
      company_to_email: toData.email,
      company_to_phone: toData.phone,
      company_to_mobile: toData.mobile_phone,
      company_to_fax: toData.fax,
      company_to_type: toData.type,
      company_to_sector: toData.type,
      price: parseInt(order?.totalPrice * (10 / 11)),
      vat: order?.totalPrice - parseInt(order?.totalPrice * (10 / 11)),
      total_price: order?.totalPrice,
      mgt_key: mgtKey,
      mgt_date: toDate,
    }
    const response = await postTax(params)
    alert(response.data?.msg || '')
  }

  useEffect(() => {
    getDataList()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>청구서 |  IoT Monitor</title>
          </MetaTags>
          <Breadcrumbs
            maintitle="홈"
            title="청구서"
            breadcrumbItem="청구서"
          />
          {data && data.map((item, index) => {
            const prevPrice = item.prev_price || 0;
            const payedPrice = item.payed_price || 0;
            const priceSum = item.total_price || 0;
            const totalPrice = item.total_payment_price || 0;
            const totalQty = item.total_qty_kg;
            return (
              <Row key={index}>
                <Col md="12">
                  <table
                    className="body-wrap"
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "transparent",
                      margin: "0",
                    }}
                    bgcolor="#f6f6f6"
                  >
                    <tbody>
                      <tr
                        style={{
                          fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          margin: "0",
                        }}
                      >
                        <td
                          style={{
                            fontFamily:
                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                            boxSizing: "border-box",
                            fontSize: "14px",
                            verticalAlign: "top",
                            margin: "0",
                          }}
                          valign="top"
                        ></td>
                        <td
                          className="container"
                          width="600"
                          style={{
                            fontFamily:
                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                            boxSizing: "border-box",
                            fontSize: "14px",
                            verticalAlign: "top",
                            display: "block",
                            maxWidth: "600px",
                            clear: "both",
                            margin: "0 auto",
                          }}
                          valign="top"
                        >
                          <div
                            className="content"
                            style={{
                              fontFamily:
                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                              boxSizing: "border-box",
                              fontSize: "14px",
                              maxWidth: "600px",
                              display: "block",
                              margin: "0 auto",
                              padding: "20px",
                            }}
                          >
                            <table
                              className="main"
                              width="100%"
                              cellPadding="0"
                              cellSpacing="0"
                              style={{
                                fontFamily:
                                  "Helvetica Neue,Helvetica,Arial,sans-serif",
                                boxSizing: "border-box",
                                fontSize: "14px",
                                borderRadius: "7px",
                                margin: "0",
                                border: "none",
                              }}
                            >
                              <tbody>
                                <tr
                                  style={{
                                    fontFamily:
                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                    margin: "0",
                                  }}
                                >
                                  <td
                                    className="content-wrap aligncenter"
                                    style={{
                                      fontFamily:
                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                      boxSizing: "border-box",
                                      fontSize: "14px",
                                      verticalAlign: "top",
                                      margin: "0",
                                      padding: "20px",
                                      border: "3px solid #1d1e3a",
                                      borderRadius: "7px",
                                      backgroundColor: "#fff",
                                    }}
                                    align="center"
                                    valign="top"
                                  >
                                    <table
                                      width="100%"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      style={{
                                        fontFamily:
                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                      }}
                                    >
                                      <tbody>
                                        <tr
                                          style={{
                                            fontFamily:
                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            margin: "0",
                                          }}
                                        >
                                          <td
                                            className="content-block"
                                            style={{
                                              fontFamily:
                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                              boxSizing: "border-box",
                                              fontSize: "14px",
                                              verticalAlign: "top",
                                              margin: "0",
                                              padding: "0 0 20px",
                                            }}
                                            valign="top"
                                          >
                                            <h2
                                              className="aligncenter"
                                              style={{
                                                fontFamily:
                                                  "Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif",
                                                boxSizing: "border-box",
                                                fontSize: "24px",
                                                color: "#000",
                                                lineHeight: "1.4em",
                                                fontWeight: "400",
                                                textAlign: "center",
                                                margin: "40px 0 0",
                                              }}
                                              align="center"
                                            >
                                              <b style={{ fontWeight: "600" }}>{prevDate}</b> 결제 예정 금액은{" "}
                                              <b style={{ fontWeight: "600", color: '#FF4949' }}>
                                                {" "}
                                                <br />{Util.setCommaPrice(totalPrice)}
                                              </b>
                                              원 입니다.
                                            </h2>
                                          </td>
                                        </tr>
                                        <tr
                                          style={{
                                            fontFamily:
                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            margin: "0",
                                          }}
                                        >
                                          <td
                                            className="content-block aligncenter"
                                            style={{
                                              fontFamily:
                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                              boxSizing: "border-box",
                                              fontSize: "14px",
                                              verticalAlign: "top",
                                              textAlign: "center",
                                              margin: "0",
                                              padding: "0 0 20px",
                                            }}
                                            align="center"
                                            valign="top"
                                          >
                                            {data[0] !== 'fakeData' &&
                                            <table
                                              className="invoice"
                                              style={{
                                                fontFamily:
                                                  "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                boxSizing: "border-box",
                                                fontSize: "14px",
                                                textAlign: "left",
                                                width: "80%",
                                                margin: "40px auto",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <b style={{ fontWeight: "bold" }}>{item?.company_from?.name}</b>
                                                    <br
                                                      style={{
                                                        fontFamily:
                                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                        boxSizing: "border-box",
                                                        fontSize: "14px",
                                                        margin: "0",
                                                      }}
                                                    />

                                                    <br
                                                      style={{
                                                        fontFamily:
                                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                        boxSizing: "border-box",
                                                        fontSize: "14px",
                                                        margin: "0",
                                                      }}
                                                    />
                                                    정산요약
                                                  </td>
                                                </tr>
                                                <tr
                                                  style={{
                                                    fontFamily:
                                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                    boxSizing: "border-box",
                                                    fontSize: "14px",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontFamily:
                                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                      boxSizing: "border-box",
                                                      fontSize: "14px",
                                                      verticalAlign: "top",
                                                      margin: "0",
                                                      padding: "5px 0",
                                                    }}
                                                    valign="top"
                                                  >
                                                    <table
                                                      className="invoice-items"
                                                      cellPadding="0"
                                                      cellSpacing="0"
                                                      style={{
                                                        fontFamily:
                                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                        boxSizing: "border-box",
                                                        fontSize: "14px",
                                                        width: "100%",
                                                        margin: "0",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr
                                                          style={{
                                                            fontFamily:
                                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            boxSizing:
                                                              "border-box",
                                                            fontSize: "14px",
                                                            margin: "0",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              borderTopWidth:
                                                                "1px",
                                                              borderTopColor:
                                                                "#eee",
                                                              borderTopStyle:
                                                                "solid",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                            }}
                                                            valign="top"
                                                          >
                                                            총 입고량
                                                          </td>
                                                          <td
                                                            className="alignright"
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              textAlign: "right",
                                                              borderTopWidth:
                                                                "1px",
                                                              borderTopColor:
                                                                "#eee",
                                                              borderTopStyle:
                                                                "solid",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                            }}
                                                            align="right"
                                                            valign="top"
                                                          >
                                                            {Util.setCommaPrice(totalQty)}Kg
                                                          </td>
                                                        </tr>
                                                        <tr
                                                          style={{
                                                            fontFamily:
                                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            boxSizing:
                                                              "border-box",
                                                            fontSize: "14px",
                                                            margin: "0",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              borderTopWidth:
                                                                "1px",
                                                              borderTopColor:
                                                                "#eee",
                                                              borderTopStyle:
                                                                "solid",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                            }}
                                                            valign="top"
                                                          >
                                                            당월미정산액
                                                          </td>
                                                          <td
                                                            className="alignright"
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              textAlign: "right",
                                                              borderTopWidth:
                                                                "1px",
                                                              borderTopColor:
                                                                "#eee",
                                                              borderTopStyle:
                                                                "solid",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                            }}
                                                            align="right"
                                                            valign="top"
                                                          >
                                                            {Util.setCommaPrice(priceSum)}원
                                                          </td>
                                                        </tr>
                                                        <tr
                                                          style={{
                                                            fontFamily:
                                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            boxSizing:
                                                              "border-box",
                                                            fontSize: "14px",
                                                            margin: "0",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              borderTopWidth:
                                                                "1px",
                                                              borderTopColor:
                                                                "#eee",
                                                              borderTopStyle:
                                                                "solid",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                            }}
                                                            valign="top"
                                                          >
                                                            이전미정산액
                                                          </td>
                                                          <td
                                                            className="alignright"
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              textAlign: "right",
                                                              borderTopWidth:
                                                                "1px",
                                                              borderTopColor:
                                                                "#eee",
                                                              borderTopStyle:
                                                                "solid",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                            }}
                                                            align="right"
                                                            valign="top"
                                                          >
                                                            {Util.setCommaPrice(prevPrice)}원
                                                          </td>
                                                        </tr>
                                                        <tr
                                                          style={{
                                                            fontFamily:
                                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            boxSizing:
                                                              "border-box",
                                                            fontSize: "14px",
                                                            margin: "0",
                                                          }}
                                                        >
                                                        </tr>
                                                        <tr
                                                          className="total"
                                                          style={{
                                                            fontFamily:
                                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            boxSizing:
                                                              "border-box",
                                                            fontSize: "14px",
                                                            margin: "0",
                                                          }}
                                                        >
                                                          <td
                                                            className="alignright"
                                                            width="80%"
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              textAlign: "right",
                                                              borderTopWidth:
                                                                "2px",
                                                              borderTopColor:
                                                                "#333",
                                                              borderTopStyle:
                                                                "solid",
                                                              borderBottomColor:
                                                                "#333",
                                                              borderBottomWidth:
                                                                "2px",
                                                              borderBottomStyle:
                                                                "solid",
                                                              fontWeight: "700",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                            }}
                                                            align="right"
                                                            valign="top"
                                                          >
                                                            합계
                                                          </td>
                                                          <td
                                                            className="alignright"
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              textAlign: "right",
                                                              borderTopWidth:
                                                                "2px",
                                                              borderTopColor:
                                                                "#333",
                                                              borderTopStyle:
                                                                "solid",
                                                              borderBottomColor:
                                                                "#333",
                                                              borderBottomWidth:
                                                                "2px",
                                                              borderBottomStyle:
                                                                "solid",
                                                              fontWeight: "700",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                            }}
                                                            align="right"
                                                            valign="top"
                                                          >
                                                            {Util.setCommaPrice(totalPrice)}원
                                                          </td>
                                                        </tr>
                                                        <tr
                                                          className="total"
                                                          style={{
                                                            fontFamily:
                                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                            boxSizing:
                                                              "border-box",
                                                            fontSize: "14px",
                                                            margin: "0",
                                                          }}
                                                        >
                                                          <td
                                                            className="alignright"
                                                            width="80%"
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              textAlign: "right",
                                                              borderTopWidth:
                                                                "2px",
                                                              borderTopColor:
                                                                "#333",
                                                              borderTopStyle:
                                                                "solid",
                                                              borderBottomColor:
                                                                "#333",
                                                              borderBottomWidth:
                                                                "2px",
                                                              borderBottomStyle:
                                                                "solid",
                                                              fontWeight: "700",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                            }}
                                                            align="right"
                                                            valign="top"
                                                          >
                                                            결제한 금액
                                                          </td>
                                                          <td
                                                            className="alignright"
                                                            style={{
                                                              fontFamily:
                                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                                              boxSizing:
                                                                "border-box",
                                                              fontSize: "14px",
                                                              verticalAlign:
                                                                "top",
                                                              textAlign: "right",
                                                              borderTopWidth:
                                                                "2px",
                                                              borderTopColor:
                                                                "#333",
                                                              borderTopStyle:
                                                                "solid",
                                                              borderBottomColor:
                                                                "#333",
                                                              borderBottomWidth:
                                                                "2px",
                                                              borderBottomStyle:
                                                                "solid",
                                                              fontWeight: "700",
                                                              margin: "0",
                                                              padding: "5px 0",
                                                              color: 'blue'
                                                            }}
                                                            align="right"
                                                            valign="top"
                                                          >
                                                            {Util.setCommaPrice(payedPrice)}원
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            }
                                            {data?.state !== 'Y' ?
                                              <button className="btn btn-primary w-md waves-effect waves-light" type="submit" onClick={() => {
                                                setModalShow(true)
                                              }}>정산하기(즉시결제)
                                              </button>
                                              :
                                              <div style={{ cursor: 'auto', fontSize: 16, fontWeight: "bold", color: '#C9CACA' }}>
                                                { totalPrice > 0 ? '결제가 완료되었습니다.' : '결제하실 금액이 없습니다.'}
                                              </div>
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ textAlign: "center", fontSize: 16, fontWeight: "bold", color: 'black' }}>
                                            <p style={{ cursor: "pointer" }} onClick={() => {
                                              history.push('settlement-details')
                                            }}>정산 내역 / 납부 내역</p>
                                          </td>
                                        </tr>
                                        <tr
                                          style={{
                                            fontFamily:
                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            margin: "0",
                                          }}
                                        >
                                          <td
                                            className="content-block aligncenter"
                                            style={{
                                              fontFamily:
                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                              boxSizing: "border-box",
                                              fontSize: "14px",
                                              verticalAlign: "top",
                                              textAlign: "center",
                                              margin: " 0",
                                              padding: " 0 0 20px",
                                            }}
                                            align="center"
                                            valign="top"
                                          >

                                          </td>
                                        </tr>

                                        <tr
                                          style={{
                                            fontFamily:
                                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            margin: "0",
                                          }}
                                        >
                                          <td
                                            className="content-block"
                                            style={{
                                              textAlign: "center",
                                              fontFamily:
                                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                                              boxSizing: "border-box",
                                              fontSize: "14px",
                                              verticalAlign: "top",
                                              margin: "0",
                                              padding: "0",
                                            }}
                                            valign="top"
                                          >
                                            © {new Date().getFullYear()} IoT Monitor
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            )
          })
          }
        </Container>
      </div>
      {modalSuccess &&
        <div style={{
          position: "fixed",
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.4)',
          zIndex: 999,
          display: "flex",
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div style={{ width: 480, height: 300, backgroundColor: "#fff", zIndex: 1000, padding: 36 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 20, color: 'black', fontWeight: 'bold' }}>결제완료</p>
              <p style={{ cursor: "pointer", fontSize: 20 }} onClick={() => {
                setModalSuccess(false)
              }}>X</p>
            </div>
            <div style={{ padding: 12 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 16 }}>주문번호</p>
                <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{rsp.merchant_uid?.split('_')?.[1]}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: 24 }}>
                <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{order.prevMonth + '월분 ' + Util.setCommaPrice(order.totalPrice) + '원 결제가 완료되었습니다.'}</p>
              </div>
            </div>
          </div>
        </div>
      }
      {modalShow &&
        <div style={{
          position: "fixed",
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.4)',
          zIndex: 999,
          display: "flex",
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div style={{ width: 480, height: 300, backgroundColor: "#fff", zIndex: 1000, padding: 36 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="noMargin" style={{ fontSize: 20, color: 'black', fontWeight: 'bold' }}>결제수단을 선택해 주세요.</p>
              <p className="noMargin" style={{ cursor: "pointer", fontSize: 20 }} onClick={() => {
                setModalShow(false)
              }}>X</p>
            </div>
            <div className="btn-group" style={{ display: 'flex', width: '72%', margin: "36px auto 60px", height: 35 }}>
              <Button              
                style={payMethod !== 'card' ? { width: '50%', fontSize: 14,} : { width: '50%', fontSize: 14, backgroundColor: "#48C3B1", color: "#ffffff"  }}
                type="button"                
                size="sm"
                className="waves-effect"
                onClick={() => {
                  setPayMethod('card')
                }}
              >
                신용카드
              </Button>
              <Button
                style={payMethod !== 'trans' ? { width: '50%', fontSize: 14,} : { width: '50%', fontSize: 14, backgroundColor: "#48C3B1", color: "#ffffff"  }}
                type="button"                
                size="sm"
                className="waves-effect"
                onClick={() => {
                  setPayMethod('trans')
                }}
              >
                계좌이체
              </Button>
            </div>
            <button style={{ margin: "0 auto", display: "block" }} className="btn btn-primary w-md waves-effect waves-light" type="submit" onClick={() => {
              setModalShow(false)
              setOrder({
                price: selectedData?.price,
                vat: selectedData?.vat,
                totalSum: selectedData.total_qty_kg,
                totalPrice: selectedData.total_payment_price || 0,
                prevMonth: prevMonth,
                companyFrom: selectedData.company_from,
                companyTo: selectedData.company_to,
                paymentReportId: selectedData.id
              })
              requestPay()
            }}>결제하기
            </button>
          </div>
        </div>
      }
      {isLoading && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

export default CollectionBill;
