import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import { useLocation, useHistory } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import loading from "../../assets/images/loading.gif";

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

import Board from './Component/Board';


//i18n
import { withTranslation } from "react-i18next"
import { postAddUser } from 'crud/auth.crud';

const UserMain = props => {
  const location = useLocation()
  const history = useHistory()

  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState(0)
  const [dataList, setDataList] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [inputData, setInputData] = useState({});
  const [page, setPage] = useState(1);

  const [inputId, setInputId] = useState('');
  const [inputPw, setInputPw] = useState('');
  const [inputName, setInputName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [isFactory, setIsFactory] = useState(false);



  const nextGetData = () => {
    setOnUpload(true)
    let newArr = []
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    setPageArr(newArr)
    setOnUpload(false)
  }


  const movePage = (id, company) => {
    console.log(id, company)
    // history.push({ pathname: '/detail', state: { id: id } })
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  // useEffect( async () => {
  //   await getDataList()
  // }, [page])

  useEffect(()=>{
    console.log(location)
  }, [])

  const addUser = async() => {
    var params = {
      "is_superuser": false,
      "is_staff": false,
      "is_active": true,
      "user_id": inputId,
      "password": inputPw,
      "username": inputName,
      "email": inputEmail,
      "mobile_phone": inputPhone,
      "data": isFactory ? 'factory' : ''
    }

    console.log(params)

    const response = await postAddUser(params)
      console.log(">>>>origin   :::: "+response.data)
      console.log(">>>>JSON Str :::: ", JSON.stringify(response))

      history.goBack()
    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                      <div className="table-responsive" style={{ height: '80vh', minHeight: '100px' }}>
                        <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                          <thead className="chart-thead">
                            <tr>
                              <th scope="col" className='bg-dark-ash'>유저 등록</th>
                            </tr>
                          </thead>
                        </table>

                        <div className="w-100" style={{ padding: 24 }}>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">사용자 ID</p>
                              <input value={inputId} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputId(e.target.value) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">비밀번호</p>
                              <input type='password' value={inputPw} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputPw(e.target.value) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">담당자 이름</p>
                              <input value={inputName} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputName(e.target.value) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">Email</p>
                              <input value={inputEmail} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputEmail(e.target.value) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">연락처</p>
                              <input value={inputPhone} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputPhone(e.target.value) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16 mb-2">회원 타입 (factory 여부)</p>
                              <Col style={{flexGrow: 1}}>
                                <div className="btn-group mt-1 waver-pagination-btn-group">
                                  <Button size='sm' className='waves-effect left-btn btn btn-secondary btn-sm' style={{ backgroundColor: isFactory === true && '#1C2F1A', color: isFactory === true && 'white' }} onClick={() => {
                                    setIsFactory(true)
                                  }}>
                                    ㅤYㅤ
                                  </Button>
                                  <Button size='sm' className='waves-effect right-btn btn btn-secondary btn-sm' style={{ backgroundColor: isFactory === false && '#1C2F1A', color: isFactory === false && 'white' }} onClick={() => {
                                    setIsFactory(false)
                                  }}>
                                    ㅤNㅤ
                                  </Button>
                                </div>
                              </Col>
                            </>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <button
            className="btn btn-primary w-md waves-effect waves-light bg-dark-ash"
            type="submit"
            onClick={() => { addUser() }}
          >
            등록
          </button>
          
          {onUpload && 
            <div style={{
                position: "absolute",
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.6)',
                zIndex: 999,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img src={loading} alt="로딩중입니다." width={'5%'}></img>
            </div>
          }

        </Container>
      </div>
    </React.Fragment>
  )
}

UserMain.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(UserMain)
