import { func } from "prop-types";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { checkDriverId, deleteDriverAccount, getBusinessList, getDriverList, patchBusinessList, saveDriverAccount, saveDriverExcel, setNewBusinessList, updateDriverList } from "crud/auth.crud";

import _ from "lodash";
import moment from "moment";
import Util from "pages/Dashboard/Common/Util";

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

const DriverManagementScreen = () => {
  const history = useHistory()
  const selector = useSelector(state => state)
  const newDate = new Date()
  const nextDate = new Date()

  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [memberId, setMemberId] = useState('');
  const [memberIdConfirm, setMemberIdConfirm] = useState(false);
  const [memberPassword, setMemberPassword] = useState('');
  const [memberPasswordConfirm, setMemberPasswordConfirm] = useState('');
  const [memberName, setMemberName] = useState('');
  const [memberEmail, setMemberEmail] = useState('');
  const [memberCellphone, setMemberCellphone] = useState('');
  const [userType, setUserType] = useState('driver');
  const [companyId, setCompanyId] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.id);
  const [myId, setMyId] = useState(selector.auth?.user?.id);
  const [driverListFile, setDriverListFile] = useState(undefined);
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState()

  const nextGetData = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      console.log("페이지 일치 여부", page, i + 1)
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {
    setOnUpload(true)
    if (isFetching || isListEnd) {
      return;
    }

    let params = {
      page: page,
      company: companyId
    }

    setIsFetching(true)

    const response = await getDriverList(params);
    const content = response.data?.results || [];
    let responseCount = response.data?.count
    for(let i = 0; i < content.length; i++){
      if(content[i].member.user_id.indexOf('deleted') !== -1){
        content.splice(i, 1)
        i -= 1
      }
    }
    responseCount = content.length
    if (!contentCount || contentCount !== Math.floor(responseCount / 15) + 1) {
      setContentCount(Math.floor(response.data.count / 15) + 1)
      let newArr = []
      for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
        if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
          newArr.push(i + 1)
        }
      }
      setPageArr(newArr)
    }
    if (content.length > 0) {
      setDataList([...content])
      setIsFetching(false)
    } else {
      setIsFetching(false)
      setIsListEnd(true)
    }
    setOnUpload(false)
  }

  const checkUserId = async () => {
    setOnUpload(true)
    const params = {
      user_id: memberId,
    }
    const rs = await checkDriverId(params)
    if (rs.data?.result === 'success') {
      setMemberIdConfirm(true)
    }
    alert(rs.data?.msg)
    setOnUpload(false)
  }

  const isValiddate = () => {
    if (memberId === '') {
      return alert("아이디를 입력해주세요.")
    }
    if (!memberIdConfirm) {
      return alert("아이디 중복확인을 해주세요.")
    }
    if (memberPassword === '') {
      return alert("비밀번호를 입력해주세요.")
    }
    if (memberPasswordConfirm === '' || memberPassword !== memberPasswordConfirm) {
      return alert("비밀번호확인을 정확히 입력해주세요.")
    }
    if (memberName === '') {
      return alert("이름을 입력해주세요.")
    }
    if (memberEmail === '') {
      return alert("이메일을 입력해주세요.")
    }
    if (memberCellphone === '') {
      return alert("휴대폰 번호를 입력해주세요.")
    }
    return "success"
  }

  const saveDriver = async () => {
    setOnUpload(true)
    const validdate = isValiddate();
    if (validdate !== 'success') {
      return alert(validdate)
    }
    const params = {
      'user_id': memberId || '',
      'password': memberPassword || '',
      'username': memberName || '',
      'email': memberEmail || '',
      'mobile_phone': Util.removeHyphens(memberCellphone) || '',
      'type': 'driver',
      'push_token': '',
      'status': 'Y',
      'company_id': companyId
    }
    const rs = await saveDriverAccount(params)
    if (rs.data.result === 'success') {
      setIsRefresh(true)
    }
    setModalShow(false)
    setMemberId('')
    setMemberIdConfirm(false)
    setMemberPassword('')
    setMemberPasswordConfirm('')
    setMemberName('')
    setMemberEmail('')
    setMemberCellphone('')
    alert(rs.data?.msg)
    setOnUpload(false)
  }

  const uploadDriverExcel = async () => {
    setOnUpload(true)
    if (driverListFile == undefined || driverListFile?.length == 0) { setOnUpload(false); return alert('파일을 등록하세요.') }
    try {
      const params = {
        type: 'xlsx',
        category: 'driverListFile',
        company_id: companyId,
        member_id: myId
      }
      const fd = new FormData();
      fd.append('data', JSON.stringify(params));
      console.log(encodeURI(driverListFile[0].name))
      const newFile = new File([driverListFile[0]], `${encodeURI(driverListFile[0].name)}`, { type: driverListFile[0].type })
      console.log('driverListFile >>>', newFile)
      if (driverListFile && !_.isEmpty(driverListFile)) {
        fd.append('file_0', newFile);
      }

      for (let key of fd.keys()) {
        console.log(key, ">>>", fd.get(key));
      }
      const response = await saveDriverExcel(fd)
      console.log(response)
      if (response.data?.result === 'success') {
        setIsRefresh(true)
        alert('정상적으로 등록되었습니다.')
      }

    } catch (e) {
      console.log(e)
      if (e.response) console.log(e.response);
    }
    setOnUpload(false)
    setDriverListFile(undefined)
    setModalShow2(false)
    setOnUpload(false)
  }

  const deleteDriver = async (item) => {
    console.log(item)
    if (confirm("해당 운반기사를 삭제하시겠습니까?")) {
      setOnUpload(true)
      {
        const params = {
          user_id: item.member?.user_id + '_deleted_' + new Date().getTime(),
          email: item.member?.email + '_deleted',
          mobile_phone: item.member?.mobile_phone + '_deleted',
          status: 'N'
        }
        console.log(params)
        const response = await deleteDriverAccount(params, item.member?.id);
        console.log(response)
        if (response?.status === 200) {
          setIsRefresh(true)
          alert('정상적으로 삭제되었습니다.')
        }
      }
      setOnUpload(false)
    }
    getDataList()
  }

  const updateDriver = async () => {
    const params = {
      user_id: memberId,
      username: memberName,
      email: memberEmail,
      mobile_phone: memberCellphone,
    }
    const response = await updateDriverList(params, selectedMemberId);
    if (response?.status === 200) {
      setIsRefresh(true)
      alert('정상적으로 수정되었습니다.')
    }
    setModalShow3(false)
    setMemberId('')
    setMemberName('')
    setMemberEmail('')
    setSelectedMemberId('')
  }

  const openUpdateModal = (item) => {
    setModalShow3(true)
    setSelectedMemberId(item.id)
    setMemberId(item.user_id)
    setMemberName(item.username)
    setMemberEmail(item.email)
    setMemberCellphone(item.mobile_phone)
  }

  const registFiles = (e) => {
    setDriverListFile([...e.target.files])
    console.log(e.target.files[0])
  }

  const deleteFile = (item) => {
    setDriverListFile(driverListFile.filter(data => data !== item))
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect(() => {
    getDataList()
  }, [isRefresh, modalShow, modalShow2, modalShow3, page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>운반기사 관리 |  IoT Monitor</title>
        </MetaTags>
        <div className="account-pages">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="운반기사 관리" />

            <Row className="justify-content-center">
              <Col md={8} lg={6} style={{ width: '20%', minWidth: 420 }}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">운반기사 관리</h5>
                      <p className="text-white-50"></p>
                      <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                    </div>
                  </div>
                  {!modalShow && !modalShow2 && !modalShow3 &&
                    <CardBody className="p-4">
                      <div className="p-3">

                        <div>
                          <div className="mb-3" style={{ position: "relative" }}>
                            <p className="noMargin" style={{ fontSize: 16, color: 'black' }}>운반기사 엑셀등록<br />(변경사항 샘플 엑셀 참조)</p>
                          </div>
                          <div className="mb-3">
                            <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{ height: 36, marginLeft: 6 }}>파일 선택</label>
                            <input style={{ display: "none" }} type="file" accept=".xls,.xlsx" id="ex_file" onChange={(e) => {
                              registFiles(e)
                            }} />
                            {driverListFile?.length > 0 && driverListFile.map((item, index) => {
                              return (
                                <div key={index} style={{ border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6 }}>
                                  <p className="noMargin" style={{ paddingLeft: 6 }}>{item.name}</p>
                                  <p className="noMargin" style={{ paddingRight: 6, color: "#000", cursor: "pointer" }} onClick={() => {
                                    deleteFile(item)
                                  }}>X</p>
                                </div>
                              )
                            })
                            }
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              onClick={async () => {
                                uploadDriverExcel()
                              }}
                            >
                              등록하기
                            </button>
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-warning waves-effect waves-light"
                              type="submit"
                              style={{ width: '100%', marginTop: 24 }}
                              onClick={async () => {
                                setModalShow(true)
                              }}
                            >
                              운반기사 추가
                            </button>
                          </div>
                        </div>

                        {dataList && dataList.map((item, index) => {
                          return (
                            <div key={index} style={{ marginHorizontal: 20 }}>
                              <div style={{ padding: 12, borderBottomWidth: 1, borderBottomColor: '#48C3B1', borderBottomStyle: "solid", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                <div style={{ cursor: "pointer", width: '60%' }} onClick={() => {
                                  openUpdateModal(item.member)
                                }}>
                                  <p style={{ fontSize: 18, color: 'black', fontWeight: 'bold', margin: 0 }}>ID : {item.member?.user_id}</p>
                                  <p style={{ fontSize: 16, color: 'black', margin: 0 }}>{item.member?.username}</p>
                                  <p style={{ fontSize: 16, color: 'black', margin: 0 }}>{Util.handleMobileNumberChange(item.member?.mobile_phone)}</p>
                                </div>
                                <div style={{ zIndex: 10, textAlign: "right", width: '40%' }}>
                                  <p style={{ color: '#ff4949', fontSize: 16, margin: 0, cursor: 'pointer' }} onClick={() => {
                                    deleteDriver(item)
                                  }}>{'X'}</p>
                                  <p style={{ color: 'black', fontSize: 16, margin: 0 }}>{item.member?.email}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })}

                        <div className="mt-2 mb-0" style={{ textAlign: "center" }}>
                          <div className="mt-4 btn-group">
                            <Button
                              style={{ opacity: page !== 1 ? 1 : 0, cursor: page !== 1 ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                              type="button"
                              color="primary"
                              size="sm"
                              className="waves-effect"
                              onClick={() => {
                                if (page !== 1) {
                                  setPage(page - 1)
                                  if (Math.min(...pageArr) === page) {
                                    setPagesGroup(pagesGroup - 5)
                                  }
                                }
                              }}
                            >
                              <i className="fa fa-chevron-left" />
                            </Button>
                            {pageArr && pageArr.map((item, index) => {
                              return (
                                <Button
                                  key={index}
                                  style={{ cursor: 'pointer', padding: "0.5rem 1rem", border: 'none', color: page === item ? 'white' : 'black', backgroundColor: page === item ? '#48C3B1' : 'white' }}
                                  type="button"
                                  size="sm"
                                  className="waves-effect"
                                  onClick={() => {
                                    setPage(item)
                                  }}
                                >
                                  {item}
                                </Button>
                              )
                            })}
                            <Button
                              style={{ opacity: page !== contentCount ? 1 : 0, cursor: page !== contentCount ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                              type="button"
                              color="primary"
                              size="sm"
                              className="waves-effect"
                              onClick={() => {
                                if (page !== contentCount) {
                                  setPage(page + 1)
                                  if (Math.max(...pageArr) === page) {
                                    setPagesGroup(pagesGroup + 5)
                                  }
                                }
                              }}
                            >
                              <i className="fa fa-chevron-right" />
                            </Button>
                          </div>
                        </div>

                      </div>
                    </CardBody>
                  }

                  {modalShow &&
                    <CardBody className="p-4">
                      <div className="p-3">

                        <div style={{ padding: '0.4rem 1rem', borderRadius: '0.2rem', margin: "18px 0", fontSize: 16 }} className="btn btn-primary dropdown-toggle waves-effect waves-light bg-success" onClick={() => { setModalShow(false) }}>뒤로가기
                        </div>

                        <div style={{ display: 'flex' }}>
                          <div className="mb-3" style={{ position: "relative" }}>
                            <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>아이디</p>
                            <input className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setMemberId(e.target.value), setMemberIdConfirm(false) }} />
                          </div>
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{ height: 35, marginTop: 30, marginLeft: 6 }} value="중복확인" onClick={() => { checkUserId() }} />
                        </div>

                        <div className="mb-3" style={{ position: "relative" }}>
                          <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>비밀번호</p>
                          <input type="password" className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setMemberPassword(e.target.value) }} />
                        </div>

                        <div className="mb-3" style={{ position: "relative" }}>
                          <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>비밀번호 확인</p>
                          <input type="password" className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setMemberPasswordConfirm(e.target.value) }} />
                        </div>

                        <div className="mb-3" style={{ position: "relative" }}>
                          <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>이름</p>
                          <input className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setMemberName(e.target.value) }} />
                        </div>

                        <div className="mb-3" style={{ position: "relative" }}>
                          <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>이메일</p>
                          <input type="email" className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setMemberEmail(e.target.value) }} />
                        </div>

                        <div className="mb-3" style={{ position: "relative" }}>
                          <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>휴대전화번호</p>
                          <input type="email" className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setMemberCellphone(Util.handleMobileNumberChange(e.target.value)) }} />
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              onClick={() => { saveDriver() }}
                            >
                              등록하기
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  }

                  {modalShow3 &&
                    <CardBody className="p-4">
                      <div className="p-3">

                        <div style={{ padding: '0.4rem 1rem', borderRadius: '0.2rem', margin: "18px 0", fontSize: 16 }} className="btn btn-primary dropdown-toggle waves-effect waves-light bg-success" onClick={() => { setModalShow3(false) }}>뒤로가기
                        </div>

                        <div style={{ display: 'flex' }}>
                          <div className="mb-3" style={{ position: "relative" }}>
                            <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>아이디</p>
                            <input className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} defaultValue={memberId} onChange={(e) => { setMemberId(e.target.value), setMemberIdConfirm(false) }} />
                          </div>
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{ height: 35, marginTop: 30, marginLeft: 6 }} value="중복확인" onClick={() => { checkUserId() }} />
                        </div>

                        <div className="mb-3" style={{ position: "relative" }}>
                          <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>이름</p>
                          <input className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} defaultValue={memberName} onChange={(e) => { setMemberName(e.target.value) }} />
                        </div>

                        <div className="mb-3" style={{ position: "relative" }}>
                          <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>이메일</p>
                          <input type="email" className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} defaultValue={memberEmail} onChange={(e) => { setMemberEmail(e.target.value) }} />
                        </div>

                        <div className="mb-3" style={{ position: "relative" }}>
                          <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>휴대전화번호</p>
                          <input type="email" className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} defaultValue={memberCellphone} onChange={(e) => { setMemberCellphone(Util.handleMobileNumberChange(e.target.value)) }} />
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              onClick={() => { updateDriver() }}
                            >
                              수정하기
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  }
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

// business-list-management-screen.propTypes = {
//   registerUser: PropTypes.func,
//   registerUserFailed: PropTypes.func,
//   registrationError: PropTypes.any,
//   user: PropTypes.any,
// }

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default DriverManagementScreen

// export default connect(mapStatetoProps, {
//   registerUser,
//   apiError,
//   registerUserFailed,
// })(business-list-management-screen)
