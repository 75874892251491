import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap"
import { Link, useLocation } from "react-router-dom"
// import  Util  from "./Common/Util"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import moment from 'moment';

// import images
import client from "../../assets/images/client.png";
import transactionAmount from "../../assets/images/transactionAmount.png";
import transactionPrice from "../../assets/images/transactionPrice.png";
import tradeHistory from "../../assets/images/거래내역.jpg";
import news from "../../assets/images/news.jpg";

import { useSelector } from 'react-redux';

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import ChartistGraph from 'react-chartist';

import "chartist/dist/scss/chartist.scss";

const style = { width: '50%', fontSize: 14 };

const clickedStyle = { backgroundColor: "#48C3B1", color: "#ffffff" };

//i18n
import { withTranslation } from "react-i18next"
import { chartResponse } from 'crud/auth.crud';
import { useEffect } from 'react';

const chartHistory = () => {
  const location = useLocation()
  const selector = useSelector(state => state)
  const newDate = new Date()
  const [companyId, setCompanyId] = useState(location.state.companyId)
  const [companyName, setCompanyName] = useState(location.state?.companyName || selector.auth.user.member_group?.[0]?.company_group?.name)
  const [modalShow, setModalShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableValue, setTableValue] = useState([]);
  const [tableValue2, setTableValue2] = useState([]);
  const [chartValue, setChartValue] = useState([]);
  const [size, setSize] = useState(4);
  const [totalElements, setTotalElements] = useState(4);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [letDate, setLetDate] = useState(newDate);
  const [month, setMonth] = useState(moment(newDate).format("MM"));
  const [day, setDay] = useState("");
  const [dayText, setDayText] = useState("");
  const [checkedDateType, setCheckedDateType] = useState("");
  const [year, setYear] = useState(moment(newDate).format("YYYY"));
  const [findingState, setFindingState] = useState('year')

  const dayData = ['일', '월', '화', '수', '목', '금', '토']

  const lineChartData = findingState === 'year' ? {
    labels: [...chartValue.map(item => item.month)],
    // ['1월', '02월', '03월', '04월', '05월', '06월', '07월', '08월', '09월', '10월', '11월', '12월'],
    series: [
      chartValue.map((item) => {
        return (item.sum)
      })
    ],
  }
    :
    {
      labels: [...tableValue2.map(item => item[0])],
      // ['1월', '02월', '03월', '04월', '05월', '06월', '07월', '08월', '09월', '10월', '11월', '12월'],
      series: [
        tableValue2.map((item) => {
          return (item[1])
        })
      ],
    }
    ;
  // if(lineChartData.labels.indexOf('12월') > -1 || lineChartData.labels.indexOf('01월') > -1){
  //   for(let i=1; i<=12; i++){
  //     if(lineChartData.labels.indexOf(i > 9 ? `${i}월` : `0${i}월`) == -1){
  //       lineChartData.labels.push(i > 9 ? `${i}월` : `0${i}월`)
  //     }
  //     if(lineChartData.labels.length === 12){
  //       break
  //     }
  //   }
  // }
  // else{
  //   if(lineChartData.labels[lineChartData.labels.length - 1])
  // }
  const lineChartOptions = {
    low: 0,
    showArea: true,
    // chartPadding: {right: 40}
    // axisY: {
    //   labelInterpolationFnc: function(value) {
    //     return value / 1000 + "k";
    //   }
    // }
  }

  const getDataList = async () => {
    if (isFetching || isListEnd) {
      return;
    }
    let date = new Date(letDate)
    let yearDate = new Date(letDate)

    let params = {
      page: page,
      company: companyId,
      start_ym: moment(yearDate.setFullYear(yearDate.getFullYear() - 1)).format("YYYY-MM") + '-01',
      end_ym: moment(date.setMonth(date.getMonth() + 1)).format('YYYY-MM') + '-01',
    }

    console.log("params >>>", params)
    const response = await chartResponse(params);
    const content = response.data?.data || [];
    console.log("content >>>", content)
    let tmpArr = []
    let tmpMonth = []
    let tmpDate = '';

    for(let i=0; i<12; i++){
      tmpDate = moment(date.setMonth(date.getMonth()-1)).format('YYYY-MM')
      tmpMonth.push(moment(date).format('MM')+'월')
      tmpArr.push(tmpDate)
    }  

    let tempArr = []
    let chartArr = []
    let tmpArr2 = [0, 0, 0, 0, 0, 0, 0]
    let prevTmpArr2 = [0, 0, 0, 0, 0, 0, 0]
    for (let i = 0; i < tmpArr.length; i++) {
      let tmpSum = 0;
      for (let j = 0; j < content.length; j++) {
        const data = content[j];
        if (tmpArr[i] === moment(data.created_at).format('YYYY-MM')) {
          tmpSum += data.qty;
          if (i === 0) {
            tmpArr2[new Date(data.created_at).getDay()] += data.qty
          }
          if (i === 1) {
            prevTmpArr2[new Date(data.created_at).getDay()] += data.qty
          }
        }
      }
      let item = {
        date: tmpArr[i],
        month: tmpMonth[i],
        sum: tmpSum,
        bar: tmpSum.toString().length > 2 ? tmpSum / 10 ** ((tmpSum.toString().length - 2)) * 2 : tmpSum * 2
      }
      console.log(tmpArr[i], tmpSum)
      let items = [
        tmpArr[i],
        tmpSum,
      ]
      tempArr.push(items)
      // console.log(tempArr[i])
      chartArr.push(item)
    }

    for (let i = 0; i < tempArr.length; i++) {
      let val = tempArr[i]
      let tmpDiff = val[1] - (tempArr[i + 1]?.[1] || 0)
      val.push(tmpDiff)
      val.push(parseInt(val[1] * 0.01))
    }
    tempArr = tempArr.filter(itm => itm[0] + '-01' !== params.start_ym)
    chartArr = chartArr.filter(itm => itm.date + '-01' !== params.start_ym)
    chartArr.reverse()

    let tempArr2 = []
    for (let i = 0; i < tmpArr2.length; i++) {
      let items = [
        dayData[i],
        tmpArr2[i],
        tmpArr2[i] - prevTmpArr2[i],
        parseInt(tmpArr2[i] * 0.15)
      ]
      tempArr2.push(items)
    }

    console.log("tempArr >>> ", tempArr)
    console.log("chartArr >>> ", chartArr)
    console.log("tempArr2 >>> ", tempArr2)

    setTableValue(tempArr)
    setChartValue(chartArr)
    setTableValue2(tempArr2)
    setIsFetching(false)
    setIsLoading(false)
  }

  useEffect(() => {
    getDataList();
  }, [])

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false)
      // getDataList();
    }
  }, [isRefresh])

  // const handleYearClick = (e) => {
  //   setYear(e.target.value)
  //   setMonthDateText(`${e.target.value}년`),
  //   setIsFetching(false),
  //   setIsListEnd(false),
  //   setDataList([]),
  //   setNewDateText(''),
  //   setMonth(''),
  //   setMaxDate(new Date(e.target.value, Month,0).getDate()),
  //   setPage(1)
  //   setIsRefresh(true)    
  // }

  // const handleMonthClick = (e) => {
  //   let value = e.target.value < 10 ? '0'+e.target.value : e.target.value
  //   setNewMonth(value)
  //   setMonthDateText(`${value}월`),
  //   setIsFetching(false),
  //   setIsListEnd(false),
  //   setDataList([]),
  //   setNewDateText(''),
  //   setMaxDate(new Date(e.target.value, newMonth,0).getDate()),
  //   setPage(1),
  //   setIsRefresh(true)    
  // }

  // const handleDateClick = (e) => {
  //   let value = e.target.value < 10 ? '0'+e.target.value : e.target.value
  //   setNewDateText(value),
  //   setMonthDateText(`${newMonth}/${value}일자`),
  //   setIsFetching(false),
  //   setIsListEnd(false),
  //   setDataList([]),
  //   setPage(1),
  //   setIsRefresh(true)
  // }

  return (
    <Row>
      <Col xl={4} style={{ margin: "0 auto", minWidth: 741 }}>
        <Card>
          <CardBody>
            <h4 className="mb-4" style={{ textAlign: 'center' }}>{companyName}</h4>
            <div className="btn-group" style={{ display: 'flex', width: '72%', margin: "36px auto 36px", height: 35 }}>
              <Button
                style={findingState == 'year' ? { ...clickedStyle, ...style } : { ...style }}
                type="button"
                size="sm"
                className="waves-effect"
                onClick={() => {
                  setFindingState('year')
                }}
              >
                월별 배출량 차트
              </Button>
              <Button
                style={findingState == 'month' ? { ...clickedStyle, ...style } : { ...style }}
                type="button"
                size="sm"
                className="waves-effect"
                onClick={() => {
                  setFindingState('month')
                }}
              >
                요일별 배출량 차트
              </Button>
            </div>
            <Row>
              <Col lg={12} style={{ margin: "24px auto" }}>
                <div>
                  <ChartistGraph data={lineChartData} style={{ height: "300px" }} options={lineChartOptions} type={'Bar'} />
                </div>
              </Col>
              <Col lg={8} style={{ margin: "24px auto" }}>
                <Row>
                  <table style={{ textAlign: 'center' }}>
                    <tr style={{ height: 36 }}>
                      <td style={{ border: "1px solid #48C3B1", backgroundColor: "#48c3b1", color: "#000", fontWeight: "600", width: 100 }}>{findingState === 'year' ? '년/월' : '요일'}</td>
                      <td style={{ border: "1px solid #48C3B1", backgroundColor: "#48c3b1", color: "#000", fontWeight: "600", width: 100 }}>배출량(kg)</td>
                      <td style={{ border: "1px solid #48C3B1", backgroundColor: "#48c3b1", color: "#000", fontWeight: "600", width: 100 }}>증감</td>
                      <td style={{ border: "1px solid #48C3B1", backgroundColor: "#48c3b1", color: "#000", fontWeight: "600", width: 100 }}>CO₂배출량</td>
                    </tr>
                    {findingState === 'year' && tableValue.map((item, index) => {
                      return (
                        <tr style={{ height: 36 }} key={index}>
                          <td style={{ border: "1px solid #48C3B1" }}>{item[0]}</td>
                          <td style={{ border: "1px solid #48C3B1" }}>{item[1]}</td>
                          <td style={{ border: "1px solid #48C3B1", color: item[2] < 0 ? 'red' : 'blue' }}>{item[2]}</td>
                          <td style={{ border: "1px solid #48C3B1" }}>{item[3]}</td>
                        </tr>
                      )
                    })}
                    {findingState === 'month' && tableValue2.map((item, index) => {
                      return (
                        <tr style={{ height: 36 }} key={index}>
                          <td style={{ border: "1px solid #48C3B1" }}>{item[0]}</td>
                          <td style={{ border: "1px solid #48C3B1" }}>{item[1]}</td>
                          <td style={{ border: "1px solid #48C3B1", color: item[2] < 0 ? 'red' : 'blue' }}>{item[2]}</td>
                          <td style={{ border: "1px solid #48C3B1" }}>{item[3]}</td>
                        </tr>
                      )
                    })}
                  </table>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

    </Row>
  )
}

chartHistory.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(chartHistory)
