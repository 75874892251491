import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { MapMarker, Map } from "react-kakao-maps-sdk";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// 이미지
import truck from "../../assets/images/truck.png";
import { getTmsData } from "../../crud/tms.crud"

const KakaoMap = props => {
  let interval = null
  const [dataList, setDataList] = useState([]);
  const [center, setCenter] = useState({ lat: 37.54699, lng: 127.09598 });
  const getDataList = async ()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);    
    const params = {
        company : urlParams.get('company') || '',
        date : urlParams.get('date') || ''        
    }
    console.log(params)
    const rs = await getTmsData(params)
    console.log(rs)
    if(rs.data?.result === 'success'){
        const tmp = []
        let sumX = 0;
        let sumY = 0;
        let len = rs.data?.data.length
        for(let i=0 ;i<len; i++){
            let data = rs.data?.data[i];
            let item = {
                lat: parseFloat(data.y),
                lng: parseFloat(data.x),
                markerName: data.id+''
            }
            sumX += parseFloat(data.x);
            sumY += parseFloat(data.y);
            tmp.push(item)
        }
        if(len > 0){
          setDataList(tmp)        
          setCenter({lat : sumY / len, lng: sumX / len})        
        }        
    }         
  }
  useEffect(()=>{
    getDataList()
    interval = setInterval(()=>{
      getDataList()
    }, 15000)

    return () => {
      clearInterval(interval)
    }
  },[])

  return (
    <React.Fragment>
      <div className="page-content" style={{padding: 0}}>      
        <Map center={center} style={{ width: "500px", height: "500px" }}>          
          {dataList && dataList.map((item)=>{
            return (
              <MapMarker 
                key={item.markerName}
                position={{ lat: item.lat, lng: item.lng }}
                image={{
                  src: truck, // 마커이미지의 주소입니다              
                  size: {
                    width: 48,
                    height: 48,
                  }, // 마커이미지의 크기입니다
                  options: {
                    offset: {
                      x: 24,
                      y: 56,
                    }, // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                  },
                }}
              >            
              </MapMarker>
            )
          })}                 
        </Map>
      </div>
    </React.Fragment>
  )
}

export default KakaoMap;