import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { useSelector } from "react-redux";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import weaver from "../../assets/images/weaver.png";

const BusinessManagementScreen = props => {
  const selector = useSelector(state => state)

  const [companyDetailId, setCompanyDetailId] = useState('');
  const [sector, setSector] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [companyListFile, setCompanyListFile] = useState(undefined);
  const [modalShow, setModalShow] = useState(false);
  const [area, setArea] = useState('');
  const [waterPerDay, setWaterPerDay] = useState('');
  const [dailyEmission, setDailyEmission] = useState('');
  const [collection, setCollection] = useState('');
  const [desiredCollectionDays, setDesiredCollectionDays] = useState([]);

  const code = selector.Data?.code[0] || []
  const sectors = code.filter(item => item.code === 'sectors')
  const areaList = code.filter(item => item.code === 'areaList')
  const waterPerDays = code.filter(item => item.code === 'waterPerDays')
  const dailyEmissions = code.filter(item => item.code === 'dailyEmissions')
  const collections = code.filter(item => item.code === 'collections')
  const desiredCollectionDaysArr = code.filter(item => item.code === 'weeks')
  
  useEffect(() => {
    const memberData = selector.Profile?.auth?.[0] || selector.auth?.user;
    const companyList = memberData?.member_group;
    const mainCompany = companyList[0];
    const mainCompanyDetail = mainCompany.company_group.company_detail[0];
    setCompanyDetailId(mainCompanyDetail.id || '');
    setSector(mainCompanyDetail.sector || '');
    setBusinessType(companyList?.[0]?.company_group?.company_detail?.[0]?.type);
    setCompanyListFile('');
    setModalShow(false);
    setArea('');
    setWaterPerDay('');
    setDailyEmission(mainCompanyDetail.day_emission || '');
    setCollection(mainCompanyDetail.collection_amount.toString() || '');
    setDesiredCollectionDays(mainCompanyDetail.collection_day ? JSON.parse(mainCompanyDetail.collection_day) : []);
    }, []);

  const isValiddate = () => {
    if(sector === ''){
      return alert("업종을 선택해주세요.")
    }
    if(collection === ''){
      return alert("예상수거량을 선택해주세요.")
    }
    if(desiredCollectionDays.length === 0){
      return alert("희망수거일을 선택해주세요.")
    }
    return "success";
  }

  // 위에는 사업장 목록 파일 삭제, 아래껀 필수 업로드 서류 삭제
  // const deleteFile = (item) => {
  //   setCompanyListFile(companyListFile.filter(data => data !== item))
  // }

  const deleteDocument = (item, data) => {
    item.changeState(item.currentState.filter(element => element.name !== data.name))
  }

  // 중복되는 파일을 제외하고 파일등록(위에껀 사업장 목록 파일, 아래껀 필수 업로드 서류)
  // const registFiles = (e) => {
  //   let filteredFiles
  //   for(let i=0;i<e.target.files.length;i++){
  //     if(companyListFile?.length > 0 && companyListFile?.map(item => item.name).indexOf(e.target.files[i].name) != -1){
  //       filteredFiles = (filteredFiles || [...e.target.files]).filter(data => data !== [...e.target.files][i]);
  //     }
  //   }
  //   setCompanyListFile([...companyListFile, ...(filteredFiles || e.target.files)])
  // }

  const registDocument = (e, item) => {
    let filteredFiles
    for(let i=0;i<e.target.files.length;i++){
      if(item.currentState?.length > 0 && item.currentState?.map(item => item.name).indexOf(e.target.files[i].name) != -1){
        filteredFiles = (filteredFiles || [...e.target.files]).filter(data => data !== [...e.target.files][i]);
      }
    }
    item.changeState(item.type === 'multi' ? [...item.currentState, ...(filteredFiles || e.target.files)] : [...e.target.files])
  }

  const handleDesiredCollectionDays = (item, num) => {                
    if(desiredCollectionDays.filter(itm => itm.key === item.key).length > 0){            
      setDesiredCollectionDays([...desiredCollectionDays.filter(value => value.key !== item.key)]);
    }else if(desiredCollectionDays.length < num){
      setDesiredCollectionDays([...desiredCollectionDays, item]);
    }
  }

  const thisState = {
    companyDetailId : companyDetailId,
    sector : sector,
    businessType : businessType,
    companyListFile : companyListFile,
    modalShow : modalShow,
    area : area,
    waterPerDay : waterPerDay,
    dailyEmission : dailyEmission,
    collection : collection,
    desiredCollectionDays : desiredCollectionDays,
  }

  const addFileData = (items) =>{
  }

  const resetFileData = (items) =>{
  }

  return (
    // <React.Fragment>
    //   <div className="page-content">
    //   <MetaTags>
    //     <title>사업장 정보 수정 |  IoT Monitor</title>
    //   </MetaTags>
    //   <div className="account-pages">
    //     <Container fluid>
    //       {/* Render Breadcrumbs */}
    //       <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="사업장 정보 수정" />

    //       <Row className="justify-content-center">
    //         <Col md={8} lg={6} style={{width: '20%'}}>
    //           <Card className="overflow-hidden">
    //             <div className="bg-primary">
    //               <div className="text-primary text-center p-4">
    //                 <h5 className="text-white font-size-20">사업장 정보</h5>
    //                 <p className="text-white-50"></p>
    //                 <a href="index.html" className="logo logo-admin">
    //                   <div className="logo logo-admin">
    //                    <img src={weaver} height="24" alt="logo" />
    //                  </div>
    //                 </a>
    //               </div>
    //             </div>
                 <CardBody className="p-4">
                  <div className="p-3">

                      {props.registrationError &&
                        props.registrationError ? (
                          <Alert color="danger">
                            {props.registrationError}
                          </Alert>
                        ) : null}

                      <div className="mb-3">
                        <legend>업종선택</legend>
                      {   
                      sectors && sectors.map((item, index) => { return (
                        <div className="form-check form-check-inline" key={item.key+'_'+index}>
                          <input className="is-untouched is-pristine av-valid form-check-input" checked={sector == item.key} type='radio' id={item.key} name='sectors' value={item.key} onChange={()=>{setSector(item.key)}} />
                          <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                        </div>
                      )})
                      }
                      </div>

                      {/* { businessType === 'multi' &&
                      <div>
                        <div className="mb-3">
                          <p className="noMargin" style={{fontSize: 18, color: 'black', marginTop: 12}}>다수사업장 리스트 업로드</p>
                        </div>
                        <div className="mb-3">
                          <p style={{marginBottom: 6, color: "black", fontSize: 15}}>- 사업장명/대표자명/사업장등록번호/우편번호/주소/연락처/일배출량/희망수거일 필수</p>
                          <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                          <input style={{display: "none"}} type="file" multiple={true} id="ex_file" onChange={(e)=>{
                            registFiles(e)
                          }}/> 
                          {companyListFile?.length > 0 && companyListFile.map((item, index) => {
                            return (
                              <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                                <p className="noMargin" style={{paddingLeft: 6}}>{item.name}</p>
                                <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                                  deleteFile(item)
                                }}>X</p>
                              </div>
                            )
                          })
                          }
                        </div>
                      </div>
                      } */}

                      { businessType === 'many' &&
                        <div className="mb-3 allbaroRegist">
                          <p style={{marginBottom: 0, fontSize: 18}}>올바로 등록 대상 확인</p>
                          <p style={{marginBottom: 0, fontSize: 14, color: '#ff4949'}}>(*체크 시 올바로 등록 대상 사업장입니다.)</p>
                          <div className="mb-3">
                            <div>
                              <legend>· 사업장 면적</legend>
                              {   
                              areaList && areaList.map((item, index) => { return (
                                <div className="form-check form-check-inline" key={item.key+'_'+index}>
                                  <input className="is-untouched is-pristine av-valid form-check-input" checked={area == item.key} type='radio' id={item.key} name='areaList' value={item.key} onChange={()=>{setArea(item.key)}} />
                                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                                </div>
                              )})
                              }
                            </div>
                            <div>
                              <legend>· 일 식수인원</legend>
                              {   
                              waterPerDays && waterPerDays.map((item, index) => { return (
                                <div className="form-check form-check-inline" key={item.key+'_'+index}>
                                  <input className="is-untouched is-pristine av-valid form-check-input" checked={waterPerDay == item.key} type='radio' id={item.key} name='waterPerDays' value={item.key} onChange={()=>{setWaterPerDay(item.key)}} />
                                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                                </div>
                              )})
                              }
                            </div>
                            <div>
                              <legend>· 일 배출량</legend>
                              {   
                              dailyEmissions && dailyEmissions.map((item, index) => { return (
                                <div className="form-check form-check-inline" key={item.key+'_'+index}>
                                  <input className="is-untouched is-pristine av-valid form-check-input" checked={dailyEmission == item.key} type='radio' id={item.key} name='dailyEmissions' value={item.key} onChange={()=>{setDailyEmission(item.key)}} />
                                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                                </div>
                              )})
                              }
                            </div>
                          </div>
                        </div>
                      }

                      <div className="mb-3">
                        <legend>예상수거량(용기통 L수 확인 후 체크)</legend>
                      {   
                      collections && collections.map((item, index) => { return (
                        <div className="form-check form-check-inline" key={item.key+'_'+index}>
                          <input className="is-untouched is-pristine av-valid form-check-input"  checked={collection == item.key} type='radio' id={item.key} name='collections' value={item.key} onChange={()=>{setCollection(item.key)}} />
                          <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                        </div>
                      )})
                      }
                      </div>

                      <div className="mb-3">
                        <legend>희망수거일(최대 3회까지 선택 가능)</legend>
                      {   
                      desiredCollectionDaysArr && desiredCollectionDaysArr.map((item, index) => { return (
                        <div className="form-check form-check-inline" key={item.key+'_'+index}>
                          <input className="is-untouched is-pristine av-valid form-check-input"  checked={desiredCollectionDays.filter(data => data.key == item.key).length > 0} type='checkbox' id={item.key} name='desiredCollectionDays' value={item.key} onChange={()=>{
                            handleDesiredCollectionDays(item, 3)
                            }} />
                          <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                        </div>
                      )})
                      }
                      </div>

                      {/* <div className="mb-3">
                        <p className="noMargin" style={{fontSize: 18, color: 'black', marginTop: 12}}>필수 업로드 서류</p>
                      </div>
                      {
                        touchFileUploadButtonData && touchFileUploadButtonData.map((item, index)=>{
                          return (
                            <div className="mb-3" key={item.key+'_'+index}>
                              <p style={{marginBottom: 6, color: "black", fontSize: 15}}>{item.name}</p>
                              <label className="btn btn-primary waves-effect waves-light" htmlFor={item.id} style={{height: 36, marginLeft: 6}}>파일 선택</label>
                              <input style={{display: "none"}} type="file" accept=".pdf" multiple={ item.type === 'multi' ? "multiple" : ""} id={item.id} onChange={(e)=>{
                                registDocument(e, item)
                              }}/>
                              {item.currentState?.length > 0 && item.currentState?.map((data, index) => {
                                return (
                                  <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                                    <p className="noMargin" style={{paddingLeft: 6}}>{data.name}</p>
                                    <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                                      deleteDocument(item, data)
                                    }}>X</p>
                                  </div>
                                )
                              })
                              }
                            </div>
                          )
                        })
                      } */}

                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            style={{marginTop: 24}}
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={() => {
                              if(isValiddate() === 'success'){
                                let items = {...thisState}
                                addFileData(items)
                                props.handleWrite(items)
                                resetFileData(items)
                              }
                            }}
                          >
                              변경사항 저장하기
                          </button>
                        </div>
                      </div>
                      
                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                        </div>
                      </div>
                  </div>
                </CardBody> 
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    //   </div>
    // </React.Fragment>
  )
}

BusinessManagementScreen.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(BusinessManagementScreen)
