import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link, useHistory } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import { useSelector } from 'react-redux';

import Emitter from "./Emitter";
import Collection from "./Collection";
import Treatment from "./Treatment";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const AllbaroProcess = () => {
  const selector = useSelector(state => state)

  const [type, setType] = useState(selector.auth.user?.type || "")

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>올바로 처리내역 |  IoT Monitor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Breadcrumbs maintitle="홈" title="관리대장" breadcrumbItem="올바로 처리내역" />
            <Col sm={6}>
            </Col>
          </Row>

          {type == "emitter" && <Emitter />}
          {type == "collection" && <Collection />}
          {type == "treatment" && <Treatment />}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllbaroProcess
