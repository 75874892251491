import { func } from "prop-types";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getBusinessList, patchBusinessList, postCollectData, postMyContract, setNewBusinessList } from "crud/auth.crud";

import _ from "lodash";
import moment from "moment";

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

const BusinessList = () => {
  const history = useHistory()
  const selector = useSelector(state => state)
  const newDate = new Date()
  const nextDate = new Date()

  const [dataList, setDataList] = useState([]);
  const [sumList, setSumList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [pricePerL, setPricePerL] = useState(50);
  const [user, setUser] = useState(selector.auth?.user);
  const [company, setCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.id);
  const [startDate, setStartDate] = useState(moment(newDate).format('YYYY-MM') + '-01 00:00:00');
  const [endDate, setEndDate] = useState(moment(nextDate.setMonth(nextDate.getDate() + 1)).format('YYYY-MM') + '-01 00:00:00');
  const [modalShow, setModalShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(4);
  const [totalElements, setTotalElements] = useState(4);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [companyListFile, setCompanyListFile] = useState(undefined);
  const [onUpload, setOnUpload] = useState(false);

  const getMyContract = async (params) => {
    try {
      let tmp = []
      const response = await postMyContract(params);
      if (response.data?.result === 'success') {
        const dataList = response?.data?.data;
        for (let i = 0; i < dataList.length; i++) {
          const tmpList = dataList[i]
          for (let j = 0; j < tmpList.data.length; j++) {
            const tmpData = tmpList.data[j]
            if (tmpData.contract_company_group_data.type === 'collection') {
              tmp.push({
                id: dataList[i].id,
                minPrice: tmpData.contract_group_data.min_price,
                pricePerL: tmpData.contract_group_data.price_per_l,
              })
            }
            break;
          }
        }
      }
      setPriceList(tmp)
    } catch (error) {
      console.error(error);
    }
  }

  const getCollectDataSum = async (dataList) => {
    try {
      let tmp = []
      for (let i = 0; i < dataList.length; i++) {
        tmp.push(dataList[i].id)
      }
      const params = {
        ids: JSON.stringify(tmp),
        start_ym: startDate,
        end_ym: endDate,
      }
      const response = await postCollectData(params);
      if (response.data?.result === 'success') {
        const dataList = response?.data?.data;
        setSumList(dataList)
      }
      getMyContract(params)
    } catch (error) {
      console.error(error);
    }
  }

  const getDataList = async () => {
    try {
      const response = await getBusinessList(`?id=${company}`);
      if (response.data?.result === 'success') {
        let dataList = response?.data?.data?.filter(itm => itm.id !== company);
        let myData = response?.data?.data?.filter(itm => itm.id === company)
        setDataList([...myData, ...dataList])
        getCollectDataSum(dataList)
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getDataList()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>사업장 리스트 |  IoT Monitor</title>
        </MetaTags>
        <div className="account-pages">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs maintitle="홈" title="관리대장" breadcrumbItem="사업장 리스트" />

            <Row className="justify-content-center">
              <Col md={8} lg={6} style={{ width: '20%', minWidth: 435 }}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">사업장 리스트</h5>
                      <p className="text-white-50"></p>
                      <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                    </div>
                  </div>
                  <CardBody className="p-4">
                    <div className="p-3" style={{ marginTop: 36 }}>

                      {dataList && dataList.map((item, index) => {
                        const sum = sumList.filter(itm => itm.id === item.id)?.[0]?.sum || 0;
                        return (
                          <div key={index} style={{ marginHorizontal: 20, cursor: 'pointer' }} onClick={() => {
                            history.push({ pathname: '/each-site-history', state: { companyId: item.id, companyName: item.name } })
                          }}>
                            <div style={{ padding: 12, marginBottom: 12, border: "1px solid #48C3B1", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderRadius: 5 }} >
                              <div>
                                <p style={{ fontSize: 20, color: 'black', fontWeight: 'bold', width: 180, margin: 0 }}>{item.name}</p>
                              </div>
                              <div style={{ zIndex: 10 }}>
                                <p style={{ fontSize: 20, color: 'black', fontWeight: 'bold', margin: 0 }}>{sum}L</p>
                              </div>
                            </div>
                          </div>
                        )
                      })}

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                        </div>
                      </div>

                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

// business-list-management-screen.propTypes = {
//   registerUser: PropTypes.func,
//   registerUserFailed: PropTypes.func,
//   registrationError: PropTypes.any,
//   user: PropTypes.any,
// }

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default BusinessList

// export default connect(mapStatetoProps, {
//   registerUser,
//   apiError,
//   registerUserFailed,
// })(business-list-management-screen)
