import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import { useLocation, useHistory } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import loading from "../../assets/images/loading.gif";

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

import Board from './Component/Board';


//i18n
import { withTranslation } from "react-i18next"
import { postAddCompany } from 'crud/auth.crud';

const CompanyMain = props => {
  const location = useLocation()
  const history = useHistory()

  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState(0)
  const [dataList, setDataList] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [inputData, setInputData] = useState({});
  const [page, setPage] = useState(1);
  const [centerMarker, setCenterMarker] = useState({ lat: 36.44699, lng: 127.09598 })


  const [inputName, setInputName] = useState('');
  const [inputDepartment, setInputDepartment] = useState('');



  const nextGetData = () => {
    setOnUpload(true)
    let newArr = []
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    setPageArr(newArr)
    setOnUpload(false)
  }


  const movePage = (id, company) => {
    console.log(id, company)
    // history.push({ pathname: '/detail', state: { id: id } })
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  // useEffect( async () => {
  //   await getDataList()
  // }, [page])

  useEffect(()=>{
    console.log(location)
  }, [])

  const addCompany = async() => {
    var params = {
      "name": inputName,
      "department": inputDepartment
    }

    console.log(params)

    const response = await postAddCompany(params)
      console.log(">>>>origin   :::: "+response.data)
      console.log(">>>>JSON Str :::: ", JSON.stringify(response))

      history.goBack()
    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col md={4}>
              <Row>
              <Col md={12}>
              <Card>
                    <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                      <div className="table-responsive" style={{ height: '80vh', minHeight: '100px' }}>
                        <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                          <thead className="chart-thead">
                            <tr>
                              <th scope="col" className='bg-dark-ash'>발주처 등록</th>
                            </tr>
                          </thead>
                        </table>



                        <div className="w-100" style={{ padding: 24 }}>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">발주처 이름</p>
                              <input value={inputName} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputName(e.target.value) }} />
                              <p/>
                              <p className="noMargin text-black font-weight-bold font-size-16">담당부서</p>
                              <input value={inputDepartment} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputDepartment(e.target.value) }} />
                            </>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <button
            className="btn btn-primary w-md waves-effect waves-light bg-dark-ash"
            type="submit"
            onClick={() => { addCompany() }}
          >
            등록
          </button>
          
          {onUpload && 
            <div style={{
                position: "absolute",
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.6)',
                zIndex: 999,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img src={loading} alt="로딩중입니다." width={'5%'}></img>
            </div>
          }

        </Container>
      </div>
    </React.Fragment>
  )
}

CompanyMain.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(CompanyMain)
