import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { useSelector } from "react-redux";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import weaver from "../../assets/images/weaver.png";

const BusinessManagementScreen = props => {
  const selector = useSelector(state => state)

  const [companyDetailId, setCompanyDetailId] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [subjectToRecycling, setSubjectToRecycling] = useState('');
  const [recyclingPurpose, setRecyclingPurpose] = useState('');
  const [recycle, setRecycle] = useState([]);
  const [businessPartners, setBusinessPartners] = useState('');
  const [businessPartnersFile, setBusinessPartnersFile] = useState({});
  const [oldBusinessPartnersFile, setOldBusinessPartnersFile] = useState({});
  const [businessPartnersFileAdd, setBusinessPartnersFileAdd] = useState({});
  const [businessPartnersFileDel, setBusinessPartnersFileDel] = useState({});
  const [facility1, setFacility1] = useState('');
  const [facility2, setFacility2] = useState('');
  const [special5, setSpecial5] = useState('');
  const [cargo35, setCargo35] = useState('');
  const [cargo12, setCargo12] = useState('');
  const [compostamount, setCompostamount] = useState('');
  const [feedamount, setFeedamount] = useState('');
  const [wasteoilamount, setWasteoilamount] = useState('');

  const code = selector.Data?.code[0] || []
  const vehiclesArrProto = code.filter(item => item.code === 'vehicles')
  const subjectToRecyclingArr = code.filter(item => item.code === 'subjectToRecycling')
  const recyclingPurposeArr = code.filter(item => item.code === 'recyclingPurpose')
  const recyclesProto = code.filter(item => item.code === 'recycles')
  const businessPartnersArr = code.filter(item => item.code === 'businessPartners')

  const vehiclesArr = vehiclesArrProto.map((vehicle) => ({
    ...vehicle,
    text: `${vehicle.text}(`,
    currentState: eval(vehicle.key),
    changeState: eval('set' + vehicle.key.charAt(0).toUpperCase() + vehicle.key.slice(1)),
  }));

  const recycles = recyclesProto.map((recycle) => ({
    ...recycle,
    text: `${recycle.text}(`,
    currentState: eval(recycle.key),
    changeState: eval('set' + recycle.key.charAt(0).toUpperCase() + recycle.key.slice(1)),
  }));

  useEffect(() => {
    const memberData = selector.Profile?.auth?.[0] || selector.auth?.user;
    const companyList = memberData?.member_group;
    const mainCompany = companyList[0];
    const mainCompanyDetail = mainCompany.company_group.company_detail[0];
    const vehicles = mainCompanyDetail.vehicle ? JSON.parse(mainCompanyDetail.vehicle) : [];
    const recycle = mainCompanyDetail.recycle_performance ? JSON.parse(mainCompanyDetail.recycle_performance) : [];

    let vehicleArr = []
    for (let i = 0; i < vehicles.length; i++) {
      let val = {
        'key': Object.keys(vehicles[i])[0],
        'text': vehicles.filter(itm => itm.key === Object.keys(vehicles[i])[0])?.[0]?.text
      }
      vehicleArr.push(val)
    }

    let recycleArr = []
    for (let i = 0; i < recycle.length; i++) {
      let val = {
        'key': Object.keys(recycle[i])[0],
        'text': recycles.filter(itm => itm.key === Object.keys(recycle[i])[0])?.[0]?.text
      }
      recycleArr.push(val)
    }

    setCompanyDetailId(mainCompanyDetail.id || '');
    setVehicles(vehicleArr);
    setSubjectToRecycling(mainCompanyDetail.recycle_waste || '')
    setRecyclingPurpose(mainCompanyDetail.recycle_use || '')
    setSpecial5(vehicles.filter(itm => Object.keys(itm)[0] === 'special5')?.[0]?.special5 || '');
    setCargo35(vehicles.filter(itm => Object.keys(itm)[0] === 'cargo35')?.[0]?.cargo35 || '');
    setCargo12(vehicles.filter(itm => Object.keys(itm)[0] === 'cargo12')?.[0]?.cargo12 || '');
    setBusinessPartners(mainCompanyDetail.customer || '')
    setFacility1(mainCompanyDetail.facility || '')
    setFacility2('')
    setRecycle(recycleArr)
    setCompostamount(recycle.filter(itm => Object.keys(itm)[0] === 'compostamount')?.[0]?.compostamount || '')
    setFeedamount(recycle.filter(itm => Object.keys(itm)[0] === 'feedamount')?.[0]?.feedamount || '')
    setWasteoilamount(recycle.filter(itm => Object.keys(itm)[0] === 'wasteoilamount')?.[0]?.wasteoilamount || '')
    setBusinessPartnersFile(memberData.member_file.filter(item => item.category === 'businessPartnersFile') || [])
    setOldBusinessPartnersFile(memberData.member_file.filter(item => item.category === 'businessPartnersFile') || [])
  }, []);

  const isValiddate = () => {
    for (let i = 0; i < vehicles.length; i++) {
      const item = vehicles[i];
      if (item.key === '') {
        alert(item.text.replace("(", "") + " 수량을 입력해주세요.")
        return ""
      }
    }
    if (!subjectToRecycling) {
      alert("재활용 대상 폐기물을 선택해주세요.")
      return ""
    }
    if (recyclingPurpose === '') {
      alert("재활용 용도를 선택해주세요.")
      return ""
    }
    for (let i = 0; i < recycle.length; i++) {
      const item = recycle[i];
      if (item.key === '') {
        return "재생원료 구매실적 " + item.text.replace("(", "") + " 값을 입력해주세요."
      }
    }
    return "success";
  }

  const inputLenght = (max, oldString, newString) => {
    const regExp = new RegExp('^.{0,' + max + '}$');
    if (regExp.exec(newString) == null) {
      return oldString;
    }
    return newString;
  }

  // 필수 업로드 서류 삭제

  const deleteDocument = (item, data) => {
    item.changeState(item.currentState.filter(element => element.name !== data.name))
  }

  // 중복되는 파일을 제외하고 파일등록

  const registDocument = (e, item) => {
    let filteredFiles
    for (let i = 0; i < e.target.files.length; i++) {
      if (item.currentState?.length > 0 && item.currentState?.map(item => item.name).indexOf(e.target.files[i].name) != -1) {
        filteredFiles = (filteredFiles || [...e.target.files]).filter(data => data !== [...e.target.files][i]);
      }
    }
    item.changeState(item.type === 'multi' ? [...item.currentState, ...(filteredFiles || e.target.files)] : [...e.target.files])
  }

  const handleCheckBoxAndTextInput = (str, item, set) => {
    const dupItem = { ...item }
    if (str.filter(itm => itm.key === item.key).length > 0) {
      set(str.filter(value => value.key !== item.key))
      item.changeState('')
    } else {
      delete dupItem.currentState
      delete dupItem.changeState
      set([...str, dupItem])
    }
  }

  const thisState = {
    companyDetailId: companyDetailId,
    vehicles: vehicles,
    subjectToRecycling: subjectToRecycling,
    recyclingPurpose: recyclingPurpose,
    recycle: recycle,
    businessPartners: businessPartners,
    businessPartnersFile: businessPartnersFile,
    oldBusinessPartnersFile: oldBusinessPartnersFile,
    businessPartnersFileAdd: businessPartnersFileAdd,
    businessPartnersFileDel: businessPartnersFileDel,
    facility1: facility1,
    facility2: facility2,
    special5: special5,
    cargo35: cargo35,
    cargo12: cargo12,
    compostamount: compostamount,
    feedamount: feedamount,
    wasteoilamount: wasteoilamount,
  }

  const addFileData = (items) => {
  }

  const resetFileData = (items) => {
  }

  return (
    // <React.Fragment>
    //   <div className="page-content">
    //   <MetaTags>
    //     <title>사업장 정보 수정 |  IoT Monitor</title>
    //   </MetaTags>
    //   <div className="account-pages">
    //     <Container fluid>
    //       {/* Render Breadcrumbs */}
    //       <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="사업장 정보 수정" />

    //       <Row className="justify-content-center">
    //         <Col md={8} lg={6} style={{width: '20%'}}>
    //           <Card className="overflow-hidden">
    //             <div className="bg-primary">
    //               <div className="text-primary text-center p-4">
    //                 <h5 className="text-white font-size-20">사업장 정보</h5>
    //                 <p className="text-white-50"></p>
    //                 <a href="index.html" className="logo logo-admin">
    //                   <div className="logo logo-admin">
    //                    <img src={weaver} height="24" alt="logo" />
    //                  </div>
    //                 </a>
    //               </div>
    //             </div>
    <CardBody className="p-4">
      <div className="p-3">

        {props.registrationError &&
          props.registrationError ? (
          <Alert color="danger">
            {props.registrationError}
          </Alert>
        ) : null}

        <div className="mb-3">
          <legend>보유차량 수</legend>
          {
            vehiclesArr && vehiclesArr.map((item, index) => {
              return (
                <div className="form-check form-check-inline" style={{ display: "flex" }} key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" checked={vehicles.filter(itm => itm.key === item.key).length > 0} type='checkbox' id={item.key} name="vehicles" value={item.key} onChange={(e) => {
                    handleCheckBoxAndTextInput(vehicles, item, setVehicles)
                  }}
                  />
                  <label className="form-check-label" htmlFor={item.key} style={{ marginLeft: '0.5rem' }}>{item.text}</label>
                  <input style={{ width: 64, height: 23, padding: 6, marginLeft: '0.5rem', marginRight: 6 }} className="form-control" value={item.currentState} disabled={vehicles.filter(itm => itm.key === item.key).length > 0 ? false : "disabled"} onChange={(e) => {
                    item.changeState(e.target.value)
                  }} />
                  <p>대)</p>
                </div>
              )
            })
          }
        </div>

        <div className="mb-3">
          <legend>재활용 대상 폐기물</legend>
          {
            subjectToRecyclingArr && subjectToRecyclingArr.map((item, index) => {
              return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" type='radio' checked={subjectToRecycling == item.key} id={item.key} name="subjectToRecycling" value={item.key} onChange={() => { setSubjectToRecycling(item.key) }} />
                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
              )
            })
          }
        </div>

        <div className="mb-3">
          <legend>재활용의 용도</legend>
          {
            recyclingPurposeArr && recyclingPurposeArr.map((item, index) => {
              return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" type='radio' checked={recyclingPurpose == item.key} id={item.key} name="recyclingPurpose" value={item.key} onChange={() => { setRecyclingPurpose(item.key) }} />
                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
              )
            })
          }
        </div>

        <div className="mb-3">
          <p className="noMargin" style={{ fontSize: 16, color: 'black', marginTop: 12 }}>시설보유현황</p>
          <textarea className="form-control" value={facility1} style={{ marginBottom: '2rem' }} placeholder="보유하고 있는 시설을 입력해주세요." onChange={(e) => {
            setFacility1(inputLenght(50, facility1, e.target.value))
          }}></textarea>
        </div>

        <div className="mb-3">
          <legend>재생원료 구매 실적</legend>
          {
            recycles && recycles.map((item, index) => {
              return (
                <div className="form-check form-check-inline" style={{ display: "flex" }} key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" checked={recycle.filter(itm => itm.key === item.key).length > 0} type='checkbox' id={item.key} name="recycles" value={item.key} onChange={(e) => {
                    handleCheckBoxAndTextInput(recycle, item, setRecycle)
                  }}
                  />
                  <label className="form-check-label" htmlFor={item.key} style={{ marginLeft: '0.5rem' }}>{item.text}</label>
                  <input style={{ width: 64, height: 23, padding: 6, marginLeft: '0.5rem', marginRight: 6 }} className="form-control" value={item.currentState} disabled={recycle.filter(itm => itm.key === item.key).length > 0 ? false : "disabled"} onChange={(e) => {
                    item.changeState(e.target.value)
                  }} />
                  <p>L)</p>
                </div>
              )
            })
          }
        </div>

        <div className="mb-3">
          <legend>거래처 수</legend>
          {
            businessPartnersArr && businessPartnersArr.map((item, index) => {
              return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" type='radio' checked={businessPartners == item.key} id={item.key} name="businessPartners" value={item.key} onChange={() => { setBusinessPartners(item.key) }} />
                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
              )
            })
          }
        </div>

        {/* <div className="mb-3">
          <p className="noMargin" style={{ fontSize: 18, color: 'black', marginTop: 12 }}>필수 업로드 서류</p>
        </div>
        {
          touchFileUploadButtonData && touchFileUploadButtonData.map((item, index) => {
            return (
              <div className="mb-3" key={item.key + '_' + index}>
                <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>{item.name}</p>
                <label className="btn btn-primary waves-effect waves-light" htmlFor={item.id} style={{ height: 36, marginLeft: 6 }}>파일 선택</label>
                <input style={{ display: "none" }} type="file" multiple={item.type === 'multi' ? "multiple" : ""} accept=".pdf" id={item.id} onChange={(e) => {
                  registDocument(e, item)
                }} />
                {item.currentState?.length > 0 && item.currentState?.map((data, index) => {
                  return (
                    <div key={index} style={{ border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6 }}>
                      <p className="noMargin" style={{ paddingLeft: 6 }}>{data.name}</p>
                      <p className="noMargin" style={{ paddingRight: 6, color: "#000", cursor: "pointer" }} onClick={() => {
                        deleteDocument(item, data)
                      }}>X</p>
                    </div>
                  )
                })
                }
              </div>
            )
          })
        } */}

        <div className="mb-3 row">
          <div className="col-12 text-end">
            <button
              style={{ marginTop: 24 }}
              className="btn btn-primary w-md waves-effect waves-light"
              type="submit"
              onClick={() => {
                if (isValiddate() === 'success') {
                  console.log({...thisState})
                  let items = { ...thisState }
                  addFileData(items)
                  props.handleWrite(items)
                  resetFileData(items)
                }
              }}
            >
              변경사항 저장하기
            </button>
          </div>
        </div>

        <div className="mt-2 mb-0 row">
          <div className="col-12 mt-4">
          </div>
        </div>
      </div>
    </CardBody>
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    //   </div>
    // </React.Fragment>
  )
}

BusinessManagementScreen.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(BusinessManagementScreen)
