import _ from "lodash";
import moment from 'moment';
import persistor from "index"
import { SET_AUTH, CLEAR_AUTH, SET_ACCESS_TOKEN } from "../store/auth/profile/actionTypes"
import { RE_LOGIN_USER, LOGOUT_USER } from "../store/auth/login/actionTypes"
import { CLEAR_DATA } from "../store/auth/data/actionTypes"
import { API_URL } from "../crud/auth.crud"
import { reLoginUser, apiError } from "../store/actions"

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { accessToken }
      } = store.getState();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }      
      return config;
    },
    err => Promise.reject(err)    
  );


  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {
        config,
        response,
      } = error;
      if (response?.status === 401) {
        if(config?.url.indexOf('refresh/') >= 0){
            localStorage.clear()
            axios.defaults.headers.common.Authorization = '';
            window.location.href = '/dashboard'
            return Promise.reject(error);
        }
        if(window.location.href.indexOf('/login') >= 0){
          localStorage.clear()
          axios.defaults.headers.common.Authorization = '';
          return Promise.reject(error);
        }
        const originalRequest = config;
        const authData = await store.getState()?.auth;
        try {
          const { data } = await axios.post(API_URL+'/token/refresh/', { refresh: authData?.refreshToken || '' });
          await store.dispatch({ type: RE_LOGIN_USER, data: {access : data?.access }});
          localStorage.setItem("authUser", JSON.stringify(authData?.user))
          // 새로운 토큰 저장
          axios.defaults.headers.common.Authorization = `Bearer ${data?.access}`;
          originalRequest.headers.Authorization = `Bearer ${data?.access}`;
          // 401로 요청 실패했던 요청 새로운 accessToken으로 재요청
          return axios(originalRequest);
        } catch (e) {
          console.log(e)
          if (window.location.href.indexOf('/login') < 0) {
            localStorage.clear()
            axios.defaults.headers.common.Authorization = '';
            window.location.href = '/dashboard'
          }
        }
      }
      return Promise.reject(error);
    }
  );

}