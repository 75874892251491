import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button, Input, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import { MapMarker, Map, CustomOverlayMap } from "react-kakao-maps-sdk";
import customMarker from "assets/images/marker.png"
import customErrorMarker from "assets/images/marker_error.png"
import customAsMarker from "assets/images/marker_as.png"
import customDimMarker from "assets/images/marker_dim.png"
import { useHistory } from "react-router-dom"
import $ from 'jquery';
import loading from "assets/images/loading.gif";
import { over, wrap } from "lodash";
import moment from "moment";
import * as XLSX from 'xlsx';
//crud
import { postAllFixError, postFixError } from 'crud/auth.crud';

const DefaultForm = (props) => {
  const history = useHistory()

  const [overlay, setOverlay] = useState();
  const [isSearch, setIsSearch] = useState(['0', false]);
  const [searchText, setSearchText] = useState('')
  const [primeCoordinates, setPrimeCoordinates] = useState(0)
  const [coordinates, setCoordinates] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [colIndex, setColIndex] = useState([])
  const [primeWidth1, setPrimeWidth1] = useState(0)
  const [primeWidth2, setPrimeWidth2] = useState(0)
  const [deviceId, setDeviceId] = useState('')

  const { form, headers, dataList, callback, boardKey, title, centerMarker, height, fullScreen, domKey, isLoading, filterList } = props;

  const [type, setType] = useState(headers !== undefined ? headers[0].key : headers)

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setPrimeCoordinates(e.clientX)
  };
  const handleMouseMove = (e) => {
    if(isDragging){
      setCoordinates(e.clientX);
    }
  };
  const handleMouseUp = (e) => {
    setIsDragging(false)
    setCoordinates(0)
    setPrimeCoordinates(0)
    setPrimeWidth1(0)
    setPrimeWidth2(0)
    setColIndex([])
  };

  useEffect(() => {
    if(coordinates - primeCoordinates !== 0){
      $(`.${colIndex[0]+colIndex[1]}`).css({"width": `${primeWidth1 + (coordinates - primeCoordinates)}px`})
      $(`.${colIndex[0] + (colIndex[1] + 1)}`).css({"width": `${primeWidth2 + (primeCoordinates - coordinates)}px`})
    }
  }, [coordinates])

  useEffect(() => {
    if(colIndex.length !== 0){
      setPrimeWidth1(parseInt($(`.${colIndex[0]+colIndex[1]}`).css('width'), 10))
      setPrimeWidth2(parseInt($(`.${colIndex[0] + (colIndex[1] + 1)}`).css('width'), 10))
    }
  }, [colIndex])

  const mapRef = useRef()

  const deleteOverlay= (e) => {
    setOverlay()
    e.stopPropagation()
  }
  
  useEffect(() => {
    // 풀스크린 모드 on/off 시 카카오맵 resize
    if (form === 'map') {
      if (mapRef.current) {
        mapRef.current.relayout()
      }
    }
    ////
  }, [height]);

  useEffect(() => {
    // 검색창 입력 시 들어갈 로직
  }, [searchText])

  const playLoading = () => {
    return <div style={{
      position: "absolute",
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'rgba(255, 255, 255, 0.6)',
      zIndex: 999,
      display: "flex",
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <img src={loading} alt="로딩중입니다." width={'5%'}></img>
    </div>
  }

  const exportExcel = () => {
    const table = $(`#table${domKey}`);
    const rows = table.find('tr');

    const data = [];

    for (let i = 1; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('td, th');
      const rowData = [];
      for (let j = 0; j < cells.length; j++) {
        rowData.push(cells[j].innerText);
      }
      data.push(rowData);
    }

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});

    const blob = new Blob([wbout], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${title}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setOverlay()
  }, [centerMarker])

  const searchList = () => {
    filterList(type, searchText, domKey)
  }

  const changeType = () => {
    setType($(`#select${domKey}`).val() !== null ? $(`#select${domKey}`).val() : $(`#option${domKey}`).val())
  }

  useEffect(() => {
    if(deviceId.length > 0){
      callback(deviceId)
    }
  }, [deviceId])

  const onFix = async(device_id) => {
    if (window.confirm("오류 확인 완료처리 하시겠습니까?")) {
      try{
        const response = await postFixError({iot_device_id : device_id})
        if (response.status === 200) {
          const content = response.data?.results
          console.log("134",content)
          alert("완료 처리 되었습니다.");
          window.location.reload()
        }
      } catch (error) {
        console.error(error)
        alert("통신오류 입니다. 나중에 다시 실행 해 주세요.");
      }
      

    } else {
      console.log("완료처리 취소.")
    }

  };

  const onFixAll = async() => {
    if(confirm("오류 일괄 확인 완료처리 하시겠습니까?")) {
      try{
        const response = await postAllFixError()
        if (response.data?.result === 'success') {
          alert(response.data.msg);
          window.location.reload()
        } else {
          alert(response.data.msg);
        }
      }catch(error){
        console.error(error)
        alert("통신오류 입니다. 나중에 다시 실행 해 주세요.");
      }
    }
  }

  return (
    <>
      {form === 'map' &&
        <Card className="position-relative">
          <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
            <div className="table-responsive" style={{ height: height, minHeight: '300px' }}>
              <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                <thead className="chart-thead position-relative">
                  <i className="mdi mdi-fullscreen position-absolute text-white font-size-24 pointer" style={{ right: 12, lineHeight: '48px' }} onClick={() => {
                    fullScreen(domKey)
                  }} />
                  <tr>
                    <th scope="col" className="font-size-16 text-start bg-dark-ash">{title}</th>
                  </tr>
                </thead>
                <Map className="w-100 map-custom" center={centerMarker} ref={mapRef} level={height * 1 > 60 ? 7 : 13} style={{ height: `calc(${height} - 48.6px)` }}>
                  {dataList.length > 0 && dataList.map((item, index) => {return (
                      <div key={item.markerName}>
                        <MapMarker
                          onClick={() => {
                            overlay !== item ? setOverlay(item) : setOverlay()
                            setDeviceId(item.id || '')
                            callback(item.id)

                            console.log("ov",item)
                          }}
                          position={{ lat: item.lat, lng: item.lon }}
                          image={{
                            src: item.error_type && item.error_type.includes('에러') ? customErrorMarker : (item.solar_status === "AS" || item.battery_status === 'AS' ? customAsMarker : item.dimming_value === '60' ? customDimMarker : customMarker), //마커이미지의 주소입니다
                            size: {
                              width: 25,
                              height: 40,
                            },
                          }}
                        >
                        </MapMarker>
                        {overlay && index < 2 &&
                          <CustomOverlayMap
                            position={{ lat: overlay.lat, lng: overlay.lon }}
                            xAnchor={0.5}
                            yAnchor={1.4}
                          >
                            <div className="overlay font-size-12 pointer" style={{height: 180}} onClick={()=>{ goDetail()}}>
                              <p className="mb-2 font-weight-bold">{`<<${overlay.id}>>`}</p>
                              <p className="position-absolute font-size-12 pointer noMargin x-button" onClick={(e) => { deleteOverlay(e) }}>X</p>
                              <p className="noMargin"><span className="font-weight-bold">Latitude</span> : {overlay.lat}</p>
                              <p className="noMargin"><span className="font-weight-bold">Longitude</span> : {overlay.lon}</p>
                              {/* {console.log("ovvv",overlay.iot_device_model.address)} */}
                              <p className="noMargin padding-top-6 font-weight-bold">{overlay.address}</p>
                              <p className="noMargin padding-top-6 font-weight-bold">{overlay.error_type !== '' ? overlay.error_type : ""}</p>
                            </div>
                          </CustomOverlayMap>
                        }
                      </div>
                    )
                  })}
                </Map>

                {/* </tbody> */}
              </table>
            </div>

          </CardBody>
          {isLoading && playLoading()}
        </Card>
      }
      {form === 'post' &&
        <Card className="position-relative">
          <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
            <div className="table-responsive" style={{ height: height, minHeight: '250px', overflowX: 'auto' }}>
              <table id={`table${domKey}`} className="table table-hover table-centered table-nowrap mb-0 chart-table" 
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}>
                <thead className="chart-thead position-sticky" style={{ top: 0 }}>
                  {(isSearch[1] && isSearch[0] === domKey) || domKey === '99' ?
                    <></>
                    :
                    <>
                      {headers[0]['class'] === 'error-confirm' &&
                      <Button color="light" type="button" size="xs" className="position-absolute text-black font-size-12 pointer font-weight-bold" style={{right: 92, zIndex: 10, top: 8}} onClick={() => {onFixAll()}}>일괄 확인</Button>
                      }
                      <i className="mdi mdi-file-excel position-absolute text-white font-size-18 pointer" style={{right: 40, lineHeight: '48px', zIndex: 10}}
                        onClick={() => {
                          exportExcel()
                        }} />
                      <i className="mdi mdi-fullscreen position-absolute text-white font-size-24 pointer" style={{ right: 12, lineHeight: '48px', zIndex: 10 }}
                        onClick={() => {
                          fullScreen(domKey)
                        }} />
                      {headers[0]['class'] !== 'current-id' &&
                      <i className="mdi mdi-magnify position-absolute text-white font-size-22 pointer" style={{ right: 64, lineHeight: '48px', zIndex: 10 }}
                        onClick={() => {
                          setIsSearch([domKey, true])
                        }} />
                      }
                    </>
                  }
                  <tr>
                    <th scope="col" className="position-relative table-title font-size-16 text-start bg-dark-ash" colSpan={headers.length}>
                      {
                        isSearch[1] && isSearch[0] === domKey ?
                          <>
                          <div className="d-flex">
                            <select id={`select${domKey}`} className='dropdown-toggle waves-effect waves-light mb-2 waver-select-box' onChange={(e) => {
                              setType(e.target.value)
                            }}>
                              <option id={`option${domKey}`} value={headers[0].key} selected disabled hidden>{headers[0].value}</option>
                              {headers.length > 0 && headers.map((item, index) => {
                                return (
                                  <option key={item + index} value={item.key}>{item.value}</option>
                                )
                              })}
                            </select>
                            <input className="is-touched is-dirty av-valid form-control padding-right-56" placeholder="검색어 입력"
                              onClick={() => {
                                changeType()
                              }}
                              onChange={(e) => {
                                setSearchText(e.target.value)
                              }}
                              onKeyDown={(e) => {
                                if(e.key === 'Enter'){
                                  searchList()
                                }
                              }}/>
                            </div>
                            <i className="mdi mdi-magnify position-absolute text-black font-size-22 pointer noMargin x-button" style={{top: 14, right: 48}} onClick={(()=>{
                              searchList()
                            })} />
                            <p className="position-absolute text-black font-size-12 pointer noMargin x-button" style={{ top: 20, right: 18 }} onClick={() => {
                              setSearchText('')
                              setIsSearch(['0', false])
                            }}>X</p>
                          </>
                          :
                          title + ` (${dataList.length})`
                      }
                    </th>
                  </tr>
                  <tr>
                    {
                      headers.length > 0 && headers.map((v, i) => {
                        return (
                          <th style={{position: "relative", overflow: "inherit"}} key={title + i} scope="col" className={`${headers[0]['class'] + i} bg-dark-ash`}><p style={{wordBreak: "break-all", marginBottom: 0}}>{v.value}</p>
                          {i !== headers.length - 1 && 
                          <div style={{position: "absolute", right: -8, cursor: "col-resize", top: -16, height: '100%', zIndex: 100}}onMouseDown={(e)=>{
                            handleMouseDown(e)
                            setColIndex([headers[0]['class'], i])
                          }}>
                            <div style={{width: 16, height: 16, backgroundColor: "white", borderRadius: '50%', display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div className="bg-dark-ash" style={{width: 8, height: 8, borderRadius: '50%'}} />
                            </div>
                            <div style={{width: 2, height: '100%', backgroundColor: 'white', marginLeft: 7}} />
                          </div>}</th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody className="chart-tbody clickable">

                  {
                    dataList.length > 0 && dataList.map((item, index) => {
                      return (
                        <tr key={boardKey + index} onClick={(e) => { 
                          setDeviceId(item.id) }}>
                          {
                            headers && headers.map((v) => {
                              let text = item[v.key] || ''
                              if (typeof text === 'object') {
                                text = text.name || ''
                              }
                              if (text === '' && item.iot_device_model) {
                                text = item.iot_device_model[v.key]
                              }
                              if (v.key === 'created_at' || v.key === 'updated_at') {
                                text = moment(text).format('YYYY-MM-DD HH:mm:ss')
                              }
                              if (text === 'T') {
                                text = 'ON'
                              } else if(text ==='F') {
                                text = 'OFF'
                              } else {
                                Text = 'OFF'
                              }
                              
                              return (
                                v.key === 'fixErr' ?
                                <td><p style={{wordBreak: "break-all", marginBottom: 0}}> <Button onClick={()=>onFix(item.id)}>확인</Button></p></td> :
                                <td><p style={{wordBreak: "break-all", marginBottom: 0}}> {text}</p></td>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                  }
                  {dataList.length === 0 &&
                    <tr >
                      <td scope="row" colSpan={headers.length}>데이터가 없습니다.</td>
                    </tr>
                  }

                </tbody>
              </table>
            </div>

          </CardBody>
          {isLoading && playLoading()}
        </Card>
      }
    </>
  )

}

export default DefaultForm;
