import React, { useState } from "react"
import MetaTags from 'react-meta-tags';

import { Link, useLocation } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import { useSelector } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import EmitterSettlement from "../Bill/EmitterSettlement"
import EmitterPayment from "../Bill/EmitterPayment"
import TreatmentSettlement from "../Bill/TreatmentSettlement"
import TreatmentPayment from "../Bill/TreatmentPayment"
import CollectionSettlement from "../Bill/CollectionSettlement"
import CollectionPayment from "../Bill/CollectionPayment"
import ReceiptSettlement from "../Bill/ReceiptSettlement"
import ReceiptPayment from "../Bill/ReceiptPayment"

const style = { fontSize: 14 };

const clickedStyle = { backgroundColor: "#48C3B1", color: "#ffffff" };

const SettlementDetails = () => {
  const selector = useSelector(state => state)
  const location = useLocation()

  const [type, setType] = useState(selector.auth.user?.type || "")
  const [detailType, setDetailType] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.company_detail?.[0]?.type || "")
  const [collectionState, setCollectionState] = useState('collection')
  // 드롭다운
  const [settingMenu, setSettingMenu] = useState(false)
  const [settingMenu2, setSettingMenu2] = useState(false)
  const [settlementState, setSettlementState] = useState('settlement')
  const [companyId, setCompanyId] = useState(selector.auth?.user?.member_group?.[0]?.company_group.id);
  const [myCompany, setMyCompany] = useState(location?.state)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>정산/납부 내역 |  IoT Monitor</title>
        </MetaTags>
        <Container fluid>          
          {type !== 'collection' &&
            <>
              <Row>
                <Breadcrumbs maintitle="홈" title="청구서" breadcrumbItem="정산/납부 내역" />           
              </Row>        
              <Row>
                {!myCompany &&
                <Col sm={12}>                
                  <div className="float-start btn-group" style={{ margin: "26px 0 24px", height: 38 }}>
                    <Button                  
                      type="button"
                      size="sm"
                      className={settlementState === 'settlement' ? 'waves-effect custom-btn active' : 'custom-btn waves-effect'}                  
                      onClick={() => {
                        setSettlementState('settlement')
                      }}
                    >
                      정산내역
                    </Button>                
                    <Button                  
                      type="button"
                      size="sm"
                      className={settlementState === 'payment' ? 'waves-effect custom-btn active' : 'custom-btn waves-effect'}                  
                      onClick={() => {
                        setSettlementState('payment')
                      }}
                    >
                      납부내역
                    </Button>
                  </div>
                </Col>
                }
              </Row>          
              {settlementState === 'settlement' && type === 'emitter' && <EmitterSettlement />}
              {settlementState === 'payment' && type === 'emitter' && <EmitterPayment />}
              {settlementState === 'settlement' && type === 'treatment' && <TreatmentSettlement />}
              {settlementState === 'payment' && type === 'treatment' && <TreatmentPayment />}
            </>
          }
          {/* {type === 'emitter' && detailType === 'multi' && <MultiEmitter />} */}
          {type === 'collection' &&
            <>
              <Row>
                  <Breadcrumbs maintitle="홈" title="청구서" breadcrumbItem="정산상세내역" />           
                </Row>        
                <Row>                  
                  <Col sm={12}>                
                    <div className="float-start btn-group" style={{ margin: "26px 0 0", height: 38 }}>
                      <Button                  
                        type="button"
                        size="sm"
                        className={collectionState === 'settlement' ? 'waves-effect custom-btn active' : 'custom-btn waves-effect'}                  
                        onClick={() => {
                          setSettlementState('settlement')
                        }}
                      >
                        정산내역
                      </Button>                
                      <Button                  
                        type="button"
                        size="sm"
                        className={collectionState === 'payment' ? 'waves-effect custom-btn active' : 'custom-btn waves-effect'}                  
                        onClick={() => {
                          setSettlementState('payment')
                        }}
                      >
                        납부내역
                      </Button>
                      <Button                  
                        type="button"
                        size="sm"
                        className={collectionState === 'collection' ? 'waves-effect custom-btn active' : 'custom-btn waves-effect'}                  
                        onClick={() => {
                          setCollectionState('collection')
                        }}
                      >
                        수거내역
                      </Button>                
                      <Button                  
                        type="button"
                        size="sm"
                        className={collectionState === 'receipt' ? 'waves-effect custom-btn active' : 'custom-btn waves-effect'}                  
                        onClick={() => {
                          setCollectionState('receipt')
                        }}
                      >
                        입고내역
                      </Button>                      
                    </div>
                  </Col>                  
                </Row>          
              
              {collectionState === 'collection' && settlementState === 'settlement' && <CollectionSettlement />}
              {collectionState === 'collection' && settlementState === 'payment' && <CollectionPayment />}
              {collectionState === 'receipt' && settlementState === 'settlement' && <ReceiptSettlement />}
              {collectionState === 'receipt' && settlementState === 'payment' && <ReceiptPayment />}
            </>
          }

        </Container>
      </div>
    </React.Fragment>
  )
}

export default SettlementDetails
