import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import { useHistory } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import loading from "../../assets/images/loading.gif";

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

import Board from './Component/Board';

//i18n
import { withTranslation } from "react-i18next"
import { getAllIotDeviceHistoryList, getIotDeviceBatteryList, getIotDeviceList, getIotDeviceSolarList, getDeviceSearchList } from 'crud/auth.crud';
import AddDevice from './AddDevice';

const DeviceMain = props => {
  const history = useHistory()

  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState(0)
  const [dataList, setDataList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('id')
  const [searchType, setSearchType] = useState('')
  const [search, setSearch] = useState('')
  const [searchText, setSearchText] = useState('')
  const [batteryError, setBatteryError] = useState([])
  const [solarError, setSolarError] = useState([])
  const [centerMarker, setCenterMarker] = useState({ lat: 36.44699, lng: 127.09598 })
  const [screenList, setScreenList] = useState(0)

  const nextGetData = () => {
    setOnUpload(true)
    let newArr = []
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getAllDataList = async () => {
    try{
      const response1 = await getIotDeviceSolarList()
      const response2 = await getIotDeviceBatteryList()
      console.log(response1)
      if (response1?.data?.results?.length > 0){
        setSolarError(response1?.data?.results)
      }else{
        setSolarError(false)
      }
      if (response2?.data?.results?.length > 0){
        setBatteryError(response2?.data?.results)
      }else{
        setBatteryError(false)
      }
    } catch (error) {
      console.error(error);
    }
  }

  //수정해야함
  const getDataList = async () => {
    setOnUpload(true)
    try {      
      const params = {   
        search: search,     
        page: page,
        type : searchType, 
        order : order
      }
      console.log(params.page)
      console.log(params)
      const response = await getIotDeviceList(params);
      console.log("초기 데이터 >>>", response.data)
      
      let dataCount = response.data.count % 15 === 0 ? response.data.count / 15 : Math.floor(response.data.count / 15) + 1
      if (!contentCount || contentCount !== dataCount) {
        if(dataCount === 0){
          setContentCount(1)
        }else{
          setContentCount(dataCount)
        }
        let newArr = []
        for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
          if (i + 1 <= dataCount) {
            newArr.push(i + 1)
          }
        }
        if(newArr.length === 0) newArr = [1]
        setPageArr(newArr)
      }
      if (response.status === 200) {
        const content = response.data?.results || [];
        // console.log(content)
        if (content.length > 0) {
          console.log("in content ... :::", content)
          
          // for (let index = 0; index < content.length; index++) {
          //   content[index].date_joined = content[index].date_joined.substring(0,10)
          //   content[index].is_active = content[index].is_active === true ? "활성" : "비활성"
          // }

          setDataList([...content])          
        } else {
          setDataList(false)          
        }
        // 발주처 중복 제거
        // let filteredArr = []
        // let dupContent = [...content]
        // for(let i=0;i<dupContent.length;i++){
        //   if(filteredArr.indexOf(dupContent[i].company.name) === -1){
        //     filteredArr.push(dupContent[i].company.name)
        //   }else{
        //     dupContent.splice(i, 1)
        //     i -= 1
        //   }
        // }
        // for(let i=0;i<filteredArr.length;i++){
        //   filteredArr[i] = {company : filteredArr[i]}
        // }
        // if (filteredArr.length > 0) {
        //   setCompanyList([...filteredArr])
        // } else {
        //   setCompanyList(false)
        // }
        // console.log("발주처 목록 >>> ", filteredArr)
        //////
      }
    } catch (error) {
      console.error(error);
    }
    setOnUpload(false)
  };


  //수정해야함
  const getDataSearchList = async () => {
    setOnUpload(true)
    try {      
      const params = {   
        search: search,     
        page: page,
        type : searchType, 
        order : order
      }
      console.log(params.page)
      console.log(params)
      const response = await getDeviceSearchList(params);
      console.log("초기 데이터222 >>>", response.data)
      
      let dataCount = response.data.count % 15 === 0 ? response.data.count / 15 : Math.floor(response.data.count / 15) + 1
      if (!contentCount || contentCount !== dataCount) {
        if(dataCount === 0){
          setContentCount(1)
        }else{
          setContentCount(dataCount)
        }
        let newArr = []
        for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
          if (i + 1 <= dataCount) {
            newArr.push(i + 1)
          }
        }
        if(newArr.length === 0) newArr = [1]
        setPageArr(newArr)
      }
      if (response.status === 200) {
        const content = response.data?.results || [];
        // console.log(content)
        if (content.length > 0) {
          console.log("in content ... :::", content)
          
          // for (let index = 0; index < content.length; index++) {
          //   content[index].date_joined = content[index].date_joined.substring(0,10)
          //   content[index].is_active = content[index].is_active === true ? "활성" : "비활성"
          // }

          setDataList([...content])          
        } else {
          setDataList(false)          
        }
        // 발주처 중복 제거
        // let filteredArr = []
        // let dupContent = [...content]
        // for(let i=0;i<dupContent.length;i++){
        //   if(filteredArr.indexOf(dupContent[i].company.name) === -1){
        //     filteredArr.push(dupContent[i].company.name)
        //   }else{
        //     dupContent.splice(i, 1)
        //     i -= 1
        //   }
        // }
        // for(let i=0;i<filteredArr.length;i++){
        //   filteredArr[i] = {company : filteredArr[i]}
        // }
        // if (filteredArr.length > 0) {
        //   setCompanyList([...filteredArr])
        // } else {
        //   setCompanyList(false)
        // }
        // console.log("발주처 목록 >>> ", filteredArr)
        //////
      }
    } catch (error) {
      console.error(error);
    }
    setOnUpload(false)
  };

  const movePage = (id, company) => {
    console.log(id, company)
    history.push({ pathname: '/detail', state: { id: id } })
  }

  const createMarker = (id, company) => {
    let arr = []
    for(let i=0;i<dataList.length;i++){
      if(dataList[i]?.company?.name === company){
        arr.push(dataList[i])
      }
    }
    setMarkerList(arr)
    console.log(arr)
    let lat = []
    let lng = []
    for(let i=0;i<arr.length;i++){
      lat.push(arr[i].lat)
      lng.push(arr[i].lng)
    }
    const center = {lat: (Math.max(...lat)+Math.min(...lat))/2, lng: (Math.max(...lng)+Math.min(...lng))/2}
    if(centerMarker.lat === center.lat && centerMarker.lng === center.lng){
      center.lat = center.lat - 0.00001
      center.lng = center.lng - 0.00001
      setCenterMarker(center)
    }else{
      setCenterMarker(center)
    }
  }

  const fullScreen = (key) => {
    setScreenList(key)
    console.log('풀스크린', key)
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect( async () => {
    await getDataList()
    await getAllDataList()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          <div className="position-relative app-search d-none d-lg-block" style={{ padding: "calc(18px / 2) 0" }}>
                <input
                  // type="text"
                  className="form-control"
                  placeholder="검색어를 입력해주세요..."
                  onChange={(e) => {
                    setSearch(e.target.value.length == 0 ? '' : e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      getDataSearchList()
                    }
                  }}
                />
                <span onClick={ () => {
                  getDataSearchList()
                }} className="fa fa-search" style={{ lineHeight: '56px', cursor: 'pointer' }} />
              </div>
          <Row>
            {screenList === 0 && 
            <>
            
            <Col md={12}>
            <Board
                title={'단말 상태'}
                form={'post'}
                headers={[
                  {key : 'id', value: 'SN'},
                  {key : 'msn', value: 'MSN'},
                  {key : 'msisdn', value: 'MSISDN'},
                  {key : 'mac', value: 'mac 주소'},
                  {key : 'lte', value: 'lte id'},
                  {key : 'company', value: '발주처'},
                  {key : 'sign_content', value: '표지판 내용'},
                  {key : 'address', value: '주소'},
                  {key : 'updated_at', value: '업데이트 시간'},  
                ]} 
                dataList={dataList} 
                callback={movePage} 
                boardKey={'device'}
                page={page}
                height={'70vh'}
                setPage={setPage}
                pagesGroup={pagesGroup}
                setPagesGroup={setPagesGroup}
                pageArr={pageArr}
                contentCount={contentCount}
                domKey={4}
                fullScreen={fullScreen} />
            </Col>
            </>
            }
          </Row>
          
          <button
            className="btn btn-primary w-md waves-effect waves-light bg-dark-ash"
            type="submit"
            // onClick={() => { history.push({ pathname: '/deviceadd' }) }}
            onClick={() => { {alert("협의 후 사용가능한 기능입니다.")} }}
          >
            신규 기기 등록
          </button>
          
          {onUpload && 
            <div style={{
                position: "absolute",
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.6)',
                zIndex: 999,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img src={loading} alt="로딩중입니다." width={'5%'}></img>
            </div>
          }

        </Container>
      </div>
    </React.Fragment>
  )
}

DeviceMain.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(DeviceMain)
