import { filter } from "lodash";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import moment from 'moment';

import { useSelector } from "react-redux";

import { Link, useHistory } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import { retry } from "redux-saga/effects";
import { contract_writeResponse, contractWriteHistory, companyListCall, postBuyWrite } from "../../crud/auth.crud"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import images
import loading from "../../assets/images/loading.gif";
import index from "pages/Blog/BlogGrid";
import { hover } from "@testing-library/user-event/dist/hover";

const style = {backgroundColor: "#ffffff", padding: "0.3rem", cursor: "pointer", width: 240};

const hoverStyle = {backgroundColor: "#48C3B1", color: "#ffffff", width: 240};

const SellRequestWrite = () => {
  const selector = useSelector(state => state)

  const [title, setTitle] = useState('');
  const [rightBtnText, setRightBtnText] = useState('');
  const [rightBtnDisabled, setRightBtnDisabled] = useState(true);
  const [imgArr, setImgArr] = useState([]);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
  const [subjectToRecycling, setSubjectToRecycling] = useState('compost');
  const [id, setId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [onUpload, setOnUpload] = useState(false);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [qty, setQty] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [previewImages, setPreviewImages] = useState([])

  const subjectToRecyclingArr = [
    { 'key' : 'compost', 'text' : '퇴비'},
    { 'key' : 'feed', 'text' : '사료'},     
    { 'key' : 'wasteoil', 'text' : '폐유'},        
  ]

  const handleWrite = async () => {
    setOnUpload(true)

    try {
      console.log(imgArr)
        const fd = new FormData();

        for(let i=0 ;i<imgArr.length; i++){
          fd.append('file_'+i, imgArr[i]);
        }

        const companyData = selector.auth?.user?.member_group?.[0]?.company_group;
        const member = selector.auth?.user;
        const tradeData = {   
            company : companyData?.id,
            member : member.id,
            address : companyData.address1 + ' ' + companyData.address2 || '',
            subject : subject,
            content : content,
            product_type : subjectToRecycling,
            price_per_kg: parseInt(pricePerKg),
            // price : parseInt(price),
            qty : parseInt(qty),
            type : 'sell',
            state : 'N',
            status : 'Y',
        }            
        fd.append('trade_data', JSON.stringify(tradeData))

        for (let key of fd.keys()) {
          console.log(key, ">>>", fd.get(key));
        }
        const response = await postBuyWrite(fd);     
        console.log(response.data)       
        if(response.data?.result === 'success'){ 
          setOnUpload(false)
          alert(response.data.msg)
            // SimpleToast.show(response.data.msg, SimpleToast.SHORT);
            // this.props.route?.params?.handleTabIndex(0)                  
            // this.props.route?.params?.handleRefresh(true)
            // await this.props.navigation.navigate('TradeScreen', {
            //     refresh : true,                          
            // });
        }else{
          setOnUpload(false)
        }             
       
    } catch (e) {
      setOnUpload(false)
      console.log(e)
      if (e.response) console.log(e.response);
    }
  }

  const deleteImage = (index) => {
    let newImgArr = [...imgArr]
    console.log("imgArr ::", imgArr)
    console.log("newImgArr ::", [...imgArr])
    newImgArr.splice(index,1)
    setImgArr(newImgArr)
  }
  
  useEffect(()=>{
    setTotalPrice(pricePerKg * qty)
  }, [pricePerKg, qty])

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newPreviewImages = [];
    const readers = [];

    for (let i = 0; i < files.length; i++) {
      readers[i] = new FileReader();  

      readers[i].onload = (event) => {
        newPreviewImages[i] = event.target.result;
        setPreviewImages([...newPreviewImages]);
      };

      readers[i].readAsDataURL(files[i]);
    }
    console.log("이미지 미리보기 ::", readers)
  };

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
        <title>판매 등록 /  IoT Monitor</title>
      </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
          <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="판매 등록" />
          <Col sm={6}>
            <div className="float-end d-none d-md-block page-title-box">
            </div>
          </Col>
          </Row>

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              {/* <EmailSideBar /> */}
              <div className="email-rightbar mb-3">
                <Card>
                  <CardBody style={{padding: 48}}>
                  <div onClick={()=>{window.history.back()}}
                    style={{padding: '0.4rem 1rem', borderRadius: '0.2rem', marginBottom: 48, fontSize: 16}}
                    className="btn btn-primary dropdown-toggle waves-effect waves-light bg-success"
                  >뒤로가기
                  </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>제목</p>
                      <input className="is-untouched is-pristine av-invalid form-control" style={{width: 240}} onChange={(e) => {
                        if(e.target.value.length > 0){
                          setSubject(e.target.value);
                        }else{
                          setSubject('')
                        }
                      }} />
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35, alignItems: "center"}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>상품 종류</p>
                      {   
                      subjectToRecyclingArr && subjectToRecyclingArr.map((item, index) => { return (
                        <div className="form-check form-check-inline" key={item.key + '_' + index}>
                          <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name="subjectToRecycling" value={item.key} onChange={()=>{
                            setSubjectToRecycling(item.key)}} />
                          <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                        </div>
                      )})
                      }
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>Kg당 가격</p>
                      <input className="is-untouched is-pristine av-invalid form-control" style={{width: 132}} onChange={(e) => {
                        e.target.value.length == 0 ? setPricePerKg(0) : setPricePerKg(e.target.value);
                      }} />
                      <p style={{lineHeight: '35px'}}>&nbsp;원</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>판매량 (Kg)</p>
                      <input className="is-untouched is-pristine av-invalid form-control" style={{width: 132}} onChange={(e) => {
                        e.target.value.length == 0 ? setQty(0) : setQty(e.target.value);
                      }} />
                      <p style={{lineHeight: '35px'}}>&nbsp;Kg</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50, height: 35}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>총 가격</p>
                      <input className="is-untouched is-pristine av-invalid form-control" style={{width: 132}} value={(totalPrice)} disabled />
                      <p style={{lineHeight: '35px'}}>&nbsp;원</p>
                    </div>
                    <div style={{display: "flex", marginBottom: 50}}>
                      <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>상세 설명</p>
                      <textarea className="is-untouched is-pristine av-invalid form-control" style={{width: 480}} onChange={(e) => {
                        if(e.target.value.length > 0){
                          setContent(e.target.value);
                        }else{
                          setContent('')
                        }
                      }} />
                    </div>
                    <div style={{marginBottom: 50}}>
                      <div style={{display: "flex"}}>
                        <p style={{width: 110, color: '#9a9a9a', fontSize: 14, lineHeight: "35px"}}>상품 이미지</p>
                        <label className="btn btn-primary waves-effect waves-light" htmlFor="getFile" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                        <input style={{display: "none"}} type="file" id="getFile" accept="image/*" multiple="multiple" onChange={(e) => {
                          handleFileChange(e)
                          setImgArr(e.target.files)
                          console.log(e.target.files)
                        }} />
                      </div>
                      <div style={{width: 1000}}>
                      {previewImages.length > 0 && previewImages.map((image, index) => (
                        <div key={index} style={{width: "fit-content", width: 600, position: "relative", marginBottom: 24}}>
                          <img style={{width: 600, fontSize: 16, lineHeight: '35px', padding: 3, border: "1.5px solid #48C3B1"}} src={image} />
                          <p style={{position: "absolute", top: 12, right: 24, cursor: "pointer"}} onClick={()=>{
                            deleteImage(index)
                            setPreviewImages(previewImages.filter(v => v !== image))
                          }}>X</p>
                        </div>
                      ))
                      }
                      </div>
                    </div>
                    
                    <Button
                      type="button"
                      color="warning"
                      style={{padding: '0.4rem 1.75rem', borderRadius: '0.2rem'}}
                      onClick={() => {
                        handleWrite()
                      }}
                      >
                      등록하기
                    </Button>
                  </CardBody>
                  
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

export default SellRequestWrite
