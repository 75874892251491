import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import { useHistory } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import loading from "../../assets/images/loading.gif";

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

import Board from './Component/Board';

//i18n
import { withTranslation } from "react-i18next"
import { getHistoryList, getHistorySearchList } from 'crud/auth.crud';

const HistoryMain = props => {
  const history = useHistory()
  const eventPage = localStorage.getItem('event_page')
  const eventSearch = localStorage.getItem('event_search')
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState(0)
  const [dataList, setDataList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('id')
  const [searchType, setSearchType] = useState('')
  const [search, setSearch] = useState('')
  const [searchText, setSearchText] = useState('')
  const [batteryError, setBatteryError] = useState([])
  const [solarError, setSolarError] = useState([])
  const [centerMarker, setCenterMarker] = useState({ lat: 36.44699, lng: 127.09598 })
  const [screenList, setScreenList] = useState(0)

  const [searchStatus, setSearchStatus] = useState(false)
  const [backStatus, setBackStatus] = useState(false)

  const nextGetData = () => {
    setOnUpload(true)
    let newArr = []
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    setPageArr(newArr)
    setOnUpload(false)
  }

  // const getAllDataList = async () => {
  //   try{
  //     const response1 = await getIotDeviceSolarList()
  //     const response2 = await getIotDeviceBatteryList()
  //     console.log(response1)
  //     if (response1?.data?.results?.length > 0){
  //       setSolarError(response1?.data?.results)
  //     }else{
  //       setSolarError(false)
  //     }
  //     if (response2?.data?.results?.length > 0){
  //       setBatteryError(response2?.data?.results)
  //     }else{
  //       setBatteryError(false)
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const getDataList = async () => {
    setOnUpload(true)
    try {      
      const params = {   
        search: search,     
        page: page,
        type : searchType, 
        order : order
      }
      console.log(params.page)
      console.log(params)
      const response = await getHistoryList(params);
      console.log("초기 데이터 >>>", response.data)
      
      let dataCount = response.data.count % 15 === 0 ? response.data.count / 15 : Math.floor(response.data.count / 15) + 1
      if (!contentCount || contentCount !== dataCount) {
        if(dataCount === 0){
          setContentCount(1)
        }else{
          setContentCount(dataCount)
        }
        let newArr = []
        for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
          if (i + 1 <= dataCount) {
            newArr.push(i + 1)
          }
        }
        if(newArr.length === 0) newArr = [1]
        setPageArr(newArr)
      }
      if (response.status === 200) {
        const content = response.data?.results || [];
        // console.log(content)
        if (content.length > 0) {
          console.log("in content ... :::", content)
          
          // for (let index = 0; index < content.length; index++) {
          //   content[index].date_joined = content[index].date_joined.substring(0,10)
          //   content[index].is_active = content[index].is_active === true ? "활성" : "비활성"
          // }

          setDataList([...content])          
        } else {
          setDataList(false)          
        }
        // 발주처 중복 제거
        // let filteredArr = []
        // let dupContent = [...content]
        // for(let i=0;i<dupContent.length;i++){
        //   if(filteredArr.indexOf(dupContent[i].company.name) === -1){
        //     filteredArr.push(dupContent[i].company.name)
        //   }else{
        //     dupContent.splice(i, 1)
        //     i -= 1
        //   }
        // }
        // for(let i=0;i<filteredArr.length;i++){
        //   filteredArr[i] = {company : filteredArr[i]}
        // }
        // if (filteredArr.length > 0) {
        //   setCompanyList([...filteredArr])
        // } else {
        //   setCompanyList(false)
        // }
        // console.log("발주처 목록 >>> ", filteredArr)
        //////
      }
    } catch (error) {
      console.error(error);
    }
    setOnUpload(false)
  };

  const getDataSearchList = async () => {
    setOnUpload(true)
    setSearchStatus(true)
    
    try {    
      
      if(search === '') {
        console.log("empty")
        setSearchStatus(false)
        getDataList()
        return
      }

      const params = {   
        search: search,     
        page: page,
        type : searchType, 
        order : order
      }
      console.log(params.page)
      console.log(params)
      const response = await getHistorySearchList(params);
      console.log("초기 데이터222 >>>", response.data)
      
      if (!contentCount || contentCount !== Math.floor(response.data?.count / 15) + 1) {
        setContentCount(Math.floor(response.data.count / 15) + 1)
        let newArr = []
        for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
          if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
            newArr.push(i + 1)
          }
        }
        setPageArr(newArr)
      }
      if (response.status === 200) {
        const content = response.data?.results || [];
        // console.log(content)
        if (content.length > 0) {
          console.log("in content ... :::", content)
          
          // for (let index = 0; index < content.length; index++) {
          //   console.log(content[index])
          //   if(content[index].date_joined === undefined){
          //     content[index].date_joined = "1990-01-01 00:00:00"
          //   }
          //   content[index].date_joined = content[index].date_joined.substring(0,10)
          //   content[index].is_active = content[index].is_active === true ? "활성" : "비활성"
          // }

          setDataList([...content])          
        } else {
          setDataList(false)          
        }
        // 발주처 중복 제거
        // let filteredArr = []
        // let dupContent = [...content]
        // for(let i=0;i<dupContent.length;i++){
        //   if(filteredArr.indexOf(dupContent[i].company.name) === -1){
        //     filteredArr.push(dupContent[i].company.name)
        //   }else{
        //     dupContent.splice(i, 1)
        //     i -= 1
        //   }
        // }
        // for(let i=0;i<filteredArr.length;i++){
        //   filteredArr[i] = {company : filteredArr[i]}
        // }
        // if (filteredArr.length > 0) {
        //   setCompanyList([...filteredArr])
        // } else {
        //   setCompanyList(false)
        // }
        // console.log("발주처 목록 >>> ", filteredArr)
        //////
      }
    } catch (error) {
      console.error(error);
    }
    setOnUpload(false)
  };

  const movePage = (id, iot_device_id) => {
	localStorage.setItem('event_page', page)
	localStorage.setItem('event_search', search)
    history.push({ pathname: '/detail', state: { id: iot_device_id } })
  }


  const fullScreen = (key) => {
    setScreenList(key)
    console.log('풀스크린', key)
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect( async () => {
	const eventPage = localStorage.getItem('event_page')
    const eventSearch = localStorage.getItem('event_search')
	if(eventPage || eventSearch){
	  if(eventSearch){
		  setSearchStatus(true)
		  setSearch(eventSearch)
	  }
	  if(eventPage != 1){
		  setPage(parseInt(eventPage))
	  }else{
		  setBackStatus(true)
	  }
	  localStorage.setItem('event_page', '')
	  localStorage.setItem('event_search', '')		    	  
	}else{
		if(searchStatus){
		  await getDataSearchList()
		} else {
		  await getDataList()
		}
	}    
    
    // await getAllDataList()
  }, [page])

  useEffect( async () => {
	if(backStatus){
		if(searchStatus){
		  await getDataSearchList()
		} else {
		  await getDataList()
		}
	}	
  }, [backStatus])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          <div className="position-relative app-search d-none d-lg-block" style={{ padding: "calc(18px / 2) 0" }}>
                <input
                  // type="text"
                  className="form-control"
                  placeholder="검색어를 입력해주세요..."
				  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value.length == 0 ? '' : e.target.value)
                    console.log(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      getDataSearchList()
                    }
                  }}
                />
                <span onClick={ () => {
                  getDataSearchList()
                }} className="fa fa-search" style={{ lineHeight: '56px', cursor: 'pointer' }} />
              </div>
          <Row>
            {screenList === 0 && 
            <>
            
            <Col md={12}>
            <Board
                title={'기기 이벤트'}
                form={'post'}
                headers={[
                  { key: 'updated_at', value: '이벤트시간' },
                  { key: 'iot_device_id', value: 'SN' },
                  { key: 'iot_device_mac', value: 'UUID' },
                  { key: 'solar_voltage', value: '쏠라전압' },
                  { key: 'solar_reference_voltage', value: '쏠라기준전압' },
                  { key: 'battery_voltage', value: '배터리전압' },
                  { key: 'dimming_value', value: '디밍값' },
                  { key: 'event_type', value: 'LED상태' },
                  { key: 'led_1', value: 'LED 1' },
                  { key: 'led_2', value: 'LED 2' },
                  { key: 'led_3', value: 'LED 3' },
                  // { key: 'led_4', value: 'LED 4' },
                  { key: 'channel_output', value: 'LED 총전류값' },
                  { key: 'lat', value: '위도' },
                  { key: 'lon', value: '경도' },
                  { key: 'temp', value: '온도' },
                ]} 
                dataList={dataList} 
                callback={movePage} 
                boardKey={'device'}
                page={page}
                height={'70vh'}
                setPage={setPage}
                pagesGroup={pagesGroup}
                setPagesGroup={setPagesGroup}
                pageArr={pageArr}
                contentCount={contentCount}
                domKey={5}
                fullScreen={fullScreen} />
            </Col>
            </>
            }
          </Row>
          
          {onUpload && 
            <div style={{
                position: "absolute",
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.6)',
                zIndex: 999,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img src={loading} alt="로딩중입니다." width={'5%'}></img>
            </div>
          }

        </Container>
      </div>
    </React.Fragment>
  )
}

HistoryMain.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(HistoryMain)
