import React from "react"
import MetaTags from 'react-meta-tags';

import { Link, useHistory } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const BusinessListScreen = () => {

  const history = useHistory()

  const dataList = [
    {id : "14256", company : "로픽원테크놀로지스유한회사", represent : "홍길동", amount : "300kg 미만", date : "월, 금"},
    {id : "14256", company : "로픽원테크놀로지스유한회사", represent : "홍길동", amount : "300kg 미만", date : "월, 금"},
    {id : "14256", company : "로픽원테크놀로지스유한회사", represent : "홍길동", amount : "300kg 미만", date : "월, 금"},
    {id : "14256", company : "로픽원테크놀로지스유한회사", represent : "홍길동", amount : "300kg 미만", date : "월, 금"},
    {id : "14256", company : "로픽원테크놀로지스유한회사", represent : "홍길동", amount : "300kg 미만", date : "월, 금"},
    {id : "14256", company : "로픽원테크놀로지스유한회사", represent : "홍길동", amount : "300kg 미만", date : "월, 금"},
  ]

  const clickList = (item) => {
    const url = {
      pathname : `/business-management-screen`,
      state : {...item}
    }
    history.push(url);
  }

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
        <title>사업장 관리 /  IoT Monitor</title>
      </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="사업장 관리" />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              {/* <EmailSideBar /> */}
              <div className="email-rightbar mb-3">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">사업장 목록</h4>
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col" style={{display: 'flex'}}>(#) <p style={{fontWeight: 600}}>ID</p></th>
                            <th scope="col" style={{fontWeight: 600}}>사업장명</th>
                            <th scope="col" style={{fontWeight: 600}}>대표자명</th>
                            <th scope="col" style={{fontWeight: 600}}>일배출량</th>
                            <th scope="col" style={{fontWeight: 600}}>희망수거일</th>
                            {/* <th scope="col" colSpan="2">
                              Status
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>

                        {
                        dataList && dataList.map((item, index) => { return (
                          <tr key={item.id + '_' + index}>
                            <th scope="row">{item.id}</th>
                            <td style={{cursor: "pointer"}} onClick={() => {
                              clickList(item)
                              }}>{item.company}</td>
                            <td>{item.represent}</td>
                            <td>{item.amount}</td>
                            <td>{item.date}</td>
                          </tr>
                        )})
                        }
                        
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
                <Row>
                  <Col xs="7">전체 {'1,524'}개 중 {'1 - 20'}개</Col>
                  <Col xs="5">
                    <div className="btn-group float-end">
                      <Button
                        type="button"
                        color="success"
                        size="sm"
                        className="waves-effect"
                      >
                        <i className="fa fa-chevron-left"/>
                      </Button>
                      <Button
                        type="button"
                        color="success"
                        size="sm"
                        className="waves-effect"
                      >
                        <i className="fa fa-chevron-right"/>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BusinessListScreen
