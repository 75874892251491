import React, { useEffect, useState } from "react"
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import store from "store";

class Demand extends React.PureComponent {

    constructor(props) {
        super(props);        
        this.state = {
            vehicles : [],
            subjectToRecycling : '',
            recyclingPurpose : '',
            recycle : [],
            businessPartners : '',
            // businessPartnersFile: undefined,
            facility1 : '',
            facility2 : '',
            special5 : '',
            cargo35 : '',
            cargo12 : '',
            compostamount : '',
            feedamount : '',
            wasteoilamount : '',
        }

        const code = store.getState().Data?.code[0] || []
        this.vehicles = code.filter(item => item.code === 'vehicles')
        this.subjectToRecycling = code.filter(item => item.code === 'subjectToRecycling')
        this.recyclingPurpose = code.filter(item => item.code === 'recyclingPurpose')
        this.recycles = code.filter(item => item.code === 'recycles')
        this.businessPartners = code.filter(item => item.code === 'businessPartners')
    }

    validateAddCompany = () => {
    }


    handleError = (err) => {
        if (DocumentPicker.isCancel(err)) {
            console.warn('cancelled')
            // User cancelled the picker, exit any dialogs or menus and move on
        } else if (isInProgress(err)) {
            console.warn('multiple pickers were opened, only the last will be considered')
        } else {
            throw err
        }
    }

    openFilePicker= async (str) => {
        try {
          const pickerResult = await DocumentPicker.pickSingle({
            presentationStyle: 'fullScreen',
            //copyTo: 'cachesDirectory',      
            type: [types.xls, types.csv, types.xlsx]
          })
          console.log(pickerResult)
          this.setState({[str] : {...pickerResult}})          
        } catch (e) {
          this.handleError(e)
        }
    }

    deleteFile = (str) =>{
        this.setState({ [str] : undefined})
    }    

    isValiddate = () => {
        
        for(let i=0; i<this.state.vehicles.length; i++){
            const item = this.state.vehicles[i];
            if(this.state[item] === ''){
                // alert(item.text.replace("(", "")+" 수량을 입력해주세요.")
                alert("보유차량 수를 입력해주세요.")
                return("")
            }
        }

        if(!this.state.subjectToRecycling){
            alert("재활용 대상 폐기물을 선택해주세요.")
            return("")
        }

        if(this.state.recyclingPurpose === ''){
            alert("재활용 용도를 선택해주세요.")
            return("")
        }
        
        for(let i=0; i<this.state.recycle.length; i++){
            const item = this.state.recycle[i];
            if(this.state[item] === ''){
                // alert("처리실적 "+ item.text.replace("(", "")+" 값을 입력해주세요.")
                alert("재생원료 구매실적을 입력해주세요.")
                return("")
            }
        }

        return "success"
    }

    ifVehiclesUnchecked = (e) => {
        this.setState({vehicles : this.state.vehicles.filter(item => item !== e.target.id), [e.target.id] : ''})                          
    }

    ifRecyclesUnchecked = (e) => {
        this.setState({recycle : this.state.recycle.filter(item => item !== e.target.id), [e.target.id] : ''})                          
    }

    // registFiles = (e) => {
    //     this.setState({businessPartnersFile : [...e.target.files]})
    // }

    // deleteFile = () => {
    //     this.setState({businessPartnersFile : []})
    // }

    render() {
        return (
        <>
            <div className="mb-3">
                <legend>보유차량 수</legend>
            {   
            this.vehicles && this.vehicles.map((item, index) => { return (
                <div className="form-check form-check-inline" style={{display: "flex"}} key={item.key + '_' + index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='checkbox' id={item.key} name="vehicles" value={item.key} onChange={(e)=>{   
                            e.target.checked ? this.setState({vehicles : [ ...this.state.vehicles, item.key]}) : this.ifVehiclesUnchecked(e)
                        }}
                    />
                    <label className="form-check-label" htmlFor={item.key} style={{marginLeft: '0.5rem'}}>{item.text}</label>
                    <p style={{fontWeight: 200, lineHeight: 1.5}}>{' ('}</p>
                    <input style={{width: 64, height: 23, padding: 6, marginLeft: '0.5rem', marginRight: 6}} className="form-control" value={this.state[item.key]} disabled={this.state.vehicles.indexOf(item.key) > -1 ? false : "disabled"}
                        onChange={(e)=>{
                            this.setState({[item.key] : e.target.value})
                        }}/>
                    <p style={{fontWeight: 200, lineHeight: 1.5}}>대)</p>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <legend>재활용 대상 폐기물</legend>
            {   
            this.subjectToRecycling && this.subjectToRecycling.map((item, index) => { return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name="subjectToRecycling" value={item.key} onChange={()=>{this.setState({subjectToRecycling : item.key})}} />
                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <legend>재활용의 용도</legend>
            {   
            this.recyclingPurpose && this.recyclingPurpose.map((item, index) => { return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name="recyclingPurpose" value={item.key} onChange={()=>{this.setState({recyclingPurpose : item.key})}} />
                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <p className="noMargin" style={{fontSize: 16, color: 'black', marginTop: 12}}>폐기물 보관시설 보유현황</p>
                <textarea className="form-control" style={{marginBottom: '2rem'}} placeholder="보유하고 있는 시설을 입력해주세요." onChange={(e)=>{this.setState({facility1 : e.target.value })}}></textarea>
            </div>

            <div className="mb-3">
                <legend>재생원료 구매 실적</legend>
            {   
            this.recycles && this.recycles.map((item, index) => { return (
                <div className="form-check form-check-inline" style={{display: "flex"}} key={item.key + '_' + index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='checkbox' id={item.key} name="recycles" value={item.id} onChange={(e)=>{   
                        e.target.checked ? this.setState({recycle : [ ...this.state.recycle, item.key]}) : this.ifRecyclesUnchecked(e)
                    }} />
                    <label className="form-check-label" htmlFor={item.key} style={{marginLeft: '0.5rem'}}>{item.text}</label>
                    <p style={{fontWeight: 200, lineHeight: 1.5}}>{' ('}</p>
                    <input style={{width: 64, height: 23, padding: 6, marginLeft: '0.5rem', marginRight: 6}} className="form-control" value={this.state[item.key]} disabled={this.state.recycle.indexOf(item.key) > -1 ? false : "disabled"} onChange={(e)=>{
                        this.setState({[item.key] : e.target.value})
                    }}></input>
                    <p style={{fontWeight: 200, lineHeight: 1.5}}>Ton)</p>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <legend>거래처 수</legend>
            {   
            this.businessPartners && this.businessPartners.map((item, index) => { return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name="businessPartners" value={item.key} onChange={()=>{this.setState({businessPartners : item.key})}} />
                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
            )})
            }
            </div>

            {/* <div>
                <div className="mb-3">
                    <p className="noMargin" style={{fontSize: 18, color: 'black', marginTop: 12}}>거래처 리스트 업로드</p>
                </div>
                <div className="mb-3">
                    <p style={{marginBottom: 6, color: "black", fontSize: 15}}>- 처리장/대표자명/주소/연락처 포함 필수</p>
                    <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                    <input style={{display: "none"}} type="file" accept=".xls,.xlsx" id="ex_file" onChange={(e)=>{
                        this.registFiles(e)
                    }}/> 
                    {this.state.businessPartnersFile?.length > 0 && this.state.businessPartnersFile.map((item, index) => {
                    return (
                        <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                        <p className="noMargin" style={{paddingLeft: 6}}>{item.name}</p>
                        <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                            this.deleteFile()
                        }}>X</p>
                        </div>
                    )})}
                </div>
            </div> */}

            <div className="mb-3 row">
                <div className="col-12 text-end">
                    <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                    onClick={()=>{
                        if(this.isValiddate() === 'success'){
                        this.props.handlePress({
                        ...this.state
                    })}}}
                    >                        
                        회원가입 완료하기
                    </button>
                </div>
            </div>
        </>
        );
    }
};

export default Demand;


