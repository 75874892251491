import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"
import { useSelector } from 'react-redux';

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const localItem = localStorage.getItem('user_type')
  let myIdJson = localStorage.getItem('persist:profile') || null
  if (myIdJson) {
    myIdJson = JSON.parse(myIdJson)?.auth || null
  }
  if (myIdJson) {
    myIdJson = JSON.parse(myIdJson)
  }
  const [myId, setMyId] = useState(myIdJson?.[0]?.id || '')
  const selector = useSelector(state => state)
  // const [userType, setUserType] = useState(selector.Profile?.auth?.[0]?.data)
  const [userType, setUserType] = useState(localItem)
  console.log("usertype", userType)

  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        // console.log(pathName, items[i].pathname)
        if (pathName.indexOf(items[i].pathname) > -1 && items[i].pathname !== '/') {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect"
                onClick={(e) => {
                  if (window.location.pathname === "/dashboard") {
                    e.preventDefault(); // 기본 동작 방지
                    window.location.reload(); // 새로고침
                  }
                }}
              >
                <i className="ti-home"></i>
                <span>{props.t("홈 화면")}</span>
              </Link>
              {userType !== 'factory' &&
                <>
                  {/* <Link to="/companyadd" className="waves-effect">
                  <i className="ti-file"></i>
                  <span>{props.t("발주처 추가")}</span>
                </Link> */}
                  <Link to="/deviceadd" className="waves-effect"
                    onClick={(e) => {
                      if (window.location.pathname === "/deviceadd") {
                        e.preventDefault(); // 기본 동작 방지
                        window.location.reload(); // 새로고침
                      }
                    }}
                  >
                    <i className="ti-harddrives"></i>
                    <span>{props.t("단말정보수정")}</span>
                  </Link>
                  <Link to="/errormain" className="waves-effect"
                    onClick={(e) => {
                      if (window.location.pathname === "/errormain") {
                        e.preventDefault(); // 기본 동작 방지
                        window.location.reload(); // 새로고침
                      }
                    }}
                  >
                    <i className="ti-notepad"></i>
                    <span>{props.t("오류 단말")}</span>
                  </Link>
                  {/* <Link to="/historymain" className="waves-effect">
                  <i className="ti-files"></i>
                  <span>{props.t("이벤트 조회")}</span>
                </Link>

                
                <Link to="/gearingmain" className="waves-effect mt-4">
                  <i className="ti-layout-accordion-merged"></i>
                  <span>{props.t("기기 연동")}</span>
                </Link> */}

                  {/* <Link to="/historymain" className="waves-effect">
                  <i className="ti-files"></i>
                  <span>{props.t("이벤트 조회")}</span>
                </Link>
                <Link to="/controlidmain" className="waves-effect">
                  <i className="ti-harddrives"></i>
                  <span>{props.t("기기 컨트롤")}</span>
                </Link> */}


                </>
              }

              {userType === 'factory' &&
                <>
                  {/* <Link to="/historymain" className="waves-effect">
                <i className="ti-files"></i>
                <span>{props.t("이벤트 조회")}</span>
              </Link> */}
                  <Link to="/controlidmain" className="waves-effect"
                    onClick={(e) => {
                      if (window.location.pathname === "/controlidmain") {
                        e.preventDefault(); // 기본 동작 방지
                        window.location.reload(); // 새로고침
                      }
                    }}
                  >
                    <i className="ti-harddrives"></i>
                    <span>{props.t("단말기컨트롤")}</span>
                  </Link>
                </>
              }

              {userType === 'admin' &&
                <>
                  <Link to="/historymain" className="waves-effect"
                    onClick={(e) => {
                      if (window.location.pathname === "/historymain") {
                        e.preventDefault(); // 기본 동작 방지
                        window.location.reload(); // 새로고침
                      }
                    }}
                  >
                    <i className="ti-files"></i>
                    <span>{props.t("이벤트 조회")}</span>
                  </Link>
                  <Link to="/historyservmain" className="waves-effect"
                    onClick={(e) => {
                      if (window.location.pathname === "/historyservmain") {
                        e.preventDefault(); // 기본 동작 방지
                        window.location.reload(); // 새로고침
                      }
                    }}
                  >
                    <i className="ti-files"></i>
                    <span>{props.t("서버 이벤트 조회")}</span>
                  </Link>
                  <Link to="/controlidmain" className="waves-effect"
                    onClick={(e) => {
                      if (window.location.pathname === "/controlidmain") {
                        e.preventDefault(); // 기본 동작 방지
                        window.location.reload(); // 새로고침
                      }
                    }}
                  >
                    <i className="ti-harddrives"></i>
                    <span>{props.t("단말기컨트롤")}</span>
                  </Link>
                  <Link to="/usermain" className="waves-effect mt-4"
                    onClick={(e) => {
                      if (window.location.pathname === "/usermain") {
                        e.preventDefault(); // 기본 동작 방지
                        window.location.reload(); // 새로고침
                      }
                    }}
                  >
                    <i className="ti-menu"></i>
                    <span>{props.t("사용자 관리")}</span>
                  </Link>
                </>
              }
              {userType !== 'admin' &&
                <Link
                  to={{
                    pathname: "/userdetail",
                    state: { id: myId }, // 여기서 state를 추가
                  }}
                  className="waves-effect mt-4"
                >
                  <i className="ti-menu"></i>
                  <span>{props.t("내정보 관리")}</span>
                </Link>
              }
              <Link to="/logout" className="waves-effect mt-4">
                <i className="ti-plug"></i>
                <span>{props.t("로그아웃")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))