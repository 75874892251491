import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import store from 'store';
import { SET_EXCHANGE_STATE } from 'store/auth/data/actionTypes';
import { useSelector } from 'react-redux';

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next"
import SellList from './SellList';
import BuyList from './BuyList';

const PlatformProductScreen = () => {
  const selector = useSelector(state=>state)
  const [exchangeState, setExchangeState] = useState(selector.Data.exchangeState)

  const platformChange = async(newState) => {
    console.log(newState)
    await store.dispatch({ type: SET_EXCHANGE_STATE, data: newState})
    setExchangeState(newState)
  }

  return (
    <React.Fragment>

      {exchangeState === 'sell' && <SellList stateChange={platformChange} />}
      {exchangeState === 'buy' && <BuyList stateChange={platformChange} />}

    </React.Fragment>
  )
}

PlatformProductScreen.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(PlatformProductScreen)
