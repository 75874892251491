import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import LightData from "./LightData"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { MapMarker, Map } from "react-kakao-maps-sdk";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { tmsCompanyResponse, tmsResponse } from "../../crud/auth.crud"

import moment from 'moment';

// 이미지
import driver from "../../assets/images/driver.png";
import truck from "../../assets/images/truck.png"

const LoadingContainer = () => <div>Loading...</div>

const TMS = () => {
  const selector = useSelector(state => state)

  const [type, setType] = useState(selector.auth.user?.type || "")
  const [company, setCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group.id)
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00',)
  const [positions, setPositions] = useState([])
  const [lat, setLat] = useState()
  const [lng, setLng] = useState()
  const [collectList, setCollectList] = useState([])

  const getDataList = async () => {
    let params = {
      company: company,
      date: date,
    }
    if(type === 'emitter'){
      const param = {
        company : company,            
      }
      const response = await tmsCompanyResponse(param.company)        
      params = {
        company : response.data?.data?.[0]?.[0]?.company,
        date : date,
      }
    }
    const rs = await tmsResponse(params)
    const tmp = []
    const tmp2 = []
    if (rs.data?.result === 'success') {
      console.log(rs.data)
      for (let i = 0; i < rs.data?.data.length; i++) {
        let data = rs.data?.data[i];
        let item = {
          lat: parseFloat(data.x),
          lng: parseFloat(data.y),
          markerName: data.id + ''
        }
        tmp.push(item)
        let list = { company: data.company_data.name, create: data.created_at.substr(11, 8) }
        tmp2.push(list)
      }
    }
    setCollectList(tmp2)
    setPositionList(tmp)
  }

  const setPositionList = (tmp) => {
    let arr = []
    let latSum = 0
    let lngSum = 0
    for (let i = 0; i < tmp.length; i++) {
      let val = tmp[i]
      arr.push({ title: val.markerName, latlng: { lat: val.lng, lng: val.lat } })
      latSum += val.lng
      lngSum += val.lat
    }
    setPositions(arr)
    setLat(latSum / tmp.length)
    setLng(lngSum / tmp.length)
    console.log(latSum / tmp.length, lngSum / tmp.length)
  }

  useEffect(() => {
    getDataList()
    const interval = setInterval(() => {
      getDataList()
    }, 15000);
    return () => clearInterval(interval)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TMS |  IoT Monitor</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="홈" breadcrumbItem="TMS" breadcrumbHeaderItem='실시간 차량 이동정보 확인'/>          
          <Row>
            <Col lg={8} style={{ margin: '0px auto' }}>
              <Card>
                <CardBody>                    
                  <div
                    id="gmaps-markers"
                  >
                    <Map
                      center={{ lat: lat ? lat : 33.450936, lng: lng ? lng : 126.569477 }}
                      style={{ width: "100%", height: "100%" }}
                    >
                      {positions.map((position) => (
                        <MapMarker
                          key={`${position.title}-${position.latlng}`}
                          position={position.latlng}
                          title={position.title}
                          image={{
                            src : truck,
                            size: {
                              width: 64,
                              height: 64
                            }
                          }}
                        />
                      ))}
                    </Map>
                  </div>

                </CardBody>
              </Card>
            </Col>
            <Col lg={4} style={{ margin: '0px auto' }}>
              <Card>
                <CardBody style={{padding: 0, borderRadius: 4, overflow: 'hidden'}}>   
                    <div className="table-responsive" style={{maxHeight: 'calc(100vh - 265px)', overflowY : 'auto'}}>
                      <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                        <thead className="chart-thead">
                          <tr>                            
                            <th scope="col" >수거장소</th>
                            <th scope="col" >수거시각</th>                            
                          </tr>
                        </thead>
                        <tbody className="chart-tbody">
                          {collectList && collectList.map((item, index) => {
                            return (
                              <tr key={item.id + '_' + index}>
                                <td>{item.company}</td>
                                <td>{item.create}</td>                                
                              </tr>
                            )
                          })
                          }                          
                          {collectList.length === 0 &&
                            <tr >
                              <td colSpan="2">데이터가 없습니다.</td>                              
                            </tr>
                          }

                        </tbody>
                      </table>
                    </div>              

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

TMS.propTypes = {
  google: PropTypes.object
}

export default connect(
  null,
  {}
)
  (
    GoogleApiWrapper({
      apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
      LoadingContainer: LoadingContainer,
      v: "3",
    })(TMS)
  )
