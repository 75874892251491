import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container, Button } from "reactstrap"
import { useSelector } from "react-redux";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

import { getProcessList } from "../../crud/auth.crud"

import moment from 'moment';
import Util from "pages/Dashboard/Common/Util";

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

// 주소 찾기
import DaumPostcode from 'react-daum-postcode';

const ProcessHistory = props => {
  const selector = useSelector(state => state)
  const newDate = new Date()
  const nextDate = new Date()

  const [user, setUser] = useState(selector.auth?.user);
  const [dataList, setDataList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [pricePerL, setPricePerL] = useState(50);
  const [companyId, setCompanyId] = useState(selector.auth?.user?.member_group?.[0]?.company_group.id);
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [novelDate, setnovelDate] = useState(new Date());
  const [date, setDate] = useState(moment(newDate).format('YYYY.MM.DD'));
  const [dateText, setDateText] = useState(moment(newDate).format("MM/DD"));
  const [year, setYear] = useState(parseInt(moment(newDate).format("YYYY")) + '');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState(moment(newDate).day());
  const [dayText, setDayText] = useState(Util.getDayText(moment(newDate).day()));
  const [startDate, setStartDate] = useState(moment(newDate).format('YYYY-MM-DD') + ' 00:00:00');
  const [endDate, setEndDate] = useState(moment(new Date(newDate.setDate(newDate.getDate() + 1))).format('YYYY-MM-DD') + ' 00:00:00');
  const [checkedDateType, setCheckedDateType] = useState('');
  const [yearModalShow, setYearModalShow] = useState(false);
  const [monthModalShow, setMonthModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState()

  // 드롭다운 map용
  const yearSelect = [
    moment(newDate).format("YYYY") - 3, moment(newDate).format("YYYY") - 2, moment(newDate).format("YYYY") - 1, moment(newDate).format("YYYY")
  ]
  const monthSelect = [
    ...Array(12).keys()
  ].map(x => x + 1)

  const nextGetData = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      console.log("페이지 일치 여부", page, i + 1)
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {
    setOnUpload(true)
    try {
      let thisMonth = month < 10 ? '0' + month : month;
      let start_ym = month ? year + '-' + thisMonth + '-01' : year + '-01-01';
      let newDate = new Date(start_ym)
      let end_ym = month ? moment(newDate.setMonth(newDate.getMonth() + 1)).format("YYYY-MM") + '-01' : (parseInt(year) + 1) + '-01-01';

      const params = {
        page: page,
        company: user.member_group?.[0]?.company_group?.id,
        start_date: start_ym,
        end_date: end_ym,
      }
      console.log(params)
      const response = await getProcessList(params);
      console.log(response)
      if (!contentCount || contentCount !== Math.floor(response.data?.count / 15) + 1) {
        setContentCount(Math.floor(response.data.count / 15) + 1)
        let newArr = []
        for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
          if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
            newArr.push(i + 1)
          }
        }
        setPageArr(newArr)
      }
      if (response.status === 200) {
        const content = response.data?.results || [];
        if (content.length > 0) {
          setDataList([...content])
          setIsFetching(false)
        } else {
          setDataList(false)
          setIsFetching(false)
          setIsListEnd(true)
        }
      }
    } catch (error) {
      console.error(error);
    }
    setOnUpload(false)
  };

  const handleYearClick = (e) => {
    setYear(e.target.value)
    setIsFetching(false),
      setIsListEnd(false),
      setDataList([]),
      setMonth(''),
      setPage(1)
    setIsRefresh(true)
  }

  const handleMonthClick = (e) => {
    let value = e.target.value
    // let value = e.target.value < 10 ? '0'+e.target.value : e.target.value
    setMonth(value)
    setIsFetching(false),
      setIsListEnd(false),
      setDataList([]),
      setPage(1),
      setIsRefresh(true)
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect(() => {
    getDataList();
  }, [year, month, page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>처리내역 |  IoT Monitor</title>
        </MetaTags>
        <div className="account-pages">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs maintitle="홈" title="관리대장" breadcrumbItem="처리내역" />

            <Row className="justify-content-center">
              <Col md={8} lg={6} style={{ width: '20%', minWidth: 435 }}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">처리내역</h5>
                      <p className="text-white-50"></p>
                      <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                    </div>
                  </div>
                  <CardBody className="p-4">
                    <div className="p-3">

                      <select id="yearselect" name="yearselect" className='dropdown-toggle waves-effect waves-light' style={{ height: 24, marginLeft: 6, fontSize: 14, padding: "0 6px", marginTop: 24 }} onChange={(e) => {
                        handleYearClick(e)
                        console.log(e.target.value)
                      }}>
                        <option value="" selected disabled hidden>{moment(newDate).format("YYYY") + '년'}</option>
                        {yearSelect && yearSelect.map((item, index) => {
                          return (
                            <option key={index} value={item}>{item + "년"}</option>
                          )
                        })}
                      </select>
                      <select id="monthselect" name="monthselect" className='dropdown-toggle waves-effect waves-light' style={{ height: 24, marginLeft: 6, fontSize: 14, padding: "0 11px", marginTop: 24 }} value={month} onChange={(e) => {
                        handleMonthClick(e)
                        console.log(e.target.value)
                      }}>
                        <option value="" selected disabled hidden>전체</option>
                        <option value={''}>전체</option>
                        {monthSelect && monthSelect.map((item, index) => {
                          return (
                            <option key={index} value={item}>{item + "월"}</option>
                          )
                        })}
                      </select>

                      {dataList && dataList.map((item, index) => {
                        return (
                          <div key={'data_' + index} style={{ padding: 12, borderBottomWidth: 1, borderBottomColor: '#48C3B1', borderBottomStyle: "dotted", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                              <p style={{ fontSize: 18, color: 'black', fontWeight: 'bold', margin: 0 }}>{item.company?.name}</p>
                              <p style={{ fontSize: 14, marginTop: 5, marginBottom: 0 }}>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                            </div>
                            <div>
                              <p style={{ color: 'black', fontSize: 18, fontWeight: 'bold' }}>{item.process_wearing}Kg</p>
                            </div>
                          </div>
                        )
                      })}
                      {!dataList &&
                        <div style={{ padding: 12, borderBottomWidth: 1, borderBottomColor: '#48C3B1', borderBottomStyle: "dotted" }}>
                          <div>
                            <p style={{ fontSize: 18, color: 'black', fontWeight: 'bold', margin: 0, textAlign: "center" }}>데이터가 없습니다.</p>
                          </div>
                        </div>
                      }

                      {/* <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={ async () => {
                              handleSubmit()
                            }}
                          >
                              회원정보 변경하기
                          </button>
                        </div>
                      </div> */}


                      <div className="mt-2 mb-0" style={{ textAlign: "center" }}>
                        <div className="mt-4 btn-group">
                          <Button
                            style={{ opacity: page !== 1 ? 1 : 0, cursor: page !== 1 ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                            type="button"
                            color="primary"
                            size="sm"
                            className="waves-effect"
                            onClick={() => {
                              if (page !== 1) {
                                setPage(page - 1)
                                if (Math.min(...pageArr) === page) {
                                  setPagesGroup(pagesGroup - 5)
                                }
                              }
                            }}
                          >
                            <i className="fa fa-chevron-left" />
                          </Button>
                          {pageArr && pageArr.map((item, index) => {
                            return (
                              <Button
                                key={index}
                                style={{ cursor: 'pointer', padding: "0.5rem 1rem", border: 'none', color: page === item ? 'white' : 'black', backgroundColor: page === item ? '#48C3B1' : 'white' }}
                                type="button"
                                size="sm"
                                className="waves-effect"
                                onClick={() => {
                                  setPage(item)
                                }}
                              >
                                {item}
                              </Button>
                            )
                          })}
                          <Button
                            style={{ opacity: page !== contentCount ? 1 : 0, cursor: page !== contentCount ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                            type="button"
                            color="primary"
                            size="sm"
                            className="waves-effect"
                            onClick={() => {
                              if (page !== contentCount) {
                                setPage(page + 1)
                                if (Math.max(...pageArr) === page) {
                                  setPagesGroup(pagesGroup + 5)
                                }
                              }
                            }}
                          >
                            <i className="fa fa-chevron-right" />
                          </Button>
                        </div>
                      </div>

                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

ProcessHistory.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(ProcessHistory)
