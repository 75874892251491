import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link, useHistory } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import { useSelector } from 'react-redux';

import axios from "axios";
import { saveAs } from "file-saver";

import { contractCall, getAllbaroData } from "../../crud/auth.crud"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import loading from "../../assets/images/loading.gif";

const ContractFinish = () => {

  const history = useHistory()

  // 드롭다운
  const [settingMenu, setSettingMenu] = useState(false)

  // useState가 list일 땐 목록창, detail일 땐 디테일창, modify일 땐 수정창
  const [form, setForm] = useState('list')

  const selector = useSelector(state => state);
  console.log("설렉터", selector)

  const [page, setPage] = useState(1)
  const [dataList, setDataList] = useState([])
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState()
  const [companyId, setCompanyId] = useState(selector.auth?.user?.member_group?.[0]?.company_group.id);

  const nextGetContract = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      console.log("페이지 일치 여부", page, i + 1)
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {

    const params = {
      page: page,
      company: companyId
    }

    const response = await getAllbaroData(params);
    console.log("카운트 확인", response)
    if (!contentCount || contentCount !== Math.floor(response.data.count / 15) + 1) {
      setContentCount(Math.floor(response.data.count / 15) + 1)
      let newArr = []
      for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
        console.log("페이지 일치 여부", page, i + 1)
        if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
          newArr.push(i + 1)
        }
      }
      setPageArr(newArr)
    }
    if(typeof(response.data.count) === 'undefined'){
      setPageArr([1])
      setContentCount(1)
    }
    if (response.status === 200) {
      const content = response.data?.data || [];
      if (content.length > 0) {
        setDataList([...content])
      } else {
        setDataList(false)
      }
    }
  }

  useEffect(() => {
    nextGetContract()
  }, [pagesGroup])

  useEffect(() => {
    getDataList()
  }, [page])

  return (
    // <React.Fragment>
    //   <div className="page-content">
    //   <MetaTags>
    //     <title>계약서 관리 /  IoT Monitor</title>
    //   </MetaTags>
    //     <Container fluid>
    //       {/* Render Breadcrumbs */}
    //       <Row>
    //       <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="계약서 관리" />
    //       <Col sm={6}>
    //         <div className="float-end page-title-box" style={{display: "flex", marginTop: 12}}>
    //           <Dropdown
    //             style={{marginRight: 12}}
    //             isOpen={settingMenu}
    //             toggle={() => {
    //               setSettingMenu(!settingMenu)
    //             }}
    //           >
    //             <DropdownToggle color="primary" className="btn btn-primary dropdown-toggle waves-effect waves-light">계약여부
    //               <i className="mdi mdi-arrow-down-bold ms-2"></i> 
    //             </DropdownToggle>
    //             <DropdownMenu right>
    //               <Link to="/ContractFinish">
    //                 <DropdownItem>계약완료</DropdownItem>
    //               </Link>
    //               <Link to="/Uncontracted">
    //                 <DropdownItem>미계약</DropdownItem>
    //               </Link>
    //             </DropdownMenu>
    //           </Dropdown>
    //           <Link to="/contract-management-screen-write"
    //             className="btn btn-primary dropdown-toggle waves-effect waves-light bg-warning"
    //             style={{fontSize: 14}}
    //           >계약서 등록
    //           </Link>
    //         </div>
    //       </Col>
    //       </Row>

    <Row>
      <Col xs="12">
        {/* Render Email SideBar */}
        {/* <EmailSideBar /> */}
        <div className="email-rightbar mb-3">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">올바로 처리 내역</h4>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ fontWeight: 600 }}>ID</th>
                      <th scope="col" style={{ fontWeight: 600 }}>거래처명</th>
                      <th scope="col" style={{ fontWeight: 600 }}>거래일자</th>
                      <th scope="col" style={{ fontWeight: 600 }}>입고량</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      dataList && dataList.map((item, index) => {
                        return (
                          <tr key={item.id + '_' + index}>
                            <th style={{ cursor: "pointer" }} scope="row">{item.id}</th>
                            <td style={{ cursor: "pointer" }}>{item.company?.name}</td>
                            <td style={{ cursor: "pointer" }}>{item.created_at.substr(0, 10)}</td>
                            <td style={{ cursor: "pointer" }}>{item.qty}Kg</td>
                          </tr>
                        )
                      })
                    }
                    {!dataList &&
                      <tr style={{ position: "relative", height: 46 }}>
                        <th scope="row"><p className="noMargin" style={{ position: "absolute", left: 0, right: 0, top: '0.75rem', margin: '0 auto', textAlign: "center" }}>데이터가 없습니다.</p></th>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    }

                  </tbody>
                </table>
              </div>
            </CardBody>

          </Card>
          {form === 'list' &&
            <Row>
              <Col xs="12" style={{ textAlign: "center" }}>
                <div className="btn-group mt-4">
                  <Button
                    style={{ opacity: page !== 1 ? 1 : 0, cursor: page !== 1 ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                    type="button"
                    color="primary"
                    size="sm"
                    className="waves-effect"
                    onClick={() => {
                      if (page !== 1) {
                        setPage(page - 1)
                        if (Math.min(...pageArr) === page) {
                          setPagesGroup(pagesGroup - 5)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  {pageArr && pageArr.map((item, index) => {
                    return (
                      <Button
                          key={index}
                          style={{ cursor: 'pointer', padding: "0.5rem 1rem", border: 'none', color: page === item ? 'white' : 'black', backgroundColor: page === item ? '#48C3B1' : 'white' }}
                          type="button"
                          size="sm"
                          className="waves-effect"
                          onClick={() => {
                            setPage(item)
                          }}
                        >
                          {item}
                        </Button>
                    )
                  })}
                  <Button
                    style={{ opacity: page !== contentCount ? 1 : 0, cursor: page !== contentCount ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                    type="button"
                    color="primary"
                    size="sm"
                    className="waves-effect"
                    onClick={() => {
                      if (page !== contentCount) {
                        setPage(page + 1)
                        if (Math.max(...pageArr) === page) {
                          setPagesGroup(pagesGroup + 5)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-chevron-right" />
                  </Button>
                </div>
              </Col>
            </Row>
          }
        </div>
      </Col>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </Row>
    //     </Container>
    //   </div>
    // </React.Fragment>
  )
}

export default ContractFinish
