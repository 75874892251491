import { func } from "prop-types";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { checkBinId, checkDriverId, deleteBinAccount, deleteDriverAccount, getBinCompany, getBinList, getBusinessList, getDriverList, patchBusinessList, saveBinAccount, saveBinExcel, saveDriverAccount, saveDriverExcel, setNewBusinessList, updateBinList, updateDriverList } from "crud/auth.crud";

import _ from "lodash";
import moment from "moment";
import Util from "pages/Dashboard/Common/Util";

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

const style = { backgroundColor: "#ffffff", padding: "0.3rem", cursor: "pointer", width: 210 };

const hoverStyle = { backgroundColor: "#48C3B1", color: "#ffffff", width: 210 };

const WasteBinManagementScreen = () => {
  const history = useHistory()
  const selector = useSelector(state => state)
  const newDate = new Date()
  const nextDate = new Date()

  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [wasteBinId, setWasteBinId] = useState('');
  const [wasteBinIdConfirm, setWasteBinIdConfirm] = useState(false);
  const [wasteBinMount, setWasteBinMount] = useState('60');
  const [targetCompany, setTargetCompany] = useState({});
  const [companyId, setCompanyId] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.id);
  const [myId, setMyId] = useState(selector.auth?.user?.id);
  const [wasteBinListFile, setWasteBinListFile] = useState(undefined);
  const [selectedWasteBinId, setSelectedWasteBinId] = useState('');
  const [isHover, setIsHover] = useState(false)
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState()

  const mountList = [
    { 'key': '60', 'text': '60L' },
    { 'key': '120', 'text': '120L' },
    { 'key': '240', 'text': '240L' },
  ]

  const nextGetData = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      console.log("페이지 일치 여부", page, i + 1)
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {
    setOnUpload(true)
    if (isFetching || isListEnd) {
      return;
    }

    let params = {
      page: page,
      company: companyId
    }

    setIsFetching(true)

    const response = await getBinList(params);
    const content = response.data?.results || [];
    if (!contentCount || contentCount !== Math.floor(response.data?.count / 15) + 1) {
      setContentCount(Math.floor(response.data.count / 15) + 1)
      let newArr = []
      for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
        if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
          newArr.push(i + 1)
        }
      }
      setPageArr(newArr)
    }
    if (content.length > 0) {
      setDataList([...content])
      setIsFetching(false)
    } else {
      setIsFetching(false)
      setIsListEnd(true)
    }
    setOnUpload(false)
  }

  const getCompanyList = async () => {
    setOnUpload(true)
    const rs = await getBinCompany(companyId)
    const rsData = rs.data?.data || [];
    let tmp = []
    for (let i = 0; i < rsData.length; i++) {
      for (let j = 0; j < rsData[i].length; j++) {
        if (tmp.filter(itm => itm.company !== rsData[i][j].company)) {
          tmp.push(rsData[i][j].contract_company_group_data)
        }
      }
    }
    setCompanyList(tmp)
    setOnUpload(false)
  }

  const checkPk = async () => {
    if(wasteBinId === ''){
      return alert('RFID를 입력해주세요.')
    }
    setOnUpload(true)
    const params = {
      id: wasteBinId,
    }
    const rs = await checkBinId(params)
    if (rs.data?.result === 'success') {
      setWasteBinIdConfirm(true)
    }
    alert(rs.data?.msg)
    setOnUpload(false)
  }

  const isValiddate = () => {
    if (wasteBinId === '') {
      return alert("RFID를 입력해주세요.")
    }
    if (!wasteBinIdConfirm) {
      return alert("RFID 중복확인을 해주세요.")
    }
    if (wasteBinMount === '') {
      return alert("용량을 입력해주세요.")
    }
    if (_.isEmpty(targetCompany)) {
      return alert("대상업체를 입력해주세요.")
    }
    return "success"
  }

  const saveWasteBin = async () => {
    setOnUpload(true)
    const validdate = isValiddate();
    if (validdate !== 'success') {
      return alert(validdate)
    }
    const params = {
      'id': wasteBinId,
      'mount': wasteBinMount,
      'owner': targetCompany.id,
      'company': companyId,
      'status': 'Y',
    }
    const rs = await saveBinAccount(params)
    if (rs.status === 201) {
      setIsRefresh(true)
      alert('정상적으로 등록되었습니다.')
    } else {
      alert('등록에 실패하였습니다.')
    }
    setModalShow(false)
    setWasteBinId('')
    setWasteBinIdConfirm(false)
    setWasteBinMount('')
    setTargetCompany({})
    setOnUpload(false)
    getDataList()
  }

  const uploadWasteBinExcel = async () => {
    setOnUpload(true)
    if (wasteBinListFile == undefined || wasteBinListFile?.length == 0) { setOnUpload(false); return alert('파일을 등록하세요.') }
    try {
      const params = {
        type: 'xlsx',
        category: 'wasteBinListFile',
        company_id: companyId,
        member_id: myId
      }
      const fd = new FormData();
      fd.append('data', JSON.stringify(params));
      const newFile = new File([wasteBinListFile[0]], `${encodeURI(wasteBinListFile[0].name)}`, { type: wasteBinListFile[0].type })
      if (wasteBinListFile && !_.isEmpty(wasteBinListFile)) {
        fd.append('file_0', newFile);
      }

      for (let key of fd.keys()) {
        console.log(key, ">>>", fd.get(key));
      }
      const response = await saveBinExcel(fd)
      console.log(response)
      if (response.data?.result === 'success') {
        setIsRefresh(true)
        alert('정상적으로 등록되었습니다.')
      }

    } catch (e) {
      console.log(e)
      if (e.response) console.log(e.response);
    }
    setWasteBinListFile(undefined)
    setModalShow2(false)
    setOnUpload(false)
  }

  const deleteWasteBin = async (item) => {
    console.log(item)
    if (confirm("해당 쓰레기통을 삭제하시겠습니까?")) {
      setOnUpload(true)
      {
        const params = {
          status: 'N'
        }
        console.log(params)
        const response = await deleteBinAccount(params, item.id);
        console.log(response)
        if (response?.status === 200) {
          setIsRefresh(true)
          alert('정상적으로 삭제되었습니다.')
        }
      }
      setOnUpload(false)
    }
    getDataList()
  }

  const updateWasteBin = async () => {
    setOnUpload(true)
    const params = {
      'mount': wasteBinMount,
      'owner': targetCompany.id,
    }
    const response = await updateBinList(params, selectedWasteBinId);
    if (response?.status === 200) {
      setIsRefresh(true)
      alert('정상적으로 수정되었습니다.')
    }
    setModalShow3(false)
    setSelectedWasteBinId('')
    setWasteBinId('')
    setWasteBinIdConfirm(false)
    setWasteBinMount('')
    setTargetCompany({})
    setOnUpload(false)
  }

  const openUpdateModal = (item) => {
    setModalShow3(true)
    setWasteBinId(item.id)
    setWasteBinMount(item.mount + '')
    setTargetCompany(item.owner_company_data)
    setSelectedWasteBinId(item.id)
  }

  const registFiles = (e) => {
    setWasteBinListFile([...e.target.files])
    console.log(e.target.files[0])
  }

  const deleteFile = (item) => {
    setWasteBinListFile(wasteBinListFile.filter(data => data !== item))
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect(() => {
    getDataList()
    getCompanyList()
  }, [isRefresh, modalShow, modalShow3, page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>쓰레기통 관리 |  IoT Monitor</title>
        </MetaTags>
        <div className="account-pages">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="쓰레기통 관리" />

            <Row className="justify-content-center">
              <Col md={8} lg={6} style={{ width: '20%', minWidth: 420 }}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">쓰레기통 관리</h5>
                      <p className="text-white-50"></p>
                      <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                    </div>
                  </div>
                  {!modalShow && !modalShow2 && !modalShow3 &&
                    <CardBody className="p-4">
                      <div className="p-3">

                        <div>
                          <div className="mb-3" style={{ position: "relative" }}>
                            <p className="noMargin" style={{ fontSize: 16, color: 'black' }}>쓰레기통 엑셀등록<br />(변경사항 샘플 엑셀 참조)</p>
                          </div>
                          <div className="mb-3">
                            <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{ height: 36, marginLeft: 6 }}>파일 선택</label>
                            <input style={{ display: "none" }} type="file" accept=".xls,.xlsx" id="ex_file" onChange={(e) => {
                              registFiles(e)
                            }} />
                            {wasteBinListFile?.length > 0 && wasteBinListFile.map((item, index) => {
                              return (
                                <div key={index} style={{ border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6 }}>
                                  <p className="noMargin" style={{ paddingLeft: 6 }}>{item.name}</p>
                                  <p className="noMargin" style={{ paddingRight: 6, color: "#000", cursor: "pointer" }} onClick={() => {
                                    deleteFile(item)
                                  }}>X</p>
                                </div>
                              )
                            })
                            }
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              onClick={async () => {
                                uploadWasteBinExcel()
                              }}
                            >
                              등록하기
                            </button>
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-warning waves-effect waves-light"
                              type="submit"
                              style={{ width: '100%', marginTop: 24 }}
                              onClick={async () => {
                                setModalShow(true)
                              }}
                            >
                              쓰레기통 추가
                            </button>
                          </div>
                        </div>

                        {dataList && dataList.map((item, index) => {
                          return (
                            <div key={index} style={{ marginHorizontal: 20 }}>
                              <div style={{ padding: 12, borderBottomWidth: 1, borderBottomColor: '#48C3B1', borderBottomStyle: "solid", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                <div style={{ cursor: "pointer" }} onClick={() => {
                                  openUpdateModal(item)
                                }}>
                                  <p style={{ fontSize: 18, color: 'black', fontWeight: 'bold', margin: 0 }}>ID : {item.id}</p>
                                  <p style={{ fontSize: 16, color: 'black', margin: 0 }}>대상 : {item.owner_company_data?.name}</p>
                                  <p style={{ fontSize: 16, color: 'black', margin: 0 }}>용량 : {item.mount}L</p>
                                </div>
                                <div style={{ zIndex: 10, textAlign: "right" }}>
                                  <p style={{ color: '#ff4949', fontSize: 16, margin: 0, cursor: 'pointer' }} onClick={() => {
                                    deleteWasteBin(item)
                                  }}>{'X'}</p>
                                  <p style={{ color: 'black', width: 150, fontSize: 16, margin: 0 }}>{item.member?.email}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })}

                        <div className="mt-2 mb-0" style={{ textAlign: "center" }}>
                          <div className="mt-4 btn-group">
                            <Button
                              style={{ opacity: page !== 1 ? 1 : 0, cursor: page !== 1 ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                              type="button"
                              color="primary"
                              size="sm"
                              className="waves-effect"
                              onClick={() => {
                                if (page !== 1) {
                                  setPage(page - 1)
                                  if (Math.min(...pageArr) === page) {
                                    setPagesGroup(pagesGroup - 5)
                                  }
                                }
                              }}
                            >
                              <i className="fa fa-chevron-left" />
                            </Button>
                            {pageArr && pageArr.map((item, index) => {
                              return (
                                <Button
                                  key={index}
                                  style={{ cursor: 'pointer', padding: "0.5rem 1rem", border: 'none', color: page === item ? 'white' : 'black', backgroundColor: page === item ? '#48C3B1' : 'white' }}
                                  type="button"
                                  size="sm"
                                  className="waves-effect"
                                  onClick={() => {
                                    setPage(item)
                                  }}
                                >
                                  {item}
                                </Button>
                              )
                            })}
                            <Button
                              style={{ opacity: page !== contentCount ? 1 : 0, cursor: page !== contentCount ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                              type="button"
                              color="primary"
                              size="sm"
                              className="waves-effect"
                              onClick={() => {
                                if (page !== contentCount) {
                                  setPage(page + 1)
                                  if (Math.max(...pageArr) === page) {
                                    setPagesGroup(pagesGroup + 5)
                                  }
                                }
                              }}
                            >
                              <i className="fa fa-chevron-right" />
                            </Button>
                          </div>
                        </div>

                      </div>
                    </CardBody>
                  }

                  {modalShow &&
                    <CardBody className="p-4">
                      <div className="p-3">

                        <div style={{ padding: '0.4rem 1rem', borderRadius: '0.2rem', margin: "18px 0", fontSize: 16 }} className="btn btn-primary dropdown-toggle waves-effect waves-light bg-success" onClick={() => { setModalShow(false) }}>뒤로가기
                        </div>

                        <div style={{ display: 'flex' }}>
                          <div className="mb-3" style={{ position: "relative" }}>
                            <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>쓰레기통 RFID</p>
                            <input className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setWasteBinId(e.target.value), setWasteBinIdConfirm(false) }} />
                          </div>
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{ height: 35, marginTop: 30, marginLeft: 6 }} value="중복확인" onClick={() => { checkPk() }} />
                        </div>

                        <div style={{ display: 'flex', position: "relative" }}>
                          <div className="mb-3">
                            <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>대상업체</p>
                            <input className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} value={targetCompany.name} disabled />
                          </div>
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{ height: 35, marginTop: 30, marginLeft: 6 }} value="업체선택" onClick={() => { modalShow2 ? setModalShow2(false) : setModalShow2(true) }} />
                          <div style={{ zIndex: 1, position: 'absolute', top: 66 }}>
                            {modalShow2 && companyList.map((item) => {
                              return (
                                <div style={isHover == item.id ? { ...style, ...hoverStyle } : { ...style }} key={item.id}
                                  onMouseOver={() => {
                                    setIsHover(item.id)
                                  }}
                                  onMouseLeave={() => {
                                    setIsHover(false)
                                  }}
                                  onClick={() => {
                                    setTargetCompany(item)
                                    setModalShow2(false)
                                  }}>{item.name}</div>
                              )
                            })}
                          </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                          <div className="mb-3" style={{ position: "relative" }}>
                            <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>용량</p>
                            {mountList && mountList.map((item, index) => {
                              return (
                                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                                  <input type="radio" id={item.key} className="is-untouched is-pristine av-valid form-check-input" style={{ marginTop: 3 }} checked={wasteBinMount == item.key} onClick={(e) => { setWasteBinMount(item.key) }} />
                                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                                </div>
                              )
                            })}
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              onClick={() => { saveWasteBin() }}
                            >
                              등록하기
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  }

                  {modalShow3 &&
                    <CardBody className="p-4">
                      <div className="p-3">

                        <div style={{ padding: '0.4rem 1rem', borderRadius: '0.2rem', margin: "18px 0", fontSize: 16 }} className="btn btn-primary dropdown-toggle waves-effect waves-light bg-success" onClick={() => { setModalShow3(false) }}>뒤로가기
                        </div>

                        <div style={{ display: 'flex' }}>
                          <div className="mb-3" style={{ position: "relative" }}>
                            <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>쓰레기통 RFID</p>
                            <input className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} defaultValue={wasteBinId} onChange={(e) => { setWasteBinId(e.target.value), setWasteBinIdConfirm(false) }} />
                          </div>
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{ height: 35, marginTop: 30, marginLeft: 6 }} value="중복확인" onClick={() => { checkPk() }} />
                        </div>

                        <div style={{ display: 'flex', position: "relative" }}>
                          <div className="mb-3">
                            <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>대상업체</p>
                            <input className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} value={targetCompany.name} disabled />
                          </div>
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{ height: 35, marginTop: 30, marginLeft: 6 }} value="업체선택" onClick={() => { modalShow2 ? setModalShow2(false) : setModalShow2(true) }} />
                          <div style={{ zIndex: 1, position: 'absolute', top: 66 }}>
                            {modalShow2 && companyList.map((item) => {
                              return (
                                <div style={isHover == item.id ? { ...style, ...hoverStyle } : { ...style }} key={item.id}
                                  onMouseOver={() => {
                                    setIsHover(item.id)
                                  }}
                                  onMouseLeave={() => {
                                    setIsHover(false)
                                  }}
                                  onClick={() => {
                                    setTargetCompany(item)
                                    setModalShow2(false)
                                  }}>{item.name}</div>
                              )
                            })}
                          </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                          <div className="mb-3" style={{ position: "relative" }}>
                            <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>용량</p>
                            {mountList && mountList.map((item, index) => {
                              return (
                                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                                  <input type="radio" id={item.key} className="is-untouched is-pristine av-valid form-check-input" style={{ marginTop: 3 }} checked={wasteBinMount == item.key} onClick={(e) => { setWasteBinMount(item.key) }} />
                                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                                </div>
                              )
                            })}
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              onClick={() => { updateWasteBin() }}
                            >
                              수정하기
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  }
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

// business-list-management-screen.propTypes = {
//   registerUser: PropTypes.func,
//   registerUserFailed: PropTypes.func,
//   registrationError: PropTypes.any,
//   user: PropTypes.any,
// }

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default WasteBinManagementScreen

// export default connect(mapStatetoProps, {
//   registerUser,
//   apiError,
//   registerUserFailed,
// })(business-list-management-screen)
