import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import { useLocation } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card, 
  CardBody,
  Button
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import loading from "../../assets/images/loading.gif";

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

import Board from './Component/Board';

//i18n
import { withTranslation } from "react-i18next"
import { getUserDetail, postUpdateUser } from 'crud/auth.crud';

const UserMain = props => {
  const location = useLocation()

  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState(0)
  const [dataList, setDataList] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [inputData, setInputData] = useState({});
  const [page, setPage] = useState(1);
  const [centerMarker, setCenterMarker] = useState({ lat: 36.44699, lng: 127.09598 })
  const [inputName, setInputName] = useState('')
  const [inputEmail, setInputEmail] = useState('')
  const [inputTel, setInputTel] = useState('')
  const [inputStatus, setInputStatus] = useState('')
  const [inputPassword, setInputPassword] = useState('')
  const [showPw, setShowPw] = useState(false)

  const nextGetData = () => {
    setOnUpload(true)
    let newArr = []
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    setPageArr(newArr)
    setOnUpload(false)
  }

  const updateMember = async () => {
    const params = {
      user_id:inputData.user_id,
      username:inputName,
      email:inputEmail,
      mobile_phone:inputTel,
      status:inputStatus
    }
	if(inputPassword){
      params.password = inputPassword
    }
    console.log("params",params)
    const response = await postUpdateUser(params)
    console.log("reponse",response)
    if(response.status == 200){
      alert("수정 완료되었습니다!")
      window.location.reload()
    } else {
      alert("오류가 발생했습니다. 다시 시도 해 주세요.")
    }
  }

  const getDataList = async () => {
    setOnUpload(true)
    try {
      const response = await getUserDetail({id : location.state['id']})
      // 나중에 '900002'를 location.state.id로 바꿔야함
      console.log(response)
      if (response.status === 200) {
        setInputData(response.data)
        setInputName(response.data.username)
        setInputEmail(response.data.email)
        setInputTel(response.data.mobile_phone)
        setInputStatus(response.data.status)
        // const content = response.data?.results || [];
        // if (content.length > 0) {
        //   setDataList([...content])
        //   setMarkerList([...content].splice(0, 1))
        //   setInputData(response.data)
        //   setCenterMarker({ lat: [...content].splice(0, 1)[0].lat, lng: [...content].splice(0, 1)[0].lng })
        // } else {
        //   setDataList(false)          
        // }
      }
    } catch (error) {
      console.error(error);
    }
    setOnUpload(false)
  };

  const movePage = (id, company) => {
    console.log(id, company)
    // history.push({ pathname: '/detail', state: { id: id } })
  }



  


  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect( async () => {
    await getDataList()
  }, [page])

  useEffect(()=>{
    console.log(">>>"+JSON.stringify(location))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  {/* <Board
                  title={'유저 정보'}
                  form={'input'}
                  height={'80vh'}
                  inputContent={[
                    {title: 'idx', value: inputData.id},
                    {title: '유저 ID', value: inputData.user_id},
                    {title: '유저명', value: inputData.username},
                    {title: 'email', value: inputData.email},
                    {title: '연락처', value: inputData.mobile_phone},
                    {title: '계정 활성화 여부', value: inputData.is_active ? "활성" : "비활성"},
                    // {title: '푸시알림 동의여부', value: inputData.agree_push},
                    // {title: '메일알림 동의여부', value: inputData.agree_email},
                    {title: '가입일', value: inputData.date_joined},
                    {title: '', button: "test", onclick:() => console.log("aaaa")},
                    ]} /> */}
                    {/* {item.key === 'created_at' ? item.value.substr(0, 10) : item.value} */}
                  <Card>
                    <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                      <div className="table-responsive" style={{ height: '80vh', minHeight: '100px' }}>
                        <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                          <thead className="chart-thead">
                            <tr>
                              <th scope="col" className='bg-dark-ash'>유저 상세</th>
                            </tr>
                          </thead>
                        </table>



                        <div className="w-100" style={{ padding: 24 }}>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">번호</p>
                              <input value={inputData.id} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { console.log("e:::", e) }} disabled />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">ID</p>
                              <input value={inputData.user_id} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { console.log("e:::", e) }} disabled/>
                            </>
                          </div>
						  <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">비밀번호</p>
                              { !showPw &&
                              <Button size='sm' className='waves-effect left-btn btn btn-secondary btn-sm' style={{backgroundColor: '#1C2F1A', color: 'white' }} onClick={() => {
                                    setShowPw(true);
                                  }}>비밀번호 변경</Button>
                              }
                              { showPw &&
                              <>
                              <input value={inputPassword} type="password" className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputPassword(e.target.value) }}/>
                              <Button size='sm' className='waves-effect left-btn btn btn-secondary btn-sm' 
                                  style={{ backgroundColor: '#1C2F1A', color: 'white' }} onClick={() => {
                                    setShowPw(false);
                                    setInputPassword('')
                                  }}>비밀번호 변경취소</Button>                              
                              </>
                              }
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">담당자 이름</p>
                              <input value={inputName} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputName(e.target.value) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">Email</p>
                              <input defaultValue={inputEmail} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputEmail(e.target.value) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">연락처</p>
                              <input defaultValue={inputTel !== null ? inputTel : '-'} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setInputTel(e.target.value) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">가입일</p>
                              <input value={inputData.date_joined !== undefined ? inputData.date_joined.substring(0,10) : ''} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { console.log("e:::", e) }} disabled />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16 mb-2">회원 상태</p>
                              <Col style={{flexGrow: 1}}>
                                <div className="btn-group mt-1 waver-pagination-btn-group">
                                  <Button size='sm' className='waves-effect left-btn btn btn-secondary btn-sm' style={{ backgroundColor: inputStatus === 'Y' && '#1C2F1A', color: inputStatus === 'Y' && 'white' }} onClick={() => {
                                    setInputStatus('Y')
                                  }}>
                                    ㅤYㅤ
                                  </Button>
                                  <Button size='sm' className='waves-effect right-btn btn btn-secondary btn-sm' style={{ backgroundColor: inputStatus === 'N' && '#1C2F1A', color: inputStatus === 'N' && 'white' }} onClick={() => {
                                    setInputStatus('N')
                                  }}>
                                    ㅤNㅤ
                                  </Button>
                                </div>
                              </Col>
                            </>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <button
            className="btn btn-primary w-md waves-effect waves-light"
            type="submit"
            onClick={() => { updateMember()}}
          >
            수정
          </button>
          
          {onUpload && 
            <div style={{
                position: "absolute",
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.6)',
                zIndex: 999,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img src={loading} alt="로딩중입니다." width={'5%'}></img>
            </div>
          }

        </Container>
      </div>
    </React.Fragment>
  )
}

UserMain.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(UserMain)
