import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import { useLocation } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import loading from "../../assets/images/loading.gif";

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

import Board from '../Dashboard/Component/Board';

//i18n
import { withTranslation } from "react-i18next"
import { getIotDeviceList } from 'crud/auth.crud';

const AllDevice = props => {
  const location = useLocation()

  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState(0)
  const [dataList, setDataList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [inputData, setInputData] = useState({});
  const [page, setPage] = useState(1);
  const [centerMarker, setCenterMarker] = useState({ lat: 36.44699, lng: 127.09598 })
  const [screenList, setScreenList] = useState(['0', false])
  const [order, setOrder] = useState('id')
  const [searchType, setSearchType] = useState('')
  const [search, setSearch] = useState('')

  const nextGetData = () => {
    setOnUpload(true)
    let newArr = []
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {
    setOnUpload(true)
    try {      
      const params = {   
        search, search,     
        page: page,
        type : searchType, 
        order : order
      }
      const response = await getIotDeviceList(params);
      console.log(response.data)
      
      let dataCount = response.data.count % 15 === 0 ? response.data.count / 15 : Math.floor(response.data.count / 15) + 1
      if (!contentCount || contentCount !== dataCount) {
        if(dataCount === 0){
          setContentCount(1)
        }else{
          setContentCount(dataCount)
        }
        let newArr = []
        for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
          if (i + 1 <= dataCount) {
            newArr.push(i + 1)
          }
        }
        if(newArr.length === 0) newArr = [1]
        setPageArr(newArr)
      }
      if (response.status === 200) {
        const content = response.data?.results || [];
        if (content.length > 0) {
          setDataList([...content])          
        } else {
          setDataList(false)          
        }
        // 발주처 중복 제거
        let filteredArr = []
        let dupContent = [...content]
        for(let i=0;i<dupContent.length;i++){
          if(filteredArr.indexOf(dupContent[i].company.name) === -1){
            filteredArr.push(dupContent[i].company.name)
          }else{
            dupContent.splice(i, 1)
            i -= 1
          }
        }
        for(let i=0;i<filteredArr.length;i++){
          filteredArr[i] = {company : filteredArr[i]}
        }
        if (filteredArr.length > 0) {
          setCompanyList([...filteredArr])
        } else {
          setCompanyList(false)
        }
        //////
      }
    } catch (error) {
      console.error(error);
    }
    setOnUpload(false)
  };

  const callback = (id, company, device) => {
    // 게시판 클릭 콜백함수 로직
  }

  const fullScreen = (key) => {
    if(screenList[0] === key){
      setScreenList(['0', false])
    }else {
      setScreenList([key, true])
    }
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect( async () => {
    await getDataList()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
      {/* <div className='content-header d-flex align-items-center noMargin text-black font-size-18 justify-content-between'>
        <div>
          <p className='font-weight-bold'>전체</p>
        </div>
        <div className='d-flex pointer'>
          <div className='padding-left-12 padding-right-12'>
            <p>발광형 표시 단말기<i className="mdi mdi-menu-down" /></p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-devices" /> 개체</p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-filter-variant font-size-20" /> Filters</p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-clock-outline" /> 기록 - 과거 0분</p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-download" /></p>
          </div>
        </div>
      </div> */}
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          {/* <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title font-size-24">관리 홈</h6>
                <ol className="breadcrumb m-0">
                  <li className="font-size-16" style={{color : '#8A8A8A'}}>'국내최초' 음식물류 폐기물 스마트 수거 플랫폼</li>
                </ol>
              </Col>
            </Row>
          </div> */}
          <Row>
            <Col md={12}>
              <Board
                title={'All: 장치'}
                form={'post'}
                headers={[
                  {key : 'created_at', value: 'Created_time'},
                  {key : 'id', value: 'Name'},
                  {key : 'iot_device_model', value: 'Device profile'},
                  {key : 'label', value: 'Label'},
                ]} 
                dataList={dataList} 
                callback={callback}
                boardKey={'device'}
                page={page}
                height={'88vh'}
                setPage={setPage}
                pagesGroup={pagesGroup}
                setPagesGroup={setPagesGroup}
                pageArr={pageArr}
                contentCount={contentCount}
                domKey={'99'}
                fullScreen={fullScreen} />
            </Col>
          </Row>
          
          {onUpload && 
            <div style={{
                position: "absolute",
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.6)',
                zIndex: 999,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img src={loading} alt="로딩중입니다." width={'5%'}></img>
            </div>
          }

        </Container>
      </div>
    </React.Fragment>
  )
}

AllDevice.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(AllDevice)
