import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"

import { checkId } from "crud/auth.crud"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "store/actions"

import Util from "pages/Dashboard/Common/Util";

// Redux
import { connect } from "react-redux"

// import images
import weaver from "assets/images/weaver.png";

// 주소 찾기
import DaumPostcode from 'react-daum-postcode';

const RegisterStep1 = props => {

  const [prevState, setPrevState] = useState({})
  const history = useHistory()
  const location = useLocation()
  const [enrollZip, setEnrollZip] = useState({address:'',});
  const [enrollStreet, setEnrollStreet] = useState({address2:'',});
  const [popup, setPopup] = useState(false);
  const [memberId, setMemberId] = useState('');
  const [memberIdProto, setMemberIdProto] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [memberTelephone, setMemberTelephone] = useState('');
  const [memberFax, setMemberFax] = useState('')
  const [memberCellphone, setMemberCellphone] = useState('')

  
  const handleValidSubmit = (event, values) => {
    console.log(event);
    console.log(values);
    var regex = /^(?=.*[a-z])(?=.*[0-9])[a-z0-9]+$/;
    if(regex.test(values.memberPassword) === false){
      return alert('비밀번호는 특수문자 없이, 영문과 숫자가 모두 포함되어야합니다.')
    }else if (values.memberPassword.length < 8){
      return alert('비밀번호는 8자 이상이어야 합니다.')
    }
    if(memberId && regex.test(memberId) === false){
      return alert('아이디는 특수문자 없이, 영문과 숫자가 모두 포함되어야합니다.')
    }
    const url = {
      pathname : `/register-step2`,
      state : {...prevState, ...values, memberId} 
    }
    if(memberId && values.memberPassword == values.memberPasswordConfirm){
      history.push(url);
    }
  }

  useEffect(() => {
    setPrevState(location.state)
    props.apiError("")
  }, [location]);

  const checkUserId = async ()=>{
    if(memberIdProto)
    console.log(memberIdProto)
    const params = {
      user_id : memberIdProto,
    }
    const rs = await checkId(params)
    console.log(rs)
    console.log(rs.data)
    if(rs.data?.result === 'success'){
        setMemberId(memberIdProto)  
        alert('사용할 수 있는 아이디입니다.')    
    }else{
      alert('사용중인 아이디입니다.') 
    }
  }

  // 주소찾기 API
  const Post = (props) => {

    const complete = (data) =>{
      // data.zonecode는 우편번호, data.roadAddress는 도로명주소
      let zipAddress = data.zonecode;
      let roadAddress = data.roadAddress;
      console.log(data.zonecode)
      console.log(data.roadAddress)

      props.setZipCode({
          ...props.zipCode,
          address:zipAddress
      })
        props.setStreetAddress({
          ...props.streetAddress,
          address2:roadAddress
      })
    }

    return (
      <div>
        <DaumPostcode
          className="postmodal"
          autoClose
          onComplete={complete} />
      </div>
    );
  };

  // const handleInput = (e) => {
  //   setEnrollZip({...enrollZip, [e.target.name]:e.target.value,})
  //   console.log(123123)
  // }

  // const handleInput2 = (e) => {
  //   setEnrollStreet({...enrollStreet, [e.target.name]:e.target.value,})
  // }
  
  const handleComplete = () => {
    setPopup(!popup);
  }

  useEffect(()=>{
    setPopup(false)
  }, [enrollStreet])

  return (
    <React.Fragment>
      <MetaTags>
        <title>회원가입 |  IoT Monitor</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">회원가입</h5>
                    <p className="text-white-50"></p>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >

                      {props.registrationError &&
                        props.registrationError ? (
                          <Alert color="danger">
                            {props.registrationError}
                          </Alert>
                        ) : null}

                      <div className="mb-3" style={{position: "relative"}}>
                        <AvField
                          id="id"
                          name="memberIdProto"
                          label="아이디"
                          className="form-control"
                          placeholder="3-15자의 영문/숫자 조합"
                          type="text"
                          onChange={(e) => {setMemberIdProto(e.target.value)}}
                          validate={{
                            required: {value: true, errorMessage: '아이디가 입력되지 않았습니다.'},
                            pattern: {value: '^[A-Za-z0-9]+$', errorMessage: '아이디 작성 시 영문/숫자만 사용 가능합니다.'},
                            minLength: {value: 3, errorMessage: '아이디 작성 시 3자 이상으로 입력해주세요.'},
                            maxLength: {value: 15, errorMessage: '아이디 작성 시 15자 이하로 입력해주세요.'},
                          }}
                          required
                        />
                        <p style={{color: '#A749FF', position: 'absolute', top: 36, right: 24, cursor: "pointer"}} onClick={() => {
                          checkUserId()
                          }} >중복확인</p>
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="memberPassword"
                          label="비밀번호"
                          type="password"
                          required
                          placeholder="8자 이상의 영문/숫자 조합"
                          validate={{
                            required: {value: true, errorMessage: '비밀번호가 입력되지 않았습니다.'},
                            pattern: {value: '^[A-Za-z0-9]+$', errorMessage: '비밀번호 작성 시 영문/숫자만 사용 가능합니다.'},
                            minLength: {value: 8, errorMessage: '비밀번호 작성 시 8자 이상으로 입력해주세요.'},
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="memberPasswordConfirm"
                          label="비밀번호 확인"
                          type="password"
                          errorMessage="비밀번호가 입력되지 않았습니다."
                          required
                          placeholder="비밀번호를 입력해주세요."
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="companyName"
                          label="업체명"
                          type="text"
                          errorMessage="업체명이 입력되지 않았습니다."
                          required
                          placeholder="업체명을 입력해주세요."
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="memberName"
                          label="대표자명"
                          type="text"
                          errorMessage="대표자명이 입력되지 않았습니다."
                          required
                          placeholder="실명을 입력해주세요."
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="companyNumber"
                          label="사업자 등록번호"
                          type="text"
                          errorMessage="사업자 등록번호가 입력되지 않았습니다."
                          required
                          placeholder="000-00-00000"
                          onChange={(e)=>{setCompanyNumber(Util.checkBusinessNumber(e.target.value))}}
                          value={companyNumber}
                          validate={{
                            pattern: {value: '[0-9]' + '[0-9]' + '[0-9]' + '[-]' + '[0-9]' + '[0-9]' + '[-]' + '[0-9]' + '[0-9]' + '[0-9]' + '[0-9]' + '[0-9]', errorMessage: '000-00-00000의 형태로 입력해주세요.'},
                            maxLength: {value: 12},
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <div style={{display: 'flex'}}>
                          <AvField
                            name="zipCode"
                            label="주소"
                            type="text"
                            errorMessage="우편번호가 입력되지 않았습니다."
                            required
                            placeholder="우편번호"
                            value={enrollZip.address}
                            // onChange={(e)=>{handleInput(e)}}
                            disabled
                            style={{width: 150}}
                          />
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{height: 35, marginTop: 30, marginLeft: 6}} onClick={handleComplete} value="주소 찾기" />
                        </div>
                      <div className="address_search" >
                        {popup && <Post company={enrollZip} setZipCode={setEnrollZip} setStreetAddress={setEnrollStreet}></Post>}
                      </div>
                        <AvField
                          name="companyAddress1"
                          type="text"
                          errorMessage="주소가 입력되지 않았습니다."
                          required
                          placeholder="00시 00구 00대로 00번가길 000"
                          disabled
                          // onChange={handleInput2}
                          value={enrollStreet.address2}
                          style={{marginTop: 6}}
                        />
                        <AvField
                          name="companyAddress2"
                          type="text"
                          errorMessage="주소가 입력되지 않았습니다."
                          required
                          placeholder="상세주소 입력"
                          style={{marginTop: 6}}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="memberEmail"
                          label="이메일"
                          type="email"
                          errorMessage="이메일이 입력되지 않았습니다."
                          required
                          placeholder="이메일을 입력해주세요."
                          validate={{
                            email: {value: true, errorMessage: '유효한 이메일을 입력해주세요.'}
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="memberTelephone"
                          label="전화번호"
                          type="text"
                          errorMessage="전화번호가 입력되지 않았습니다."
                          required
                          placeholder="전화번호를 입력해주세요."
                          onChange={(e)=>{setMemberTelephone(Util.handlePhoneNumberChange(e.target.value))}}
                          value={memberTelephone}
                          validate={{
                            maxLength: memberTelephone.charAt(0) == 0 && memberTelephone.charAt(1) == 2 ? {value: 11} : {value: 12}
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="memberFax"
                          label="팩스"
                          type="text"
                          errorMessage="팩스번호가 입력되지 않았습니다."
                          required
                          placeholder="팩스번호를 입력해주세요."
                          onChange={(e)=>{setMemberFax(Util.handlePhoneNumberChange(e.target.value))}}
                          value={memberFax}
                          validate={{
                            maxLength: memberFax.charAt(0) == 0 && memberFax.charAt(1) == 2 ? {value: 11} : {value: 12}
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="memberCellphone"
                          label="휴대폰 번호"
                          type="text"
                          errorMessage="휴대폰 번호가 입력되지 않았습니다."
                          required
                          placeholder="휴대폰 번호를 입력해주세요."
                          validate={{
                            pattern: {value: '[0-9]' + '[0-9]' + '[0-9]' + '[-]' + '[0-9]' + '[0-9]' + '[0-9]' + '[0-9]' + '[-]' + '[0-9]' + '[0-9]' + '[0-9]' + '[0-9]', errorMessage: '010-0000-0000의 형태로 입력해주세요.'},
                            maxLength: {value: 13},
                          }}
                          value={memberCellphone}
                          onChange={(e)=>{setMemberCellphone(Util.handleMobileNumberChange(e.target.value))}}
                        />
                      </div>

                      {/* <div className="mb-3">
                        <AvField
                          name="memberSign"
                          label="서명 이미지"
                          type="file"
                          errorMessage="서명 이미지가 등록되지 않았습니다."
                          required
                          placeholder="서명 이미지를 등록해주세요."
                          id="sign"
                          style={{display: 'none'}}
                        />
                        <label className="btn btn-primary waves-effect waves-light" htmlFor="sign" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                      </div> */}

                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={() => {
                              
                              if(!memberId){
                                return alert("아이디 중복확인을 해주세요.")
                              }
                              if(memberPassword.value !== memberPasswordConfirm.value){
                                return alert("비밀번호 확인이 비밀번호와 다릅니다.")
                              }
                            }}
                          >
                            다음으로                        
                          </button>
                        </div>
                      </div>
                      
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  이미 계정이 있습니까 ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    로그인
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} IoT Monitor
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

RegisterStep1.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(RegisterStep1)
